
:root {
  --primary: #ED378F;
  --primary-hover: #ea5ca1;
  --primary-light: #f874b4;
  --secondary: #F37B31;
  --secondary-hover: #f88f4e;
  --alternative: #FFF0E6;
  --alternative-hover: #FFF0E6;
  --danger: #FFE6EE;
  --danger-hover: #FFE6EE;
  --link: #11ACE1;
  --gray-500: #393939;
  --gray-400: #6B6B6B;
  --gray-300: #A1A1A1;
  --gray-200: #A9A9A9;
  --gray-100: #DDDDDD;
  --white: #ffffff;
  --black: #000000;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body.RTL {
  direction: rtl;
}
.LTR{
  direction: ltr!important;
}
body {
  scrollbar-width: thin;
  /* "auto" or "thin" */
  scrollbar-color: var(--primary) #ddd;
  /* scroll thumb and track */
}

body::-webkit-scrollbar {
  width: 12px;
  /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: #ddd;
  /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 3px solid #ddd;
  /* creates padding around scroll thumb */
}
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--primary) #ddd;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
.RTL * {
  font-family: "Alexandria", sans-serif;
  line-height: 1.8;
}
select option {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}
.RTL .dir-switch{
  flex-direction: row;
  display: flex;
}

.text-en{
  font-family: "Poppins", sans-serif!important;
}
.text-ar{
  font-family: "Alexandria", sans-serif!important;
}
.text-danger{
  color: #EE404C;
}
.danger-bg{
  background-color: #FFF0F1;
  border-radius: 10px;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #ddd;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 20px;
  border: 3px solid #ddd;
}

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.RTL .text-right {
  text-align: left;
}
.RTL .text-left {
  text-align: right;
}
.word-wrap{
  word-wrap: anywhere;
}

/* *[draggable="false"] {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  pointer-events: none;
  user-drag: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
  user-select: none;
} */

.m-0 {
  margin: 0 0 0 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.ml-4 {
  margin-left: 1.5rem !important;
}

.ml-5 {
  margin-left: 3rem !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mr-4 {
  margin-right: 1.5rem !important;
}
.mr-5 {
  margin-right: 3rem !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto{
  margin-left: auto !important;
  margin-right: auto !important;
}
.ml-auto{
  margin-left: auto !important;
}
.mr-auto{
  margin-right: auto !important;
}

.p-0 {
  padding: 0 0 0 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

.pl-4 {
  padding-left: 1.5rem !important;
}

.pl-5 {
  padding-left: 3rem !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.pr-4 {
  padding-right: 1.5rem !important;
}

.pr-5 {
  padding-right: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pb-7{
  padding-bottom: 5rem !important;
}


/* For RTL */


.RTL .ml-0 {
  margin-right: 0 !important;
}

.RTL .ml-1 {
  margin-left: 0 !important;;
  margin-right: 0.25rem !important;
}

.RTL .ml-2 {
  margin-left: 0 !important;;
  margin-right: 0.5rem !important;
}

.RTL .ml-3 {
  margin-left: 0 !important;;
  margin-right: 1rem !important;
}

.RTL .ml-4 {
  margin-left: 0 !important;;
  margin-right: 1.5rem !important;
}

.RTL .ml-5 {
  margin-left: 0 !important;;
  margin-right: 3rem !important;
}

.RTL .mr-0 {
  margin-left: 0 !important;
}

.RTL .mr-1 {
  margin-right: 0 !important;
  margin-left: 0.25rem !important;
}

.RTL .mr-2 {
  margin-right: 0 !important;
  margin-left: 0.5rem !important;
}

.RTL .mr-3 {
  margin-right: 0 !important;
  margin-left: 1rem !important;
}

.RTL .mr-4 {
  margin-right: 0 !important;
  margin-left: 1.5rem !important;
}

.RTL .mr-5 {
  margin-right: 0 !important;
  margin-left: 3rem !important;
}

.RTL .pl-0 {
  padding-right: 0 !important;
}

.RTL .pl-1 {
  padding-left:  0 !important;
  padding-right: 0.25rem !important;
}

.RTL .pl-2 {
  padding-left:  0 !important;
  padding-right: 0.5rem !important;
}

.RTL .pl-3 {
  padding-left:  0 !important;
  padding-right: 1rem !important;
}

.RTL .pl-4 {
  padding-left:  0 !important;
  padding-right: 1.5rem !important;
}

.RTL .pl-5 {
  padding-left:  0 !important;
  padding-right: 3rem !important;
}

.RTL .pr-0 {
  padding-left: 0 !important;
}

.RTL .pr-1 {
  padding-left: 0.25rem !important;
  padding-right: 0 !important;
}

.RTL .pr-2 {
  padding-left: 0.5rem !important;
  padding-right: 0 !important;
}

.RTL .pr-3 {
  padding-left: 1rem !important;
  padding-right: 0 !important;
}

.RTL .pr-4 {
  padding-left: 1.5rem !important;
  padding-right: 0 !important;
}

.RTL .pr-5 {
  padding-left: 3rem !important;
  padding-right: 0 !important;
}

/* END For RTL */

.p-limit{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 250px;
  
}

.text-no-wrap{
  white-space: nowrap;
}

.padding-1{
  padding:  0.5rem !important;
}

.padding-2{
  padding:  1rem;
}

.padding-3{
  padding:  1.5rem;
}
.w-100 {
  max-width: 100% !important;
  width: 100% !important;
}

.w-95 {
  max-width: 95% !important;
  width: 100% !important;
}

.w-90 {
  max-width: 90% !important;
  width: 100% !important;
}

.w-85 {
  max-width: 85% !important;
  width: 100% !important;
}

.w-80 {
  max-width: 80% !important;
  width: 100% !important;
}

.w-75 {
  max-width: 75% !important;
  width: 100% !important;
}

.w-70 {
  max-width: 70% !important;
  width: 100% !important;
}

.w-65 {
  max-width: 65% !important;
  width: 100% !important;
}

.w-60 {
  max-width: 60% !important;
  width: 100% !important;
}

.w-55 {
  max-width: 55% !important;
  width: 100% !important;
}

.w-50 {
  max-width: 50% !important;
  width: 100% !important;
}

.w-45 {
  max-width: 45% !important;
  width: 100% !important;
}

.w-40 {
  max-width: 40% !important;
  width: 100% !important;
}

.w-35 {
  max-width: 35% !important;
  width: 100% !important;
}

.w-30 {
  max-width: 30% !important;
  width: 100% !important;
}

.w-25 {
  max-width: 25% !important;
  width: 100% !important;
}

.w-20 {
  max-width: 20% !important;
  width: 100% !important;
}

.w-15 {
  max-width: 15% !important;
  width: 100% !important;
}

.w-10 {
  max-width: 10% !important;
  width: 100% !important;
}

.w-5 {
  max-width: 5% !important;
  width: 100% !important;
}

.w-0 {
  max-width: 0% !important;
  width: 100% !important;
}

body > div > section > div > div > section > div > div > div.order-details-container > div.order-card > div.order-card-wrapper > div > div.card-image > a.product-image > img.d-inline-block-important{
  display: block!important;
}

.d-none {
  display: none !important;
}
.d-flex {
  display: flex !important;
}
.d-block {
  display: block !important;
}
.d-inline {
  display: inline !important;
}
.d-grid{
  display: grid !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
.vertical-align-middle{
  vertical-align: middle;
}
.flex-row{
  flex-direction: row!important;
}
.flex-column{
  flex-direction: column!important;
}
.flex-auto{
  flex: auto !important;
}
.align-items-center{
  align-items: center!important;
}
.align-items-start{
  align-items: start!important;
}
.align-items-end{
  align-items: end!important;
}
.justify-between{
  justify-content: space-between!important;
}
.justify-center{
  justify-content: center!important;
}
.justify-start{
  justify-content: start!important;
}
.justify-end{
  justify-content: end!important;
}
.justify-evenly{
  justify-content: space-evenly;
}
.flex-wrap{
  flex-wrap: wrap!important;
}
.bg-primary{
  background-color: var(--primary)!important;
}
.bg-secondary{
  background-color: var(--secondary)!important;
}
.bg-alternative{
  background-color: var(--alternative)!important;
}
.bg-link{
  background-color: var(--link)!important;
}
.bg-none{
  background: none;
}
.text-primary{
  color: var(--primary)!important;
}
.text-secondary{
  color: var(--secondary)!important;
}
.text-gray-500{
  color: var(--gray-500)!important;
}
.text-gray-400{
  color: var(--gray-400)!important;
}
.text-gray-300{
  color: var(--gray-300)!important;
}
.text-gray-200{
  color: var(--gray-200)!important;
}
.text-gray-100{
  color: var(--gray-100)!important;
}
.text-light{
  color: var(--white)!important;
}
.text-light-p{
  color: var(--white)!important;
  font-weight: 200;
}
.text-dark{
  color: var(--black)!important;
}
.fs-38{
  font-size: 38px;
}
.fs-36{
  font-size: 36px;
}
.fs-34{
  font-size: 34px;
}
.fs-32{
  font-size: 32px;
}
.fs-30{
  font-size: 30px;
}
.fs-28{
  font-size: 28px;
}
.fs-26{
  font-size: 26px;
}
.fs-24{
  font-size: 24px;
}
.fs-22{
  font-size: 22px;
}
.fs-20{
  font-size: 20px;
}
.fs-18{
  font-size: 18px;
}
.fs-16{
  font-size: 16px;
}
.fs-14{
  font-size: 14px;
}
.fs-12{
  font-size: 12px;
}
.fw-100{
  font-weight: 100!important;
}
.fw-200{
  font-weight: 200!important;
}
.fw-300{
  font-weight: 300!important;
}
.fw-400{
  font-weight: 400!important;
}
.fw-500{
  font-weight: 500!important;
}
.fw-600{
  font-weight: 600!important;
}
.fw-700{
  font-weight: 700!important;
}
.fw-800{
  font-weight: 800!important;
}
.fw-900{
  font-weight: 900!important;
}
.link{
  display: inline-block;
  text-decoration: none;
  color: var(--link)!important;
  background: none;
  border: 0px;
  outline: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
.link-secondary{
  display: inline-block;
  text-decoration: none;
  color: var(--secondary)!important;
  background: none;
  border: 0px;
  outline: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
.link-primary{
  display: inline-block;
  text-decoration: none;
  color: var(--primary)!important;
  background: none;
  border: 0px;
  outline: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
.decoration-none{
  text-decoration: none;
}

.btn {
  font-size: 16px;
  padding: 0px 30px;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: none;
  box-shadow: none;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  height: 45px;
  border: 0;
  cursor: pointer;
}
.btn-m {
  font-size: 14px;
  padding: 0px 20px;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  outline: none;
  box-shadow: none;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  height: 35px;
  border: 0;
  cursor: pointer;
}

.btn.btn-primary {
  background-color: var(--primary);
  color: var(--white);
  transition: all 0.3s ease-in-out;
}
.btn-m.btn-primary {
  background-color: var(--primary);
  color: var(--white);
  transition: all 0.3s ease-in-out;
}
.btn.btn-primary:active,
.btn.btn-primary:hover {
  background-color: var(--primary-hover);
  color: var(--white);
  transition: all 0.3s ease-in-out;
}

.btn.btn-secondary {
  background-color: var(--secondary);
  color: var(--white);
  transition: all 0.3s ease-in-out;
}

.btn.btn-secondary:active,
.btn.btn-secondary:hover {
  background-color: var(--secondary-hover);
  color: var(--white);
  transition: all 0.3s ease-in-out;
}
.btn.btn-disabled {
  background-color: #7A7A7A;
  color: var(--white);
  cursor: not-allowed;
  transition: all 0.3s ease-in-out;
}

.btn.btn-disabled:active,
.btn.btn-disabled:hover {
  background-color: #7A7A7A;
  color: var(--white);
  cursor: not-allowed;
  transition: all 0.3s ease-in-out;
}

.btn.btn-outline-primary {
  background:none;
  color: var(--primary);
  border: 1px solid var(--primary);
  transition: all 0.3s ease-in-out;
}

.btn.btn-outline-primary:focus,
.btn.btn-outline-primary:hover {
  background: var(--primary);
  color: var(--white);
  border: 1px solid var(--primary);
  transition: all 0.3s ease-in-out;
}
 .btn.btn-outline-primary-disabled {
  background:#F7F7F7;
  color: #A9A9A9;
  transition: all 0.3s ease-in-out;
  cursor: default;
}
.btn.btn-outline-secondary {
  background:none;
  color: var(--secondary);
  border: 1px solid var(--secondary);
  transition: all 0.3s ease-in-out;
}
.btn.btn-outline-secondary:focus,
.btn.btn-outline-secondary:hover {
  background: var(--secondary-hover);
  color: var(--white);
  border: 1px solid var(--secondary-hover);
  transition: all 0.3s ease-in-out;
}


.btn.btn-alternative {
  background-color: var(--alternative);
  color: var(--secondary);
  transition: all 0.3s ease-in-out;
}

.btn.btn-success {
  background-color: #e6ffe7;
  color: #23cf68;
  transition: all 0.3s ease-in-out;
}

.btn.btn-alternative:active,
.btn.btn-alternative:hover {
  background-color: var(--alternative-hover);
  color: var(--secondary);
  transition: all 0.3s ease-in-out;
}
.btn.btn-danger {
  background-color: var(--danger);
  color: var(--primary);
  transition: all 0.3s ease-in-out;
}

.btn.btn-danger:active,
.btn.btn-danger:hover {
  background-color: var(--danger-hover);
  color: var(--primary);
  transition: all 0.3s ease-in-out;
}
.btn-light {
  background-color: var(--alternative);
  color: var(--secondary);
}
.cursor-pointer{
  cursor: pointer;
}

body .wrapper{
  max-width: 1620px;
  padding: 0px 25px;
  margin: 0 auto;
}

.text-capitalize{
  text-transform: capitalize;
}

.input-checkbox{
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.input-checkbox + label{
  position: relative;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
}
.input-checkbox + label::before{
  content: "\EB7F";
  display: inline-block;
  font-size: 18px;
  /* border: 1px solid var(--primary); */
  /* height: 15px; */
  /* width: 15px; */
  /* border-radius: 3px; */
  vertical-align: middle;
  margin-right: 10px;
  /* margin-bottom: 2px; */
  transition: all 0.3s ease-in-out;
  font-family: remixicon !important;
  font-style: normal;
}
.RTL .input-checkbox + label::before{
  margin-right: 0px;
  margin-left: 10px;
}
.input-checkbox:checked + label::before{
  content: "\EB82";
  /* background: var(--primary); */
  color: var(--primary);
  transition: all 0.3s ease-in-out;
}
/* .input-checkbox:checked + label::after{
  content: "";
  display: inline-block;
  height: 7px;
  width: 4px;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  position: absolute;
  top: 6px;
  left: 6px;
  transition: all 0.3s ease-in-out;
  transform: rotate(45deg);
}
.RTL .input-checkbox:checked + label::after{
  left: auto;
  right: 6px;
} */
.input-radio{
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.input-radio + label{
  position: relative;
}
.input-radio + label::before{
  content: "\eb7d";
  display: inline-block;
  vertical-align: middle;
  font-size: 20px;
  color: var(--primary);
  margin-right: 10px;
  margin-bottom: 2px;
  font-family: 'remixicon' !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.RTL .input-radio + label::before{
  margin-right: 0px;
  margin-left: 10px;
}
.input-radio:checked + label::before{
  content: "\ed49";
}

.position-relative{
  position: relative;
}
.position-absolute{
  position: absolute;
}

.text-uppercase{
  text-transform: uppercase;
}
.text-lowercase{
  text-transform: lowercase;
}

.border-line{
  border-bottom: 2px solid var(--secondary);
  
}

.border-line-light{
  border-bottom: 1px solid var(--gray-100);
  
}

.border-line-dark{
  border-bottom: 1px solid var(--gray-200);
  
}

.border-dotted-light{
  border-bottom: 1px dashed var(--gray-100);
}
.border-none{
  border: none!important;
}

@media screen and (max-width: 1240px) {
  .btn {
    font-size: 14px;
    padding: 0px 20px;
  }
}
@media screen and (max-width: 600px) {
  body .wrapper {
    padding: 0px 15px;
  }
}
@media screen and (max-width: 500px) {
  .input-checkbox:checked + label::after {
    height: 6px;
    width: 3px;
  }
}


.spinner {
  transform-origin: center;
  animation-name: animation;
  animation-duration: 1.2s;
  animation-timing-function: cubic-bezier;
  animation-iteration-count: infinite;
}
@keyframes animation {
   0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.consent-popup{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px 0px 30px 0px #eeeeee;
  z-index: 999999999;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.consent-popup.active{
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}
.consent-popup::before{
  content: "";
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  background-color: var(--white);
  opacity: 0.97;
}
.consent-popup .popup-wrapper{
  padding: 35px 55px 20px 20px;
  position: relative;
  z-index: 2;
}
.consent-popup .popup-wrapper .popup-close{
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: 0px;
  box-shadow: none;
  cursor: pointer;
  padding: 5px;
  font-size: 20px;
}
.consent-popup .popup-wrapper .popup-text{
  margin: 0 0 10px;
  font-size: 14px;
}
.consent-popup .popup-wrapper .popup-footer .btn-primary,
.consent-popup .popup-wrapper .popup-footer .btn-secondary{
  font-size: 14px;
  height: 35px;
  padding: 5px 15px;
  font-weight: 400;
}
.consent-popup .popup-wrapper .popup-footer .btn-secondary{
  background-color: var(--gray-100);
  color: var(--gray-500);
}
.error-list{
  display: block;
  background: var(--danger);
  border-radius: 5px;
  width: 100%;
  list-style-position: inside;
  padding: 10px 15px;
  color: var(--primary);
  font-weight: 500;
  font-size: 14px;
}
@media screen and (max-width: 600px) {
  .consent-popup .popup-wrapper .popup-text{
    font-size: 12px;
    text-align: justify;
  }
  .consent-popup .popup-wrapper {
    padding: 15px;
  }
  
  .consent-popup .popup-wrapper .popup-footer .btn-primary,
  .consent-popup .popup-wrapper .popup-footer .btn-secondary{
    font-size: 12px;
    height: 30px;
    padding: 0px 10px;
  }
}

@media screen and (max-width: 400px) {
  .consent-popup .popup-wrapper .popup-text{
    font-size: 10px;
  }
  
  .consent-popup .popup-wrapper .popup-footer .btn-primary,
  .consent-popup .popup-wrapper .popup-footer .btn-secondary{
    font-size: 10px;
    height: 26px;
  }
}

.react-loading-skeleton::after {
  z-index: 1;
}

.whatsapp-button{
  background-color: #25d366;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  border-radius: 50px;
  position: fixed;
  bottom: 25px;
  left: 25px;
  text-decoration: none;
  color: #fff;
  font-size: 28px;
  box-shadow: 0 0 8px 0px #00000022;
  transition: all 0.3s ease-in-out;
}
.whatsapp-button:hover{
  background-color: #2eca67;
  box-shadow: 0 0 15px 0px #00000022;
  transition: all 0.3s ease-in-out;
}
apple-pay-button {
  --apple-pay-button-width: 100%;
  --apple-pay-button-height: 45px;
  --apple-pay-button-border-radius: 5px;
  --apple-pay-button-padding: 0px 0px;
  --apple-pay-button-box-sizing: border-box;
}