#product-shipments .shipments-cards-header {
    position: relative;
    width: 100%;
    padding: 15px 20px;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #d6d5d5;
    margin-bottom: 20px;
}

#product-shipments .shipments-cards .shipments-btn-group {
    padding-left: 1rem;
}

#product-shipments .shipments-btn-group .btn-shipments {
    padding: 0px 30px;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    outline: none;
    box-shadow: none;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    height: 45px;
    border: 0;
    cursor: pointer;
    background: none !important;
    padding: 0 !important;
    color: var(--gray-300);
    font-size: 16px;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
}

#product-shipments .shipments-btn-group .btn-shipments i {
    background-color: #f8f8f8;
    border-radius: 20px;
    height: 30px;
    width: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    transition: background-color 0.3s ease-in-out;
}

#product-shipments .shipments-btn-group .btn-shipments.active {
    color: var(--primary);
    transition: all 0.3s ease-in-out;
}

#product-shipments .shipments-btn-group .btn-shipments.active i {
    background-color: #ffcce5;
    transition: background-color 0.3s ease-in-out;
}

#product-shipments .shipments-btn-group .btn-shipments::after {
    content: "";
    border-left: 1px solid var(--gray-200);
    height: 20px;
    margin-left: 1rem;
    margin-right: 1rem;
    padding: 0 !important;
}

#product-shipments .shipments-btn-group>button.btn-shipments:last-of-type::after {
    display: none;
}

/* Shipment box styles */
#product-shipments .order-details-container {
    padding: 0px 0px;
}

#product-shipments div.shipment-heading {
    background-color: #f8f8f8;
    padding: 7px 25px;
    border-radius: 10px 0px 10px 0px;
    display: inline-block;
}

#product-shipments div.track-shipment {
    padding: 7px 25px;
}

#product-shipments div.track-shipment a {
    text-decoration: none;
}

#product-shipments div.shipment-heading i {
    color: var(--primary);
}

#product-shipments .order-card-wrapper{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
#product-shipments .order-card-wrapper > div{
    width: 100%;
    border-bottom: 1px dotted var(--gray-300);
    padding-bottom: 10px;
}
#product-shipments .order-card-wrapper > div .card-title-brand{
    max-width: 210px;
    width: 100%;
}
#product-shipments .order-card-wrapper > div .card-price{
    max-width: 100px;
    width: 100%;
}
#product-shipments .order-card-wrapper > div .total-price{
    max-width: 150px;
    width: 100%;
}

#product-shipments .pending-single-item-container > div{
    width: 100%;
    max-width: 25%;
}
/* #product-shipments .pending-single-item-container .pending-message-container{
    padding: 10px 18px;
} */
#product-shipments .pending-single-item-container .pending-message-container .btn.btn-alternative{
    font-size: 14px;
    height: 100%;
    padding: 10px 18px;
}

#product-shipments .shipment-info .courier-logo-container img {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    border-radius: 120px;
    object-fit: cover;
    border: 2px solid var(--secondary);
}

#product-shipments .shipment-info {
    border-bottom: 1px dashed #cdcdcd;
    padding-bottom: 15px;
}

#product-shipments .shipment-info p {
    margin: 5px 0px;
}

.dropdown-btn-container{
    position: relative;
}
.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 160px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    z-index: 1;
}

.dropdown-menu li {
    padding: 5px 15px;
    cursor: pointer;
}

.dropdown-menu li:hover {
    background-color: #f2f2f2;
}





@media screen and (max-width: 1250px) {
    #product-shipments .order-details-container{
        border: 1px dotted #d6d5d5;
    }
    #product-shipments .order-details-container > div.order-card{
        border: none !important;
    }
    #product-shipments .shipment-info {
        border-bottom: none;
        border: 1px solid #cdcdcd;
        padding-bottom: 0px;
        padding: 20px;
        border-radius: 10px;
    }
    #product-shipments .shipments-cards-header {
        padding: 10px 20px;
    }
    
    #product-shipments .shipments-btn-group .btn-shipments {
        font-size: 14px;
    }
    
    #product-shipments .shipments-btn-group .btn-shipments i,
    #product-shipments .shipments-btn-group .btn-shipments.active i {
        background-color: #fff;
        height: auto;
        width: auto;
    }
    
    #product-shipments .order-card-wrapper > div{
        width: 48%;
        border: 1px solid #cdcdcd;
        padding: 20px;
        border-radius: 10px;
    }
    #product-shipments .pending-single-item-container > div{
        width: 100%;
        max-width: 100%;
    }
    .order-card .card-rating > div, .order-card .product-price {
        justify-content: center;
    }
    
    #product-shipments .shipment-price-label{
        text-align: center;
    }
    #product-shipments .order-card-wrapper > div .card-price{
        max-width: none;
    }
    #product-shipments .order-card-wrapper > div .card-title-brand{
        max-width: none;
    }
    #product-shipments .order-card-wrapper > div .total-price {
        max-width: none;
        width: auto;
      }
    
}

@media screen and (max-width: 700px) {
    #product-shipments .shipments-btn-group {
        justify-content: center !important;
    }
    #product-shipments .order-card-wrapper > div{
        width: 48%;
    }
}

@media screen and (max-width: 500px) {
    #product-shipments .shipments-btn-group {
        justify-content: center !important;
    }

    #product-shipments .shipments-btn-group .btn-shipments {
        font-size: 14px;
    }
    #product-shipments .order-card-wrapper > div{
        width: 100%;
    }
    #product-shipments .order-card-wrapper > div .total-price {
        width: -moz-available;
      }
}