.product-card{
    position: relative;
    padding: 8px 12px;
    border: 1px solid var(--gray-100);
    border-radius: 5px;
    overflow: hidden;
    max-width: 280px;
    margin-bottom: 25px;
    width: 100%;
    -webkit-touch-callout: none; 
    -webkit-user-select: none; 
    -khtml-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none; 
    user-select: none; 
}
.product-card *{
    -webkit-touch-callout: none; 
    -webkit-user-select: none; 
    -khtml-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none; 
    user-select: none; 
}



.product-card .product-tag{
    position: absolute;
    top: 0px;
    left: 0px;
    padding: 5px 30px;
    color: var(--white);
    background-color: var(--link);
    z-index: 1;
    font-size: 16px;
    border-bottom-right-radius: 25px;
}
.product-card .product-header{
    position: relative;
}
.product-card .product-header .product-image{
    display: inline-block;
    text-align: center;
    width: 100%;
}
.product-card .product-header .product-image img{
    margin: 10px auto 0px;
    object-fit: contain;
    width: 250px;
    height: 250px;
}
.product-card .product-header .product-wishlist{
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: 0px;
    outline: none;
    cursor: pointer;
    padding: 0px;
    margin: 0px;
    z-index: 1;
}

.product-card .product-header .product-wishlist.active svg path {
    fill: var(--secondary);
}
.product-card .product-header .product-brand{
    position: absolute;
    bottom: 8px;
    left: 0px;
    width: 70px;
    height: 50px;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid var(--secondary);
    overflow: hidden;
    box-shadow: 0px 0px 3px 3px #ccc;
}
.product-card .product-header .product-brand img{
    object-fit: contain;
    width: 100%;
    height: 100%;
}
.product-card .product-body{

}
.product-card .product-body .product-title{
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
    color: var(--gray-500);
    min-height: 54px;
    display: inline-block;
}
.RTL .product-card .product-body .product-title{
    min-height: 65px;
}
.product-card .product-body .product-price{
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}
.product-card .product-body .product-price .product-currency{
    margin-right: 5px;
    font-size: 18px;
    text-transform: uppercase;
    color: var(--gray-500);
    font-weight: 500;
}
.product-card .product-body .product-price .product-amount{
    margin-right: 5px;
    font-size: 18px;
    color: var(--secondary);
    font-weight: 500;
}
.product-card .product-body .product-price .product-old-amount{
    margin-right: 5px;
    font-size: 16px;
    color: var(--gray-300);
    font-weight: 400;
}
.product-card .product-body .product-info{
    display: flex;
    align-items: center;
}
.product-card .product-body .product-info > div{
    margin-right: 10px;
}
.RTL .product-card .product-body .product-info > div{
    margin-right: 0px;
    margin-left: 10px;
}
.product-card .product-body .product-info .product-stock{
    margin-bottom: 5px;
    font-size: 14px;
}
.product-card .product-body .product-action{
    width: 100%;
    justify-content: center;
}
.product-card .product-card-popup{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    z-index: 10;
}
.product-card .product-card-popup.active{
    display: flex;
}
.product-card .product-card-popup::before{
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0;
    z-index: 10;
    transition:  all 0.3s ease-in-out;
}
.product-card .product-card-popup.active::before{
    opacity: 0.4;
    transition:  all 0.3s ease-in-out;
}
.product-card .product-card-popup .popup-wrapper{
    position: relative;
    max-width: 500px;
    width: 100%;
    background-color: #fff;
    padding: 40px 25px;
    border-radius: 15px;
    z-index: 20;
    text-align: center;
}
.product-card .product-card-popup .popup-title{
    font-size: 20px;
    font-weight: 500;
    color: var(--gray-500);
    margin: 0;
    text-align: center;
}
.product-card .product-card-popup .popup-subheading{
    font-size: 16px;
    font-weight: 400;
    color: var(--secondary);
    margin: 0;
    text-align: center;
    display: block;
}
.product-card .product-card-popup .popup-image-container{
    width: 100%;
    text-align: center;
    margin: 30px 0px;
}
.product-card .product-card-popup .popup-image-container img{
    width: 100%;
    max-width: 300px;
}
.product-card .product-card-popup .btn{
    width: 100%;
    max-width: 360px;
    margin: 0 auto;
}
.product-card .product-card-popup a.btn{
    margin: 0 auto 15px;
}

@media screen and (max-width: 600px) {
    .product-card .product-card-popup {
        padding: 0px 20px;
    }
    .product-card .product-card-popup .popup-wrapper {
        max-width: 340px;
        padding: 30px 25px;
    }
    .product-card .product-card-popup .popup-title {
        font-size: 16px;
    }
    .product-card .product-card-popup .popup-subheading {
        font-size: 14px;
    }
    .product-card .product-card-popup .popup-image-container img {
        max-width: 200px;
    }
}