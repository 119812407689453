.review-cards {
  width: 100%;
  padding: 15px 20px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #d6d5d5;
  margin-bottom: 20px;
}
.review-cards .review-image {
  max-width: 140px;
  width: 100%;
}
.review-cards .review-image img {
  width: 140px;
  height: 140px;
  object-fit: contain;
}
.review-cards .review-product-details {
  max-width: calc(60% - 110px);
  width: 100%;
}
.review-cards .review-details {
  max-width: calc(40% - 110px);
  width: 100%;
}
.review-cards .review-details .review-approved{
  color: #ED378F !important;
  background-color: #FFCCE5 !important;
}

@media screen and (max-width: 1200px) {
  .review-cards {
    width: 48%;
  }
  .review-cards .review-image {
    width: 100%;
    margin: 0 auto;
  }
  .review-cards .review-product-details {
    max-width: 100%;
    text-align: center;
  }
  .review-cards .review-product-details .product-name{
    font-size: 16px;
  }
  .review-cards .review-product-details .product-brand,
  .review-cards .review-product-details .product-review-date{
    font-size: 14px;
  }
  .review-cards .review-product-details .product-review-date span,
  .review-cards .review-product-details .product-brand span{
    font-size: 14px;
    font-weight: 500;
  }
  .review-cards .review-details {
    max-width: 100%;
    text-align: center;
    margin-top: 20px;
  }
  .review-cards .review-details .product-generated-label{
    font-size: 14px;
    font-weight: 500;
  }
  .review-cards .review-details .react-stars {
    justify-content: center;
  }
}

@media screen and (max-width: 830px) {
  .review-cards {
    width: 100%;
  }
}

@media screen and (max-width: 700px) {
  .review-cards {
    max-width: 48%;
  }
}
@media screen and (max-width: 520px) {
  .review-cards {
    max-width: 100%;
  }
}
