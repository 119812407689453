#checkout{
  padding: 80px 0px;
}
#checkout .wrapper .section-header {
  margin-bottom: 60px;
}
#checkout .left-checkout {
  max-width: calc(100% - 525px);
  width: 100%;
}

#checkout .right-checkout {
  max-width: 500px;
  width: 100%;
}
.card {
  position: relative;
  max-width: 100%;
  width: 100%;
  padding: 30px 20px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #d6d5d5;
  margin: 0 0 30px;
}

.card .checkout-form-content {
  max-width: 48%;
  width: 100%;
}

.card .checkout-form-content input {
  width: 100%;
  margin: 0px;
  margin-top: 5px;
  padding: 0px 12px;
  height: 42px;
  font-size: 14px;
  color: var(--gray-500);
  border: 1px solid #d6d5d5;
  outline: none;
  border-radius: 5px;
  background: #fff;
  transition: all 0.3s ease-in-out;
}
.card .checkout-form-content input:focus {
  border: 1px solid var(--primary);
  transition: all 0.3s ease-in-out;
}

.card .checkout-form-content input:read-only {
  cursor: not-allowed;
  background: url(../images/locked.png);
  background-position: calc( 100% - 10px) center;
  background-repeat: no-repeat;
  background-size: 18px;
}
.RTL .card .checkout-form-content input:read-only {
  background-position: 10px center;
}

.card .checkout-form-content select {
  width: 100%;
  margin: 0px;
  margin-top: 5px;
  padding: 0px 15px;
  height: 42px;
  font-size: 14px;
  color: var(--gray-500);
  border: 1px solid #d6d5d5;
  outline: none;
  border-radius: 5px;
  background: #fff;
  transition: all 0.3s ease-in-out;
}

.btn-checkout.active {
  color: var(--primary);
}

/* CHECKOUT PROUDCT  */
#checkoutProducts .checkout-product{
  padding: 10px 0px;
  border-bottom: 1px solid #d6d5d5;
  position: relative;
}
#checkoutProducts .checkout-product .remove-item{
  position: absolute;
  top: -14px;
  right: 0px;
  background: var(--primary);
  border: 0px;
  outline: none;
  color: #fff;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 25px;
  cursor: pointer;
}
#checkoutProducts .checkout-product.out-of-stock > div > div > *:not(.product-out-of-stock){
  opacity: 0.5;
}
#checkoutProducts > div.checkout-product:last-of-type{
  margin-bottom: 20px;
}
#checkoutProducts > div.checkout-product .product-delivery {
  position: absolute;
  top: -13px;
  left: 15px;
  background: var(--alternative);
  color: var(--secondary);
  padding: 5px 10px;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  border-radius: 5px;
  z-index: 1;
}
#checkoutProducts > div.checkout-product .product-delivery i {
  font-size: 16px;
  margin-right: 5px;
}
#checkoutProducts .checkout-product .left-checkout-product .product-img {
  max-width: 85px;
  width: 100%;
}

#checkoutProducts .checkout-product .left-checkout-product .product-img img {
  width: 85px;
  height: 85px;
  object-fit: contain;
}
#checkoutProducts .checkout-product .right-checkout-product {
  max-width: 200px;
  width: 100%;
}

#checkoutProducts .checkout-product .right-checkout-product .product-delivery .delivery {
  background: var(--alternative);
  color: var(--secondary);
  padding: 5px 10px;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  border-radius: 20px;
  z-index: 1;
}

.gap-1 {
  gap: 1rem;
}

#checkout .product-card-popup{
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
#checkout .product-card-popup.active{
  display: flex;
}
#checkout .product-card-popup::before{
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0;
  z-index: 10;
  transition:  all 0.3s ease-in-out;
}
#checkout .product-card-popup.active::before{
  opacity: 0.4;
  transition:  all 0.3s ease-in-out;
}
#checkout .product-card-popup .popup-wrapper{
  position: relative;
  max-width: 500px;
  width: 100%;
  background-color: #fff;
  padding: 40px 25px;
  border-radius: 15px;
  z-index: 20;
  text-align: center;
}
#checkout .product-card-popup .popup-title{
  font-size: 20px;
  font-weight: 500;
  color: var(--gray-500);
  margin: 0;
  text-align: center;
}
#checkout .product-card-popup .popup-subheading{
  font-size: 16px;
  font-weight: 400;
  color: var(--secondary);
  margin: 0;
  text-align: center;
  display: block;
}
#checkout .product-card-popup .popup-image-container{
  width: 100%;
  text-align: center;
  margin: 30px 0px;
}
#checkout .product-card-popup .popup-image-container img{
  width: 100%;
  max-width: 200px;
}
#checkout .product-card-popup .btn{
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
}
#checkout .product-card-popup a.btn{
  margin: 0 auto 15px;
}
#checkout .checkout-address-card{
  display: flex;
  width: 100%;
}
#checkout .checkout-address-card label{
  padding: 20px;
  display: flex;
  border: 1px solid #d6d5d5;
  margin-bottom: 20px;
  border-radius: 10px;
  width: 100%;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

#checkout .checkout-address-card input:checked + label{
  border: 1px solid var(--primary);
  transition: all 0.3s ease-in-out;
}

/* Payment Methods */

#checkout .wrapper form .payment-method .card .card-payment-options .single-payment-card{
  width: 100%;
  position: relative;
  border: 1px solid var(--gray-100);
  border-radius: 5px;
  padding: 20px;
  margin: 20px 0px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
#checkout .wrapper form .payment-method .card .card-payment-options .single-payment-card:hover{
  border: 1px solid var(--primary);
  transition: all 0.3s ease-in-out;
}
#checkout .wrapper form .payment-method .card .card-payment-options .single-payment-card p{
   margin-left: 30px;
}
.RTL #checkout .wrapper form .payment-method .card .card-payment-options .single-payment-card p{
   margin-right: 30px;
   margin-left: 0px;
}
#checkout .wrapper form .payment-method .card .card-payment-options .single-payment-card img{
  width: 100%;
  position: absolute;
  max-width: 45px;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.RTL #checkout .wrapper form .payment-method .card .card-payment-options .single-payment-card img{
  right: auto;
  left: 20px;
}
#PopupCVV{
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
#PopupConfirmation,
#PopupCVV.active{
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

#PopupCVV .popupCVV-overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: -99;
  opacity: 0;
  transform: scale(0);
  transition: opacity 0.3s ease-in-out;
}

#PopupConfirmation .popupConfirmation-overlay,
#PopupCVV.active .popupCVV-overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 99;
  opacity: 0.4;
  transform: scale(1);
  transition: opacity 0.3s ease-in-out;
}
#PopupCVV .popupCVV-container{
  position: fixed;
  top: -100vh;
  left: 50%;
  transform: translate(-50%,-50%);
  max-width: 400px;
  width: 100%;
  background-color: #fff;
  z-index: 100;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid var(--gray-100);
  transition: top 0.3s ease-in-out;
}
#PopupCVV.active .popupCVV-container{
  top: 50%;
  transition: top 0.3s ease-in-out;
}
#PopupConfirmation.isTamara .popupConfirmation-container{
  padding: 0;
  border: 0;
  overflow: hidden;
  background-color: #fff;
}
#PopupConfirmation.minimize .popupConfirmation-overlay{
  display: none;
}
#PopupConfirmation.minimize .popupConfirmation-container{
  position: fixed;
  top: 0;
  left: 0;
  max-width: 0px;
  width: 0px;
  background-color: #ffffff00;
  opacity: 0;
  z-index: 0;
  padding: 0px;
  border-radius: 0px;
  border: 0;
  transition: none;
}
#PopupConfirmation.isTamara .popupConfirmation-container .popupConfirmation-close{
  display: none;
}
#PopupConfirmation .popupConfirmation-container{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  max-width: 450px;
  width: 100%;
  background-color: #ebf0f5;
  z-index: 100;
  padding: 40px 20px;
  border-radius: 5px;
  border: 1px solid var(--gray-100);
  transition: top 0.3s ease-in-out;
}
#PopupConfirmation .popupConfirmation-container .popupConfirmation-close{
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: var(--gray-500);
  cursor: pointer;
}
#PopupConfirmation .popupConfirmation-container iframe{
  width: 100%;
  border: 0;
  height: 550px;
}
#PopupCVV .popupCVV-container h2{
  font-size: 22px;
  font-weight: 500;
  color: var(--gray-500);
  margin: 0 0 5px;
}
#PopupCVV .popupCVV-container p{
  font-size: 14px;
  font-weight: 400;
  color: var(--gray-400);
  margin: 0px 0px 30px;
}
#PopupCVV .popupCVV-card-container .card-icon{
  max-width: 58px;
  margin: 0 10px 0 0;
  width: 100%;
}
.RTL #PopupCVV .popupCVV-card-container .card-icon{
  margin: 0 0px 0 10px;
}

#PopupCVV .popupCVV-card-container .cvv-input{
  appearance: none;
  width: 80px;
  padding: 0px 12px;
  height: 42px;
  font-size: 14px;
  color: var(--gray-500);
  border: 1px solid #d6d5d5;
  outline: none;
  border-radius: 5px;
  background: #fff;
  margin-left: 10px;
  transition: all 0.3s ease-in-out;
}
.RTL #PopupCVV .popupCVV-card-container .cvv-input{
  margin-left: 0px;
  margin-right: 10px;
}

#PopupCVV .popupCVV-card-container .cvv-input:focus{
  border: 1px solid var(--primary);
  transition: all 0.3s ease-in-out;
}
#PopupCVV .popupCVV-card-container div{
  width: calc( 100% - 148px);
}



#checkout .wrapper form .payment-method .card .payment-option{
  display: none;
  width: 100%;
}
#checkout .wrapper form .payment-method .card .payment-option.active{
  display: inline-block;
}
#checkout .wrapper form .payment-method .card .payment-option .checkout-form-content iframe{
  height: 42px!important;
}
#checkout .wrapper form .checkout-form-content .card-icon{
  position: absolute;
  top: 32px;
  right: 10px;
  max-width: 45px;
}
.RTL #checkout .wrapper form .checkout-form-content .card-icon{
  right: auto;
  left: 10px;
  top: 35px;
}
#checkout .wrapper form .checkout-form-content .card-icon img{
  display: block;
  width: 100%;
}
#checkout .wrapper .checkout-consent{
  text-align: left;
}

#checkout .wrapper .checkout-order-details{
  max-width: 500px;
  padding: 20px 30px;
  margin: 0 auto;
  border: 1px solid var(--gray-100);
  border-radius: 10px;
}
#checkout .wrapper .checkout-order-details .divider-dotted{
  border: 1px dotted var(--gray-100);
}
#checkout .wrapper .payment-loading .payment-loading-spinner {
  color: #fff;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
#checkout .wrapper .payment-loading .payment-loading-spinner div {
  transform-origin: 40px 40px;
  animation: payment-loading-spinner 1.2s linear infinite;
}
#checkout .wrapper .payment-loading .payment-loading-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: var(--primary);
}
#checkout .wrapper .payment-loading .payment-loading-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
#checkout .wrapper .payment-loading .payment-loading-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
#checkout .wrapper .payment-loading .payment-loading-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
#checkout .wrapper .payment-loading .payment-loading-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
#checkout .wrapper .payment-loading .payment-loading-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
#checkout .wrapper .payment-loading .payment-loading-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
#checkout .wrapper .payment-loading .payment-loading-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
#checkout .wrapper .payment-loading .payment-loading-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
#checkout .wrapper .payment-loading .payment-loading-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
#checkout .wrapper .payment-loading .payment-loading-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
#checkout .wrapper .payment-loading .payment-loading-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
#checkout .wrapper .payment-loading .payment-loading-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
.checkout-cart-error{
  max-width: 600px;
  margin: 100px auto;
  padding: 0px 25px;
}

#checkout .wrapper .cart-summary .cart-voucher{
  width: 100%;
  padding: 15px 0px;
}
#checkout .wrapper .cart-summary .cart-voucher .applied-coupons{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
#checkout .wrapper .cart-summary .cart-voucher .applied-coupons > span{
  background-color: #FFD0E7;
  padding: 8px 15px;
  font-size: 14px;
  color: var(--primary);
  font-weight: 500;
  border: 1px dashed var(--primary);
  display: inline-flex;
  align-items: center;
  border-radius: 5px;
  margin: 0 10px 10px 0px;
}
.RTL #checkout .wrapper .cart-summary .cart-voucher .applied-coupons > span{
  margin: 0 0 10px 10px;
}
#checkout .wrapper .cart-summary .cart-voucher .applied-coupons > span i{
  font-size: 18px;
  cursor: pointer;
}
#checkout .wrapper .cart-summary .cart-voucher label{
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  color: var(--gray-500);
}
#checkout .wrapper .cart-summary .cart-voucher > div{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#checkout .wrapper .cart-summary .cart-voucher > div input{
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: var(--gray-500);
  margin-right: 10px;
  height: 45px;
  padding: 5px 15px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #D6D5D5;
  transition: all 0.3s ease-in-out;
}
.RTL #checkout .wrapper .cart-summary .cart-voucher > div input{
  margin-right: 0px;
  margin-left: 10px;
}
#checkout .wrapper .cart-summary .cart-voucher > div input:focus{
  border: 1px solid var(--primary);
  transition: all 0.3s ease-in-out;
}
@keyframes payment-loading-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media screen and (max-width: 1200px) {
  #checkout .right-checkout {
    max-width: 420px;
  }
  #checkout .left-checkout {
    max-width: calc(100% - 445px);
  }
}
@media screen and (max-width: 1024px) {
  .card .checkout-form-content {
    max-width: 100%;
    width: 100%;
  }
  .card .card-heading h2{
    font-size: 18px;
  }
  .card .checkout-product-heading > h2{
    font-size: 18px;
  }
  #checkout .shipping-information .card .card-heading{
    display: block !important;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 880px) {
  #checkout .left-checkout {
    max-width: 100%;
    width: 100%;
  }
  #checkout .right-checkout {
    max-width: 100%;
    width: 100%;
  }
  #checkout .shipping-information .card .card-heading{
    display: flex !important;
    margin-bottom: 30px;
  }
  #checkoutProducts .checkout-product .right-checkout-product {
    max-width: 60%;
    width: 100%;
  }
}

@media screen and (max-width: 550px) {
  #PopupCVV .popupCVV-card-container .card-icon{
    max-width: 40px;
  }
  #PopupCVV .popupCVV-card-container .cvv-input{
    width: 80px;
  }
  #PopupCVV .popupCVV-card-container div{
    width: calc( 100% - 130px);
  }
  #PopupCVV .popupCVV-card-container div h3{
    font-size: 14px;
  }
  #PopupCVV .popupCVV-card-container div p{
    font-size: 12px;
  }
  #checkoutProducts .checkout-product .right-checkout-product {
    max-width: 160px;
  }
  #checkout .shipping-information .card .card-heading{
    display: block !important;
    margin-bottom: 30px;
  }
  #checkout .wrapper form .payment-method .card .card-payment-options .single-payment-card,
  #checkout .wrapper form .payment-method .card .card-payment-options .single-payment-card:hover {
    padding: 0 0 15px;
    border: 0;
    border-radius: 0px;
    border-bottom: 1px solid var(--gray-100)
  }
  #checkout .wrapper form .payment-method .card .card-payment-options .single-payment-card img {
    max-width: 40px;
    right: 15px;
  }
  #checkout .wrapper form .payment-method .card .card-payment-options .single-payment-card label{
    font-size: 14px;
  }
  #checkout .wrapper form .payment-method .card .card-payment-options .single-payment-card .selected-card-expiry{
    font-size: 12px;
  }
}

@media screen and (max-width: 450px) {
  #checkoutProducts .checkout-product .left-checkout-product {
    display: none;
  }
  #checkoutProducts .checkout-product .right-checkout-product {
    max-width: 160px;
  }
  #checkoutProducts .checkout-product .right-checkout-product .product-title p{
    color: var(--primary)!important;
  }
}