#productSingle .fullscreen .img-magnifier-glass{
    display: none;
}
#productSingle .img-magnifier-container {
    position: relative;
}
#productSingle .image-gallery-content.fullscreen {
    background: #fff;
}
#productSingle .img-magnifier-glass {
    position: absolute;
    border: 3px solid #ffffff;
    border-radius: 10px;
    cursor: none;
    width: 250px;
    height: 250px;
    z-index: 999999;
    opacity: 0;
}
#productSingle .image-gallery-slides:hover .img-magnifier-glass {
    opacity: 1;
}
#productSingle .single-product-content{
    max-width: 55%;
    width: 100%;
}
#productSingle .single-product-content .product-wishlist{
    background: none;
    border: 0px;
    outline: none;
    cursor: pointer;
}
#productSingle .single-product-content .product-wishlist.active svg path {
    fill: var(--secondary);
  }
#productSingle .single-product-content .divider{
    background: var(--gray-100);
    height: 1px;
    width: 100%;
}
#productSingle .single-product-content .product-quantity-container .product-quantity-btn{
    background-color: var(--white);
    border: 0px;
    outline: none;
    color: var(--primary);
    box-shadow: 0px 1px 8px #ddd;
    height: 42px;
    width: 42px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    border-radius: 5px;
    cursor: pointer;
}
#productSingle .single-product-content .product-quantity-container .product-quantity-field{
    background-color: var(--white);
    border: 0px;
    outline: none;
    color: var(--gray-500);
    box-shadow: none;
    height: 42px;
    width: 50px;
    font-size: 18px;
    border-radius: 5px;
    text-align: center;
    margin: 0px 5px;
    cursor: default;
    -webkit-touch-callout: none; 
    -webkit-user-select: none; 
    -khtml-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none; 
    user-select: none; 
}
#productSingle .single-product-content .single-product-list{
    background: none;
    border: 1px solid #D6D5D5;
    border-radius: 5px;
    padding: 0px 20px;
    width: 200px;
    outline: none;
    font-size: 16px;
    transition: all 0.3s ease-in-out;
}
#productSingle .single-product-content .single-product-list:focus{
    border: 1px solid var(--primary);
    transition: all 0.3s ease-in-out;
}
#productSingle .single-product-content > div > .btn.btn-primary{
    width: 200px;
}
#productSingle .single-product-gallery{
    max-width: 43%;
    width: 100%;
    position: relative;
}
#productSingle .single-product-gallery .tag{
    font-size: 16px;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    padding: 5px 20px;
    background: var(--link);
    color: #fff;
    border-radius: 5px;
}
#productSingle .single-product-gallery .image-gallery-left-nav, 
#productSingle .single-product-gallery .image-gallery-right-nav {
    padding: 5px 10px;
    box-shadow: none;
    filter: none;
}
#productSingle .single-product-gallery .image-gallery-left-nav .image-gallery-svg, 
#productSingle .single-product-gallery .image-gallery-right-nav .image-gallery-svg{
    max-height: 26px;
    max-width: 16px;
    stroke: #fff;
    transition: stroke 0.3s ease-in-out;
}
#productSingle .single-product-gallery .image-gallery-left-nav .image-gallery-svg:hover, 
#productSingle .single-product-gallery .image-gallery-right-nav .image-gallery-svg:hover{
    stroke: var(--secondary);
    transition: stroke 0.3s ease-in-out;
  }
#productSingle .single-product-gallery .image-gallery-bullets .image-gallery-bullets-container .image-gallery-bullet{
    background: var(--alternative);
    filter: none;
    box-shadow: none;
    border: 0px;
}
#productSingle .single-product-gallery .image-gallery-bullets .image-gallery-bullets-container .image-gallery-bullet.active{
    background: var(--secondary);
}
#productSingle .single-product-gallery .image-gallery-icon.image-gallery-fullscreen-button{
    background: none;
    box-shadow: none;
    filter: none;
}
#productSingle .single-product-gallery .image-gallery-icon.image-gallery-fullscreen-button svg{
    stroke: var(--gray-100);
    transition: stroke 0.3s ease-in-out;
}
#productSingle .single-product-gallery .image-gallery-icon.image-gallery-fullscreen-button:hover svg{
    stroke: var(--secondary);
    transition: stroke 0.3s ease-in-out;
}


#productSingle .single-product-tabs{
    margin-top: 50px;
}
#productSingle .single-product-tabs .tab-headings .single-heading{
    color: var(--gray-500);
    font-size: 18px;
    font-weight: 500;
    padding: 13px 25px 15px;
    border-top: 2px solid #F8F8F8;
    background: #F8F8F8;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}
#productSingle .single-product-tabs .tab-headings .single-heading.active{
    border-top: 2px solid var(--secondary);
    background: #fff;
    transition: all 0.3s ease-in-out;
}
#productSingle .single-product-tabs .single-content{
    padding: 20px 0px;
    font-size: 16px;
    color: var(--gray-400);
}
#productSingle .single-product-tabs .single-content p{
    font-size: 16px;
    color: var(--gray-400);
}
#productSingle .single-product-tabs .single-content > *{
    margin-top: 0px;
}
#productSingle .single-product-tabs .single-content h1,
#productSingle .single-product-tabs .single-content h2,
#productSingle .single-product-tabs .single-content h3,
#productSingle .single-product-tabs .single-content h4,
#productSingle .single-product-tabs .single-content h5,
#productSingle .single-product-tabs .single-content h6{
    color: var(--gray-500);
    font-weight: 500;
    font-size: 18px;
}
#productSingle .invited-occasions-wrapper .invited-occasions-single{
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    margin-right: 15px;
    opacity: 0.8;
    cursor: pointer;
    padding: 5px 0px;
    transition: opacity 0.3s ease-in-out;
}
#productSingle .invited-occasions-wrapper .invited-occasions-single.active{
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}
#productSingle .invited-occasions-wrapper .invited-occasions-single img{
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 80px;
    border: 3px solid #fff;
    box-shadow: 0 0 0 3px #fff;
    transition: box-shadow 0.3s ease-in-out;
}
#productSingle .invited-occasions-wrapper .invited-occasions-single.active img{
    box-shadow: 0 0 0 3px var(--primary);
    transition: box-shadow 0.3s ease-in-out;
}
#productSingle .invited-occasions-wrapper .invited-occasions-single p{
    color: var(--gray-400);
    text-align: center;
    transition: color 0.3s ease-in-out;
}
#productSingle .invited-occasions-wrapper .invited-occasions-single.active p{
    color: var(--gray-500);
    transition: color 0.3s ease-in-out;
}

#productSingle .image-gallery-thumbnail .image-gallery-thumbnail-image{
    height: 90px;
    width: 90px;
    object-fit: contain;
}
#productSingle .image-gallery-thumbnail{
    border: 3px solid #fff;
}
#productSingle .image-gallery-thumbnail:hover,
#productSingle .image-gallery-thumbnail.active, 
#productSingle .image-gallery-thumbnail:focus{
    border: 3px solid var(--primary);
}
#productSingle .product-reviews{
    max-height: 500px;
    overflow: auto;
    padding-right: 50px;
}
#productSingle .product-reviews .single-review{
    margin-bottom: 40px;
}
#productSingle .product-reviews .single-review.pending-review{
    opacity: 0.5;
}
#productSingle .product-reviews .single-review .review-header .review-user-info .review-user-avatar img{
    width: 58px;
    height: 58px;
    object-fit: cover;
    border-radius: 64px;
}
#productSingle .product-reviews .single-review .review-header .review-user-info .review-user-avatar .review-user-avatar-letter{
    width: 58px;
    height: 58px;
    border-radius: 64px;
    background-color: var(--primary);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: var(--white);
    font-weight: 500;
    font-size: 24px;
}
#productSingle .product-review-fields{
    margin-bottom: 40px;
    position: relative;
}
#productSingle .product-review-fields .review-user-field textarea{
    background: none;
    border: 1px solid #D6D5D5;
    border-radius: 5px;
    padding: 10px 20px;
    width: 100%;
    outline: none;
    font-size: 16px;
    resize: none;
    transition: all 0.3s ease-in-out;
}
#productSingle .product-review-fields .review-user-field textarea:focus{
    border: 1px solid var(--primary);
    transition: all 0.3s ease-in-out;
}

#productSingle .product-review-fields .review-user-avatar img{
    width: 58px;
    height: 58px;
    object-fit: cover;
    border-radius: 64px;
}

#singleOccasion .product-card-popup,
#productSingle .product-card-popup{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    z-index: 10;
}
#singleOccasion .product-card-popup.active,
#productSingle .product-card-popup.active{
    display: flex;
}
#singleOccasion .product-card-popup::before,
#productSingle .product-card-popup::before{
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0;
    z-index: 10;
    transition:  all 0.3s ease-in-out;
}
#singleOccasion .product-card-popup.active::before,
#productSingle .product-card-popup.active::before{
    opacity: 0.4;
    transition:  all 0.3s ease-in-out;
}
#singleOccasion .product-card-popup .popup-wrapper,
#productSingle .product-card-popup .popup-wrapper{
    position: relative;
    max-width: 500px;
    width: 100%;
    background-color: #fff;
    padding: 40px 25px;
    border-radius: 15px;
    z-index: 20;
    text-align: center;
}
#singleOccasion .product-card-popup .popup-title,
#productSingle .product-card-popup .popup-title{
    font-size: 20px;
    font-weight: 500;
    color: var(--gray-500);
    margin: 0;
    text-align: center;
}
#singleOccasion .product-card-popup .popup-subheading,
#productSingle .product-card-popup .popup-subheading{
    font-size: 16px;
    font-weight: 400;
    color: var(--secondary);
    margin: 0;
    text-align: center;
    display: block;
}
#singleOccasion .product-card-popup .popup-image-container,
#productSingle .product-card-popup .popup-image-container{
    width: 100%;
    text-align: center;
    margin: 30px 0px;
}
#singleOccasion .product-card-popup .popup-image-container img,
#productSingle .product-card-popup .popup-image-container img{
    width: 100%;
    max-width: 300px;
    max-height: 300px;
    object-fit: contain;
}
#singleOccasion .product-card-popup .btn,
#productSingle .product-card-popup .btn{
    width: 100%;
    max-width: 360px;
    margin: 0 auto;
}
#singleOccasion .product-card-popup a.btn,
#productSingle .product-card-popup a.btn{
    margin: 0 auto 15px;
}
#productSingle .product-related .product-related-header{
    margin-bottom: 30px!important;
}
#productSingle .product-related .react-multi-carousel-list .product-card{
    margin-left: auto;
    margin-right: auto;
}
#productSingle .product-related .react-multi-carousel-list .react-multiple-carousel__arrow {
    min-width: 32px;
    min-height: 32px;
}
#productSingle .product-related .react-multi-carousel-list .react-multiple-carousel__arrow::before {
    font-weight: 900;  
    font-size: 12px;
}
#productSingle .product-related .react-multi-carousel-list .react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
    left: 0;
}
#productSingle .product-related .react-multi-carousel-list .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
    right: 0;
}
#productSingle .product-related-slider .product-card {
    min-width: 280px;
    margin-right: 20px;
}
#productSingle .single-product-gallery .image-gallery-thumbnail {
    width: 98px!important;
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: calc(100vh - 110px) ;
  }
@media screen and (max-width: 1400px) {
    #productSingle .single-product-content .single-product-price{
        font-size: 20px;
        font-weight: 500!important;
    }
    #productSingle .single-product-content .single-product-old-price{
        font-size: 16px;
        font-weight: 400!important;
    }
    #productSingle .single-product-content .product-wishlist {
        font-size: 0px;
    }
    #productSingle .single-product-content .product-wishlist svg{
        margin: 0!important;
    }

    #productSingle .single-product-content .single-product-title{
        font-size: 28px;
    }
    #productSingle .single-product-content .single-product-short-description{
        font-size: 14px;
    }
    #productSingle .single-product-content .product-quantity-container .product-quantity-btn {
        height: 28px;
        width: 28px;
    }
    #productSingle .single-product-content .product-quantity-container .product-quantity-field {
        height: 28px;
        font-size: 16px;
    }
    /* #productSingle .single-product-content > div > .btn.btn-primary {
        width: 100%;
    } */
}
@media screen and (max-width: 1240px) {
    #productSingle .single-product-content .single-product-list {
        font-size: 14px;
    }
    #productSingle .single-product-tabs .tab-headings .single-heading {
        font-size: 16px;
        padding: 8px 20px 10px;
    }
    #productSingle .single-product-tabs .single-content p {
        font-size: 14px;
    }
}
@media screen and (max-width: 1024px) {
    #productSingle .single-product-gallery .tag {
        font-size: 14px;
        padding: 5px 15px;
    }
    #productSingle .single-product-gallery {
        max-width: 550px;
        margin: 0 auto;
    }
    #productSingle .single-product-content {
        max-width: 600px;
        margin: 40px auto 0;
    }
    #productSingle .single-product-tabs .tab-headings{
        justify-content: center;
    }
    #productSingle .product-related .product-related-header{
        margin-bottom: 30px!important;
    }
    #productSingle .product-related .product-related-header h2{
        font-size: 18px;
    }
    #productSingle .product-related .product-related-header p{
        font-size: 14px;
    }
    #productSingle .single-product-tabs .single-content h5, 
    #productSingle .single-product-tabs .single-content h6 {
        font-size: 14px;
        margin: 0 !important;
    }
}
@media screen and (max-width: 600px) {
    #productSingle .single-product-tabs .tab-headings .single-heading {
        font-size: 14px;
        padding: 8px 10px 10px;
    }
    #productSingle .single-product-tabs .single-review .review-header {
        flex-direction: column;
        align-items: initial!important;
    }
    #productSingle .single-product-tabs .single-review .review-header .review-user-date{
        margin-left: 70px;
        margin-top: -20px;
        margin-bottom: 20px;
        color: var(--gray-400)!important;
    }
    #productSingle .product-review-fields {
        margin-bottom: 100px;
        position: relative;
    }
    #productSingle .product-review-fields .btn.btn.btn-primary{
        position: absolute;
        bottom: -60px;
    }
    #productSingle .product-review-fields .review-user-field textarea {
        padding: 10px 10px;
        font-size: 14px;
    }
    #productSingle .product-related .product-card {
        max-width: 310px;
    }
    #singleOccasion .product-card-popup ,
    #productSingle .product-card-popup {
        padding: 0px 20px;
    }
    #singleOccasion .product-card-popup .popup-wrapper ,
    #productSingle .product-card-popup .popup-wrapper {
        max-width: 340px;
        padding: 30px 25px;
    }
    #singleOccasion .product-card-popup .popup-title ,
    #productSingle .product-card-popup .popup-title {
        font-size: 16px;
    }
    #singleOccasion .product-card-popup .popup-subheading ,
    #productSingle .product-card-popup .popup-subheading {
        font-size: 14px;
    }
    #singleOccasion .product-card-popup .popup-image-container img ,
    #productSingle .product-card-popup .popup-image-container img {
        max-width: 200px;
    }
    #productSingle .single-product-content .single-product-list {
        width: 50%;
    }
}
@media screen and (max-width: 500px) {
    #productSingle .single-product-content > div > .btn.btn-primary {
        width: auto;
    }
}
@media screen and (max-width: 420px) {
    #productSingle .single-product-content #buyNowBtn{
        width: 100%;
        margin-top: 20px;
    }
    #productSingle .single-product-content > div > .btn.btn-primary {
        flex: 1;
        margin: 0 !important;
    }
}