.error-page{
    padding: 100px 0px;
}
.error-page .wrapper{
    justify-content: space-around;
}
.error-page .wrapper .error-content{
    max-width: 350px;
    width: 100%;
}
.error-page .wrapper .error-image{
    max-width: 600px;
    width: 100%;
}
.error-page .wrapper .error-image img{
    width: 100%;
}
@media screen and (max-width: 1024px) {
    .error-page .wrapper .error-content{
        max-width: 40%;
    }
    .error-page .wrapper .error-image{
        max-width: 59%;
    }
    .error-page .wrapper .error-content h1{
        font-size: 28px;
    }
    .error-page .wrapper .error-content p{
        font-size: 16px;
    }
}
@media screen and (max-width: 768px) {
    .error-page .wrapper{
        flex-direction: column-reverse;
        justify-content: center;
    }
    .error-page .wrapper .error-image,
    .error-page .wrapper .error-content{
        max-width: 500px!important;
        text-align: center;
    }
    .error-page .wrapper .error-content h1{
        font-size: 24px;
        text-align: center;
        margin-top: 40px!important;
    }
    .error-page .wrapper .error-content p{
        font-size: 16px;
        text-align: center;
    }
}