#discounts{
    padding: 40px 0;
}
#discounts .wrapper .products-wrapper{
    display: grid;
    grid-template-columns: 280px 280px 280px 280px 280px;
    justify-content: space-between;
}
#discounts .wrapper .products-wrapper .product-card{
    margin-bottom: 30px;
}

@media (max-width: 1600px) {
    #discounts .wrapper .products-wrapper{
        grid-template-columns: 225px 225px 225px 225px 225px 225px;
    }
    #discounts .wrapper .products-wrapper .product-card {
        max-width: 225px;
        padding: 8px;
    }
    #discounts .wrapper .products-wrapper .product-card .product-header .product-image img {
        width: 200px;
        height: 200px;
    }
    #discounts .wrapper .products-wrapper .product-card .product-body .product-price .product-currency,
    #discounts .wrapper .products-wrapper .product-card .product-body .product-price .product-amount{
        font-size: 14px;
    }
    #discounts .wrapper .products-wrapper .product-card .product-body .product-title  {
        min-height: 44px;
        font-size: 14px;
    }
    #discounts .wrapper .products-wrapper .product-card .btn {
        font-size: 12px;
        padding: 0px 30px;
        height: 35px;
        margin-top: 5px;
    }
    #discounts .wrapper .products-wrapper .product-card .product-body .product-info .product-stock {
        margin-bottom: 0px;
        font-size: 12px;
    }
}

@media (max-width: 1460px) {
    #discounts .wrapper .products-wrapper{
        grid-template-columns: 225px 225px 225px 225px 225px;
    }
}
@media (max-width: 1200px) {
    #discounts .wrapper .products-wrapper{
        grid-template-columns: 225px 225px 225px 225px;
    }
}
@media (max-width: 980px) {
    #discounts .wrapper .products-wrapper{
        grid-template-columns: 225px 225px 225px;
    }
}
@media (max-width: 740px) {
    #discounts .wrapper .products-wrapper{
        grid-template-columns: 200px 200px 200px;
    }
    #discounts .wrapper .products-wrapper .product-card {
        max-width: 200px;
    }
    #discounts .wrapper .products-wrapper .product-card .product-header .product-image img {
        width: 150px;
        height: 150px;
    }
    #discounts .wrapper .products-wrapper .product-card .product-header .product-wishlist svg {
        width: 14px;
        height: 14px;
    }
    #discounts .wrapper .products-wrapper .product-card .product-header .product-brand {
        width: 50px;
        height: 40px;
    }
}
@media (max-width: 660px) {
    #discounts .wrapper .products-wrapper{
        grid-template-columns: 48% 48%;
    }
    #discounts .wrapper .products-wrapper .product-card {
        max-width: 100%;
    }
    #discounts .wrapper .products-wrapper .product-card .product-header .product-image img {
        width: 100%;
        height: 150px;
        object-fit: cover;
    }
}
@media (max-width: 400px) {
    #discounts .wrapper .products-wrapper{
        grid-template-columns: 100%;
    }
}