.to-be-reviewed-cards {
  width: 100%;
  padding: 15px 20px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #d6d5d5;
  margin-bottom: 20px;
}
.to-be-reviewed-cards .product-image {
  max-width: 140px;
  width: 100%;
  margin: 0;
}
.to-be-reviewed-cards .product-image img {
  width: 140px;
  height: 140px;
  object-fit: contain;
}
.to-be-reviewed-cards .product-name {
  max-width: 200px;
}

.to-be-reviewed-cards .review-form-modal {
  display: none;
}
.to-be-reviewed-cards.active .review-form-modal {
  display: flex;
}

.to-be-reviewed-cards .review-form-modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  padding: 25px;
  display: none;
  align-items: center;
  justify-content: center;
}
.to-be-reviewed-cards .review-form-modal .review-form-container {
  background-color: #fefefe;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #888;
  max-width: 500px;
  width: 100%;
  border-radius: 10px;
}
.textarea-review {
  position: relative;
  max-width: 1140px;
  width: 100%;
  padding: 10px 10px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #d6d5d5;
  margin: 2rem 0rem;
  resize: none;
}

@media screen and (max-width: 1200px) {
  .to-be-reviewed-cards {
    max-width: 48%;
    width: 100%;
  }
  .to-be-reviewed-cards .product-image {
    margin: 0 auto;
    width: 100%;
  }
  .to-be-reviewed-cards .product-name {
    max-width: 100%;
    text-align: center;
    margin: 20px 0;
    width: 100%;
  }
  .to-be-reviewed-cards .write-review-btn {
    margin: 20px auto 0 auto;
  }
  .to-be-reviewed-cards .produt-brand {
    width: 100%;
    text-align: center;
    font-size: 14px;
  }
  .to-be-reviewed-cards .produt-brand p,
  .to-be-reviewed-cards .produt-brand span{
    font-size: 14px;
    font-weight: 500;
  }
  .to-be-reviewed-cards .product-purchase-date {
    width: 100%;
    text-align: center;
    font-size: 14px;
  }
  .to-be-reviewed-cards .product-purchase-date p,
  .to-be-reviewed-cards .product-purchase-date span{
    font-size: 14px;
    font-weight: 500;
  }
}

@media screen and (max-width: 830px) {
  .to-be-reviewed-cards {
    max-width: 100%;
  }
}
@media screen and (max-width: 700px) {
  .to-be-reviewed-cards {
    max-width: 48%;
  }
}
@media screen and (max-width: 440px) {
  .to-be-reviewed-cards {
    max-width: 100%;
  }
}
