#productList{
    padding: 100px 0px;
}
#productList .wrapper .product-sidebar{
    width: 100%;
    min-width: 360px;
    max-width: 360px;
    padding-right: 20px;
    border-right: 1px solid var(--gray-100);
    margin-right: 35px;
}
.RTL #productList .wrapper .product-sidebar{
    border-right: 0px;
    padding-right: 0px;
    margin-right: 0px;
    border-left: 1px solid var(--gray-100);
    padding-left: 20px;
    margin-left: 35px;
}
#productList .wrapper .product-sidebar .product-sidebar-close,
#productList .wrapper .product-sidebar .product-sidebar-overlay{
    display: none;
}
#productList .wrapper .product-sidebar .sidebar-section-header{
    margin: 0 0 25px;
}
#productList .wrapper .product-sidebar .category-filters,
#productList .wrapper .product-sidebar .brand-filters{
    max-height: 410px;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
}
body.RTL #productList .wrapper .product-sidebar .category-filters,
body.RTL #productList .wrapper .product-sidebar .brand-filters{
    max-height: 420px;
}
body.RTL #productList .wrapper .product-sidebar .category-filters.active,
body.RTL #productList .wrapper .product-sidebar .brand-filters.active,
#productList .wrapper .product-sidebar .category-filters.active,
#productList .wrapper .product-sidebar .brand-filters.active{
    max-height: 5000px;
    transition: max-height 0.3s ease-in-out;
}
#productList .wrapper .product-sidebar .rating-filters{
    display: inline-flex;
    flex-direction: column;
}
#productList .wrapper .product-sidebar .rating-filters button{
    display: inline-flex;
    margin-bottom: 10px;
    background: none;
    border: none;
    outline: navajowhite;
    align-items: center;
    color: var(--gray-400);
    padding: 0px;
    height: 25px;
    font-size: 16px;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
}
#productList .wrapper .product-sidebar .rating-filters button.active{
    color: var(--primary);
    transition: color 0.3s ease-in-out;
}
#productList .wrapper .product-sidebar .rating-filters button > i:last-of-type{
    margin: 0px 10px 0px 0px;
}
.RTL #productList .wrapper .product-sidebar .rating-filters button > i:last-of-type{
    margin: 0px 0px 0px 10px;
}
#productList .wrapper .product-sidebar .sidebar-heading{
    margin: 5px 0 0;
    padding-bottom: 5px;
    border-bottom: 1px solid var(--secondary);
}
/* #productList .wrapper .product-sidebar .filter-search{
    position: relative;
    margin-bottom: 20px;
}
#productList .wrapper .product-sidebar .filter-search input{
    width: 100%;
    background: none;
    border: 1px solid var(--gray-100);
    outline: none;
    padding: 8px 15px;
    font-size: 14px;
    border-radius: 5px;
    margin: 0;
    transition: all 0.3s ease-in-out;
}
#productList .wrapper .product-sidebar .filter-search input:focus{
    border: 1px solid var(--primary);
    transition: all 0.3s ease-in-out;
}
#productList .wrapper .product-sidebar .filter-search i{
    position: absolute;
    color: var(--gray-400);
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    padding-left: 10px;
    border-left: 1px solid var(--gray-100);
}
*/
#productList .wrapper .product-sidebar .filter-range{
    gap: 0px 10px;
}

#productList .wrapper .product-sidebar .filter-range input::-webkit-outer-spin-button,
#productList .wrapper .product-sidebar .filter-range input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
#productList .wrapper .product-sidebar .filter-range input{
    -moz-appearance: textfield;
    border: 1px solid var(--gray-100);
    outline: none;
    padding: 8px 15px;
    font-size: 14px;
    border-radius: 5px;
    background: none;
    margin: 0;
    transition: all 0.3s ease-in-out;
}
#productList .wrapper .product-sidebar .price-filter .multi-range-slider {
    border: 0px;
    border-radius: 0px;
    padding: 0px 12px 0px 9px;
    box-shadow: none;
}
#productList .wrapper .product-sidebar .price-filter .multi-range-slider .label {
    margin: 10px -13px -20px -3px;
}
#productList .wrapper .product-sidebar .price-filter .multi-range-slider .ruler{
    display: none;
}
#productList .wrapper .product-sidebar .price-filter .multi-range-slider .bar-left,
#productList .wrapper .product-sidebar .price-filter .multi-range-slider .bar-right{
    background-color: #A9A9A9;
    border-radius: 0;
    box-shadow: none;
    padding: 0;
}
#productList .wrapper .product-sidebar .price-filter .multi-range-slider .bar-inner {
    background-color: var(--secondary);
    border: 1px solid var(--secondary);
    box-shadow: none;
}
#productList .wrapper .product-sidebar .price-filter .multi-range-slider .thumb::before {
    background-color: var(--secondary);
    border: 0px;
    box-shadow: none;
    cursor: pointer;
}
#productList .wrapper .product-sidebar .price-filter .multi-range-slider .thumb * {
    bottom: 25px;
    left: 50%;
    font-size: 14px;
    font-weight: 500;
    background-color: var(--alternative);
    border-radius: 5px;
    color: var(--secondary);
    box-shadow: none;
    width: auto;
    padding: 0px 10px;
    text-align: center;
    transform: translateX(-50%);
} 
#productList .wrapper .product-sidebar .product-sidebar-wrapper .current-filters ul.ais-CurrentRefinements-list{
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}
#productList .wrapper .product-sidebar .product-sidebar-wrapper .current-filters ul.ais-CurrentRefinements-list li{
    padding: 0;
    margin: 0;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
}
#productList .wrapper .product-sidebar .product-sidebar-wrapper .current-filters ul.ais-CurrentRefinements-list li .ais-CurrentRefinements-label{
    display: none;
}
#productList .wrapper .product-sidebar .product-sidebar-wrapper .current-filters ul.ais-CurrentRefinements-list li .ais-CurrentRefinements-category{
    font-weight: 500;
    font-size: 14px;
    color: var(--gray-500);
    background-color: var(--alternative);
    align-items: center;
    display: inline-flex;
    padding: 2px 8px;
    border-radius: 5px;
    margin-right: 10px;
    margin-bottom: 10px;
}
#productList .wrapper .product-sidebar .product-sidebar-wrapper .current-filters ul.ais-CurrentRefinements-list li .ais-CurrentRefinements-category button{
    font-weight: 800;
    font-size: 10px;
    color: var(--gray-500);
    background: none;
    border: 0px;
    padding: 0px;
    margin: 1px 0 0 6px;
    outline: none;
    box-shadow: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 10px;
    cursor: pointer;
}

#productList .wrapper .product-sidebar .product-sidebar-wrapper .sidebar-filters .ais-RefinementList ul{
    list-style: none;
    padding: 0px;
    margin: 0px;
}
#productList .wrapper .product-sidebar .product-sidebar-wrapper .sidebar-filters .ais-RefinementList ul li{
    margin-bottom: 10px;
}
#productList .wrapper .product-sidebar .product-sidebar-wrapper .sidebar-filters .ais-RefinementList .ais-RefinementList-showMore{
    background: none;
    border: 0px;
    outline: none;
    padding: 0px;
    margin: 10px 0 0 0;
    color: var(--primary);
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}
#productList .wrapper .product-sidebar .product-sidebar-wrapper .sidebar-filters .ais-RefinementList .ais-RefinementList-showMore.ais-RefinementList-showMore--disabled{
    display: none;
}
#productList .wrapper .product-sidebar .product-sidebar-wrapper .sidebar-filters .ais-RefinementList ul li label .ais-RefinementList-checkbox{
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
#productList .wrapper .product-sidebar .product-sidebar-wrapper .sidebar-filters .ais-RefinementList ul li label .ais-RefinementList-checkbox + span.ais-RefinementList-labelText{
    position: relative;
    font-size: 16px;
    color: var(--gray-500);
    font-weight: 500;
    cursor: pointer;
}
#productList .wrapper .product-sidebar .product-sidebar-wrapper .sidebar-filters .ais-RefinementList ul li label .ais-RefinementList-checkbox + span.ais-RefinementList-labelText::before{
    content: '';
    display: inline-block;
    border: 1px solid var(--primary);
    height: 15px;
    width: 15px;
    border-radius: 3px;
    vertical-align: middle;
    margin-right: 10px;
    margin-bottom: 2px;
    transition: all 0.3s ease-in-out;
}
.RTL #productList .wrapper .product-sidebar .product-sidebar-wrapper .sidebar-filters .ais-RefinementList ul li label .ais-RefinementList-checkbox + span.ais-RefinementList-labelText::before{
    margin-right: 0px;
    margin-left: 10px;
}
#productList .wrapper .product-sidebar .product-sidebar-wrapper .sidebar-filters .ais-RefinementList ul li label .ais-RefinementList-checkbox:checked + span.ais-RefinementList-labelText::before{
    background: var(--primary);
    transition: all 0.3s ease-in-out;
}
#productList .wrapper .product-sidebar .product-sidebar-wrapper .sidebar-filters .ais-RefinementList ul li label .ais-RefinementList-checkbox:checked + span.ais-RefinementList-labelText::after{
    content: "";
    display: inline-block;
    height: 7px;
    width: 4px;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    position: absolute;
    top: 6px;
    left: 6px;
    transition: all 0.3s ease-in-out;
    transform: rotate(45deg);
}
.RTL #productList .wrapper .product-sidebar .product-sidebar-wrapper .sidebar-filters .ais-RefinementList ul li label .ais-RefinementList-checkbox:checked + span.ais-RefinementList-labelText::after{
    right: 6px;
    left: auto;
}
#productList .wrapper .product-sidebar .product-sidebar-wrapper .sidebar-filters .rating-count,
#productList .wrapper .product-sidebar .product-sidebar-wrapper .sidebar-filters .ais-RefinementList ul li label .ais-RefinementList-count{
    display: inline-block;
    margin-left: 10px;
    color: var(--gray-500);
    font-size: 14px;
    font-weight: 500;
}
.RTL #productList .wrapper .product-sidebar .product-sidebar-wrapper .sidebar-filters .rating-count,
.RTL #productList .wrapper .product-sidebar .product-sidebar-wrapper .sidebar-filters .ais-RefinementList ul li label .ais-RefinementList-count{
    margin-left: 0px;
    margin-right: 10px;
}
#productList .wrapper .product-sidebar .product-sidebar-wrapper .sidebar-filters .rating-count::before,
#productList .wrapper .product-sidebar .product-sidebar-wrapper .sidebar-filters .ais-RefinementList ul li label .ais-RefinementList-count::before{
    content: "[";
    font-size: 12px;
    font-weight: 600;
}
#productList .wrapper .product-sidebar .product-sidebar-wrapper .sidebar-filters .rating-count::after,
#productList .wrapper .product-sidebar .product-sidebar-wrapper .sidebar-filters .ais-RefinementList ul li label .ais-RefinementList-count::after{
    content: "]";
    font-size: 12px;
    font-weight: 600;
}
#productList .wrapper .products-container{
    max-width: calc( 100% - 395px );
    width: 100%;
}
#productList .wrapper .products-wrapper{
    display: grid;
    justify-content: space-between;
    grid-template-columns: 260px 260px 260px 260px;
}
#productList .wrapper .products-wrapper .product-card .product-header .product-image img {
    width: 100%;
}
#productList .wrapper .banner-image-container{
    position: relative;
    margin-bottom: 40px;
}
#productList .wrapper .banner-image-container .banner-image{
    width: 100%;
    height: 230px;
    object-fit: cover;
    border-radius: 5px;
}
#productList .wrapper .banner-image-container .banner-image-text{
    position: absolute;
    top: 50%;
    right: 50px;
    transform: translateY(-50%);
    background: var(--secondary);
    border-radius: 10px;
    padding: 40px 30px;
    margin: 0px 20px;
    opacity: 0.85;
}
#productList .wrapper .banner-image-container .banner-image-text h4{
    margin:  0;
    color: var(--white);
    font-size: 34px;
    font-weight: 500;
}
#productList .wrapper .banner-image-container .banner-image-text h6{
    margin:  0px;
    color: var(--white);
    font-size: 24px;
    font-weight: 300;
}
#productList .wrapper .banner-image-container .banner-image-text h6 span{
    font-size: 28px;
    font-weight: 500;
}
#productList .wrapper .products-filter-handle,
#productList .wrapper .products-sort{
    margin-bottom: 40px;
}
#productList .wrapper .products-sort label{
    margin: 0 10px 0 0;
    color: var(--gray-500);
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
}
.RTL #productList .wrapper .products-sort label{
    margin: 0 0 0 10px;
}
#productList .wrapper .products-sort .productSort{
    appearance: none;
    background-color: #f8f8f8;
    border: none;
    padding: 5px 20px 5px 10px;
    margin: 0;
    width: 150px;
    font-size: 16px;
    font-weight: 400;
    border-radius: 5px;
    height: 40px;
}
#productList .wrapper .products-filter-handle .btn-filter{
    background-color: #f8f8f8;
    color: var(--gray-500);
    border-radius: 5px;
    padding: 5px 20px 5px 10px;
    border: none;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    display: none;
    align-items: center;
}
#productList .wrapper .grand-child-categories {
    margin-bottom: 30px;
}
#productList .wrapper .grand-child-categories .grand-child-category.loading{
    padding: 0;
    border: 0;
    border-radius: 5px;
    white-space: nowrap;
    min-width: 120px;
    overflow: hidden;
}
#productList .wrapper .grand-child-categories .grand-child-category {
    background-color: var(--white);
    border: 1px solid var(--secondary);
    display: inline-flex;
    font-size: 12px;
    padding: 5px 10px;
    margin: 0 10px 0 0;
    border-radius: 20px;
    color: var(--secondary);
    cursor: pointer;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    white-space: nowrap;
    transition: all 0.3s ease-in-out;
}
#productList .wrapper .grand-child-categories > span.grand-child-category:last-of-type {
    margin: 0;
}
#productList .wrapper .grand-child-categories .grand-child-category:hover {
    background-color: var(--secondary-hover);
    border: 1px solid var(--secondary-hover);
    color: var(--white);
    transition: all 0.3s ease-in-out;
}
#productList .wrapper .grand-child-categories .grand-child-category.active {
    background-color: var(--secondary);
    border: 1px solid var(--secondary);
    color: var(--white);
    transition: all 0.3s ease-in-out;
}
#productList .wrapper .grand-child-categories .grand-child-category.loading:hover {
    background-color: #fff;
    border: 0px;
    transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 1600px) {
    #productList .wrapper .product-sidebar {
        min-width: 280px;
        max-width: 280px;
    }
    
    #productList .wrapper .products-container{
        max-width: calc( 100% - 315px );
    }
}
@media screen and (max-width: 1520px) {
    #productList .wrapper .product-sidebar {
        min-width: 360px;
        max-width: 360px;
    }
    #productList .wrapper .products-container{
        max-width: calc( 100% - 395px );
    }
    #productList .wrapper .products-wrapper {
        grid-template-columns: 300px 300px 300px;
    }
    #productList .wrapper .products-wrapper .product-card {
        max-width: 300px;
    }
}
@media screen and (max-width: 1360px) {
    #productList .wrapper .products-wrapper .product-card {
        max-width: 275px;
    }
    #productList .wrapper .products-wrapper {
        grid-template-columns: 275px 275px 275px;
    }

}
@media screen and (max-width: 1300px) {
    #productList .wrapper .product-sidebar {
        min-width: 280px;
        max-width: 280px;
        margin-right: 20px;
    }
    #productList .wrapper .products-container{
        max-width: calc( 100% - 300px );
    }
}
@media screen and (max-width: 1200px) {
    
    #productList .wrapper .product-sidebar {
        min-width: 360px;
        max-width: 360px;
        margin-right: 35px;
    }
    #productList .wrapper .products-container{
        max-width: calc( 100% - 395px );
    }
    #productList .wrapper .products-wrapper {
        grid-template-columns: 300px 300px;
    }
    #productList .wrapper .products-wrapper .product-card {
        max-width: 300px;
    }
}
@media screen and (max-width: 1080px) {
    #productList .wrapper .products-wrapper {
        grid-template-columns: 275px 275px;
    }
    #productList .wrapper .products-wrapper .product-card {
        max-width: 275px;
    }
    #productList .wrapper .products-wrapper .product-card .product-header .product-image img {
        width: 200px;
        height: 200px;
    }

}
@media screen and (max-width: 1024px) {
    #productList .wrapper .banner-image-container .banner-image-text h4 {
        font-size: 26px;
    }
    #productList .wrapper .banner-image-container .banner-image-text h6 {
        font-size: 18px;
    }
    #productList .wrapper .banner-image-container .banner-image-text h6 span {
        font-size: 22px;
    }
    #productList .wrapper .product-sidebar {
        min-width: 280px;
        max-width: 280px;
        margin-right: 20px;
    }
    #productList .wrapper .products-container{
        max-width: calc( 100% - 300px );
    }
}
@media screen and (max-width: 920px) {
    #productList {
        padding: 50px 0px;
    }
    #productList .wrapper .products-filter-handle .btn-filter{
        display: inline-flex;
    }
    #productList .wrapper .product-sidebar {
        min-width: 100vw;
        max-width: 100vw;
        margin-right: 0;
        padding: 0;
        position: fixed;
        top: 0;
        bottom: 0;
        left: -100vw;
        z-index: 10;
        border: 0px!important;
        transition: all 0.3s ease-in-out;
    }
    .RTL #productList .wrapper .product-sidebar {
        right: -100vw;
        left: auto;
        transition: all 0.3s ease-in-out;
    }
    #productList .wrapper .product-sidebar .product-sidebar-wrapper {
        min-width: 360px;
        max-width: 360px;
        margin-right: 0;
        position: fixed;
        top: 0;
        left: -360px;
        width: 100%;
        background: #fff;
        z-index: 10;
        padding: 50px 20px;
        height: 100%;
        overflow: auto;
        transition: all 0.3s ease-in-out;
    }
    .RTL #productList .wrapper .product-sidebar .product-sidebar-wrapper {
        left: auto;
        right: -360px;
    }
    #productList .wrapper .product-sidebar .product-sidebar-close{
        display: inline-flex;
        position: absolute;
        color: var(--gray-500);
        font-size: 20px;
        top: 10px;
        left: -50px;
        z-index: 11;
        background-color: #fff;
        cursor: pointer;
        border-radius: 5px;
        border: 1px solid var(--gray-200);
        transition: left 0.3s ease-in-out;
    }
    .RTL #productList .wrapper .product-sidebar .product-sidebar-close{
        left: auto;
        right: -50px;
        transition: right 0.3s ease-in-out;
    }
    #productList .wrapper.sidebar-open .product-sidebar .product-sidebar-close{
        left: 320px;
        transition: left 0.3s ease-in-out;
    }
    .RTL #productList .wrapper.sidebar-open .product-sidebar .product-sidebar-close{
        left: auto;
        right: 320px;
        transition: right 0.3s ease-in-out;
    }
    #productList .wrapper.sidebar-open .product-sidebar .product-sidebar-overlay{
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0.3;
        z-index: 5;
        transition: all 0.3s ease-in-out;
    }
    .RTL #productList .wrapper.sidebar-open .product-sidebar .product-sidebar-overlay{
        left: auto;
        right: 0;
        transition: all 0.3s ease-in-out;
    }
    #productList .wrapper.sidebar-open .product-sidebar{
        left: 0;
        transition: all 0.3s ease-in-out;
    }
    .RTL #productList .wrapper.sidebar-open .product-sidebar{
        right: 0;
        left: auto;
        transition: all 0.3s ease-in-out;
    }
    #productList .wrapper.sidebar-open .product-sidebar .product-sidebar-wrapper {
        left: 0;
        transition: all 0.3s ease-in-out;
    }
    .RTL #productList .wrapper.sidebar-open .product-sidebar .product-sidebar-wrapper {
        right: 0;
        left: auto;
        transition: all 0.3s ease-in-out;
    }
    #productList .wrapper .products-container {
        max-width:100%;
    }
    #productList .wrapper .products-wrapper {
        grid-template-columns: 250px 250px 250px;
    }
    #productList .wrapper .products-wrapper .product-card {
        max-width: 250px;
    }
    #productList .wrapper .product-card .product-body .product-title {
        font-size: 16px;
        height: 50px;
        max-height: 52px;
        overflow: hidden;
    }
    #productList .wrapper .product-card .product-tag {
        padding: 5px 20px;
        font-size: 14px;
    }
    
    #productList .wrapper .product-card .product-body .product-info .product-stock{
        font-size: 14px;
    }
}
@media screen and (max-width: 820px) {
    #productList .wrapper .products-wrapper {
        grid-template-columns: 235px 235px 235px;
    }
    #productList .wrapper .products-wrapper .product-card {
        max-width: 235px;
    }
    #productList .wrapper .products-wrapper .product-card .product-header .product-image img {
        width: 180px;
        height: 180px;
    }
}
@media screen and (max-width: 768px) {
    #productList .wrapper .products-wrapper {
        grid-template-columns: 300px 300px;
    }
    #productList .wrapper .products-wrapper .product-card {
        max-width: 300px;
    }
    #productList .wrapper .products-wrapper .product-card .product-header .product-image img {
        width: 240px;
        height: 240px;
    }
}
@media screen and (max-width: 700px) {
    
    #productList .wrapper .products-wrapper {
        grid-template-columns: 260px 260px;
    }
    #productList .wrapper .products-wrapper .product-card {
        max-width: 260px;
    }
    #productList .wrapper .products-wrapper .product-card .product-header .product-image img {
        width: 180px;
        height: 180px;
    }
    #productList .wrapper .product-card.dummy {
        height: 340px;
    }
}
@media screen and (max-width: 560px) {
    
    #productList .wrapper .products-wrapper {
        grid-template-columns: 49% 49%;
    }
    #productList .wrapper .products-wrapper .product-card {
        max-width: 100%;
    }
    #productList .wrapper .products-wrapper .product-card .product-header .product-image img {
        width: 160px;
        height: 160px;
    }
    #productList .wrapper .product-card .product-body .product-title {
        font-size: 14px;
    }
    #productList .wrapper .product-card .product-body .product-price {
        margin-top: 0px;
        margin-bottom: 10px;
    }
    #productList .wrapper .product-card .product-body .product-price .product-amount,
    #productList .wrapper .product-card .product-body .product-price .product-currency {
        font-size: 16px;
    }
    #productList .wrapper .banner-image-container .banner-image-text h4 {
        font-size: 26px;
    }
    #productList .wrapper .banner-image-container .banner-image-text h6 {
        font-size: 20px;
    }
    #productList .wrapper .banner-image-container .banner-image-text {
        right: auto;
        left: 50%;
        transform: translate(-50%,-50%);
        padding: 30px;
        width: 100%;
        max-width: 80%;
        margin: 0 auto;
    }
    #productList .wrapper .products-sort label {
        display: none;
    }
}
@media screen and (max-width: 420px) {
    #productList .wrapper .product-card {
        max-width: 100%;
    }
    #productList .wrapper .product-card .product-header .product-image img {
        width: 200px;
        height: 200px;
    }
}
@media screen and (max-width: 375px) {
    #productList .wrapper .products-wrapper {
        grid-template-columns: 100%;
    }
}
@media screen and (max-width: 360px) {
    #productList .wrapper .product-sidebar .product-sidebar-wrapper {
        min-width: 100%;
        max-width: 100%;
    }
}