#topbar {
  position: relative;
  padding: 15px 0px 25px;
  margin-bottom: 20px;
}
#topbar::before {
  content: "";
  background-image: url("../images/header-divider.png");
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px;
  z-index: 1;
  background-repeat: repeat-x;
  background-size: contain;
}
#topbar .wrapper > div {
  flex: 1;
}
#topbar .wrapper .top-bar-location {
  font-size: 14px;
}
#topbar .wrapper .top-bar-location svg {
  margin-right: 10px;
  font-size: 18px;
}
.RTL #topbar .wrapper .top-bar-location svg {
  margin-right: 0px;
  margin-left: 10px;
}
#topbar .wrapper .top-bar-headline {
  font-size: 14px;
}
#topbar .wrapper .top-bar-headline a {
  font-size: 14px;
  font-weight: 600;
}
#topbar .wrapper .top-bar-right .top-bar-currency .top-bar-currency-selector,
#topbar .wrapper .top-bar-right .top-bar-language .top-bar-language-selector {
  background: none;
  border: 0px;
  outline: none;
  cursor: pointer;
}
#topbar
  .wrapper
  .top-bar-right
  .top-bar-currency
  .top-bar-currency-selector
  option,
#topbar
  .wrapper
  .top-bar-right
  .top-bar-language
  .top-bar-language-selector
  option {
  color: var(--black);
}

#headerNavigation .all-category-wrapper {
  position: relative;
}
#headerNavigation .all-category-overlay {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

#headerNavigation .all-category-wrapper .all-categories-container{
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0px;
  background: var(--link);
  box-shadow: 0px 0px 10px #cecece;
  width: 300px;
  height: 100vh;
  overflow: auto;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
#headerNavigation .all-category-wrapper .all-categories-container::-webkit-scrollbar {
  display: none;
}
#headerNavigation .all-category-wrapper .all-categories-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
#headerNavigation .all-category-wrapper .all-categories-container .menu-close{
  position: absolute;
  top: 30px;
  right: 30px;
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 30px;
  font-weight: 500;
  cursor: pointer;
}

#headerNavigation .all-category-wrapper .all-categories-container .all-category-menu{
  max-width: 70%;
  width: 100%;
  padding: 20px 30px;
}
#headerNavigation .all-category-wrapper .all-categories-container .all-category-menu .menu-logo{
  width: 120px;
}
#headerNavigation .all-category-wrapper .all-categories-container .all-category-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#headerNavigation .all-category-wrapper .all-categories-container .all-category-menu ul li{
  width: 100%;
}

#headerNavigation .all-category-wrapper .all-categories-container .all-category-menu ul li.menu-item a{
  color: var(--white);
  padding: 10px 0px;
  display: block;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}

#headerNavigation .all-category-wrapper .all-categories-container .all-category-menu ul li .category-item-link:hover {
  color: var(--primary);
  transition: all 0.3s ease-in-out;
}
#headerNavigation .all-category-wrapper .all-categories-container .all-category-menu .all-category-filters > div {
  width: 100%;
  max-width: 33%;
}
#headerNavigation .all-category-wrapper .all-categories-container .all-category-menu .all-category-filters > div:first-of-type {
  width: 100%;
  max-width: 66%;
}
#headerNavigation .all-category-wrapper .all-categories-container .all-category-menu .all-category-filters > div ul,
#headerNavigation .all-category-wrapper .all-categories-container .all-category-menu .all-category-filters > div ul li{
  width: 100%;
  max-width: 100%;
}
#headerNavigation .all-category-wrapper .all-categories-container .all-category-menu .all-category-filters > div:first-of-type ul li{
  width: 100%;
  max-width: 50%;
}

.all-category-wrapper .all-categories-container .all-category-images{
    max-width: 30%;
    width: 100%;
}
.all-category-wrapper .all-categories-container .all-category-images img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
header {
  padding: 20px 0px;
}
header .wrapper {
  gap: 0px 50px;
}

.gap-2{
  gap: 2rem;
}

header .wrapper .header-logo img {
  width: 100%;
  max-width: 150px;
}
header .wrapper .search-close,
header .wrapper .search-toggle{
  display: none;
}
/* header .wrapper .ais-SearchBox, */
header .wrapper .header-search {
  flex: 1;
}
header .wrapper .ais-SearchBox .ais-SearchBox-form{
  position: relative;
  width: 100%;
  align-items: center;
  display: flex;
}
header .wrapper .ais-Hits{
  position: relative;
  width: 100%;
  z-index: 99;
}
header .wrapper .ais-Hits.ais-Hits--empty .ais-Hits-list{
  display: none!important;
}
header .wrapper .ais-Hits .ais-Hits-list{
  position: absolute;
  width: 100%;
  list-style: none;
  padding: 0px;
  background: #fff;
  border: 1px solid var(--gray-100);
  border-radius: 5px;
}
header .wrapper .ais-Hits .ais-Hits-list .ais-Hits-item .search-product-result{
  display: flex;
  text-decoration:  none;
  padding: 15px;
  border-bottom: 1px solid var(--gray-100);
}
header .wrapper .ais-Hits .ais-Hits-list > li.ais-Hits-item .showMoreLink{
  display: none;
}
header .wrapper .show .ais-Hits .ais-Hits-list > li.ais-Hits-item:last-of-type .showMoreLink{
  display: flex;
  text-decoration: none;
  padding: 15px;
  border-top: 1px solid var(--gray-100);
  text-align: center;
  align-items: center;
  justify-content: center;
  color: var(--gray-500);
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
header .wrapper .show .ais-Hits .ais-Hits-list > li.ais-Hits-item:last-of-type .showMoreLink:hover{
  color: var(--primary);
  transition: all 0.3s ease-in-out;
}
header .wrapper .ais-Hits .ais-Hits-list > li.ais-Hits-item:last-of-type .search-product-result{
  border-bottom: none;
}
header .wrapper .ais-Hits .ais-Hits-list .ais-Hits-item .search-product-result .search-product-image{
  margin-right: 10px;
  max-width: 70px;
  width: 100%;
}
.RTL header .wrapper .ais-Hits .ais-Hits-list .ais-Hits-item .search-product-result .search-product-image{
  margin-right: 0px;
  margin-left: 10px;
}
header .wrapper .ais-Hits .ais-Hits-list .ais-Hits-item .search-product-result .search-product-image img{
  width: 70px;
  height: 70px;
  object-fit: cover;
  background-image: url(../images/picture-loading.gif);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
header .wrapper .ais-Hits .ais-Hits-list .ais-Hits-item .search-product-result .search-product-info{
  max-width: calc(70% - 80px);
  width: 100%;
}
header .wrapper .ais-Hits .ais-Hits-list .ais-Hits-item .search-product-result .search-product-info h4{
  font-size: 14px;
  font-weight: 500;
  color: var(--gray-400);
  margin: 0px;
}
header .wrapper .ais-Hits .ais-Hits-list .ais-Hits-item .search-product-result .search-product-info h2{
  font-size: 16px;
  font-weight: 600;
  color: var(--gray-500);
  margin: 0px;
  overflow: hidden;
  height: 22px;
}
header .wrapper .ais-Hits .ais-Hits-list .ais-Hits-item .search-product-result .search-product-rating{
  display: flex;
  align-items: center;
}
header .wrapper .ais-Hits .ais-Hits-list .ais-Hits-item .search-product-result .search-product-rating span{
  font-weight: 400;
  color: var(--gray-400);
  font-size: 14px;
}
header .wrapper .ais-Hits .ais-Hits-list .ais-Hits-item .search-product-result .search-product-price{
  text-align: right;
  max-width: calc(70% - 80px);
  width: 100%;
}
.RTL header .wrapper .ais-Hits .ais-Hits-list .ais-Hits-item .search-product-result .search-product-price{
  text-align: left;
}
header .wrapper .ais-Hits .ais-Hits-list .ais-Hits-item .search-product-result .search-product-price span{
  font-weight: 500;
  color: var(--gray-500);
  font-size: 18px;
}
header .wrapper .header-search .search-wrapper {
  position: relative;
  width: 100%;
}
header .wrapper .ais-SearchBox .ais-SearchBox-form .ais-SearchBox-input,
header .wrapper .header-search .search-wrapper .search-field {
  background: none;
  outline: none;
  font-weight: 400;
  font-size: 16px;
  color: var(--gray-300);
  height: 50px;
  padding: 0px 20px;
  border: 1px solid var(--gray-300);
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  width: 100%;
}
header .wrapper .ais-SearchBox .ais-SearchBox-form .ais-SearchBox-input:focus,
header .wrapper .header-search .search-wrapper .search-field:focus {
  border: 1px solid var(--primary);
  color: var(--gray-400);
  transition: all 0.3s ease-in-out;
}
header .wrapper .ais-SearchBox .ais-SearchBox-form .ais-SearchBox-submit,
header .wrapper .header-search .search-wrapper .search-button {
  background: var(--primary);
  outline: none;
  border: 0px;
  font-size: 20px;
  color: var(--white);
  padding: 0px 30px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.3s ease-in-out;
  height: 50px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.RTL header .wrapper .ais-SearchBox .ais-SearchBox-form .ais-SearchBox-submit,
.RTL header .wrapper .header-search .search-wrapper .search-button {
  right: auto;
  left: 0;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
header .wrapper .ais-SearchBox .ais-SearchBox-form .ais-SearchBox-submit:hover,
header .wrapper .header-search .search-wrapper .search-button:hover {
  color: var(--white);
  background: var(--primary-hover);
  transition: all 0.3s ease-in-out;
}
header .wrapper .ais-SearchBox .ais-SearchBox-form .ais-SearchBox-submit svg{
  fill: #fff;
  width: 20px;
  height: 16px;
  stroke-width: 2px;
  stroke: #fff;
}
header .wrapper .ais-SearchBox .ais-SearchBox-form .ais-SearchBox-reset{
  display: none;
}

header .wrapper .header-right .header-cart,
header .wrapper .header-right .header-info {
  margin-right: 25px;
  padding-right: 25px;
  position: relative;
}
.RTL header .wrapper .header-right .header-cart,
.RTL header .wrapper .header-right .header-info {
  margin-right: 0px;
  padding-right: 0px;
  margin-left: 25px;
  padding-left: 25px;
}
header .wrapper .header-right .header-cart::after,
header .wrapper .header-right .header-info::after {
  content: "";
  display: inline-block;
  height: 35px;
  width: 1px;
  background-color: var(--gray-300);
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  right: 0;
}
.RTL header .wrapper .header-right .header-cart::after,
.RTL header .wrapper .header-right .header-info::after {
  right: auto;
  left: 0;
}
header .wrapper .header-right .header-info svg {
  margin-right: 20px;
}
.RTL header .wrapper .header-right .header-info svg {
  margin-right: 0px;
  margin-left: 20px;
}
header .wrapper .header-right .header-info div span {
  font-weight: 400;
  font-size: 12px;
}
header .wrapper .header-right .header-info div a {
  font-weight: 600;
  font-size: 18px;
}
header .wrapper .header-right .header-cart {
  position: relative;
}

header .wrapper .header-right .header-cart:not([data-cart="0"])::before {
  content: attr(data-cart);
  position: absolute;
  top: -10px;
  right: 10px;
  background-color: var(--primary);
  color: var(--white);
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border-radius: 20px;
}

#headerNavigation .menu-wrapper{
  max-width: calc( 100% - 500px );
  overflow: hidden;
  width: 100%;
}
body.RTL #headerNavigation .menu-wrapper{
  flex-direction: row-reverse;
}
#headerNavigation #headerMenuWrapperParent #menuNavigateLeft,
#headerNavigation #headerMenuWrapperParent #menuNavigateRight{
  background: #fff;
  padding: 20px;
  border: 0px;
  outline: 0px;
  cursor: pointer;
  font-size: 18px;
}
#headerNavigation #headerMenuWrapper{
  max-width: calc( 100% - 50px );
  overflow: hidden;
  scroll-behavior: smooth;
}
#headerNavigation #headerMenuWrapper #headerMenuContainer{
  position: relative;
  transform: translateX(0);
  transition: all 0.3s ease-in-out;
}

#headerNavigation nav.header-menu ul.header-menu-items .menu-item-container,
#headerNavigation nav.header-menu ul.header-menu-items {
  list-style-type: none;
  padding: 0;
  position: relative;
}
#headerNavigation nav.header-menu ul.header-menu-items .menu-item {
  padding: 10px 20px;
  cursor: pointer;
}
#headerNavigation nav.header-menu ul.header-menu-items .menu-item > span,
#headerNavigation nav.header-menu ul.header-menu-items .menu-item > a {
  font-weight: 500;
  font-size: 16px;
  color: var(--gray-500);
  transition: all 0.3s ease-in-out;
}
#headerNavigation nav.header-menu ul.header-menu-items .menu-item > a.active {
  color: var(--primary);
}
#headerNavigation nav.header-menu ul.header-menu-items .menu-item:hover > span,
#headerNavigation nav.header-menu ul.header-menu-items .menu-item:hover > a {
  font-weight: 500;
  color: var(--primary);
  transition: all 0.3s ease-in-out;
}
#headerNavigation nav.header-menu ul.header-menu-items .menu-item.has-children > span {
  position: relative;
}
#headerNavigation
  nav.header-menu
  ul.header-menu-items
  .menu-item.has-children > span::after {
  content: "";
  position: absolute;
  display: inline-block;
  height: 6px;
  width: 6px;
  border-top: 1px solid var(--gray-500);
  border-right: 1px solid var(--gray-500);
  transform: translateY(-50%) rotate(135deg);
  top: 50%;
  right: -15px;
  transition: all 0.3s ease-in-out;
}
#headerNavigation
  nav.header-menu
  ul.header-menu-items
  .menu-item.has-children:hover > span::after {
  border-top: 1px solid var(--primary);
  border-right: 1px solid var(--primary);
  transform: translateY(-50%) rotate(-45deg);
  top: 60%;
  right: -15px;
  transition: all 0.3s ease-in-out;
}
#headerNavigation
  nav.header-menu
  ul.header-menu-items
  .menu-item.has-children
  .menu-item-container {
    
  max-height: 550px;
  overflow: auto;
  transform: scale(0) translateX(-50%);
  padding: 15px;
  position: absolute;
  top: 45px;
  left: 0%;
  transition: all 0.3s ease-in-out;
  width: 80vw;
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  box-shadow: 0px 0px 15px 0px #eee;
  z-index: 2;
  border-radius: 10px;
  justify-content: space-between;
}
#headerNavigation
  nav.header-menu
  ul.header-menu-items
  .menu-item.has-children:hover
  .menu-item-container {
  left: 50%;
  transform: scale(1) translateX(-50%);
  transition: all 0.3s ease-in-out;
}
#headerNavigation
  nav.header-menu
  ul.header-menu-items
  .menu-item.has-children
  .menu-item-container
  li {
  padding: 10px 0px;
  max-width: 20%;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}
#headerNavigation
  nav.header-menu
  ul.header-menu-items
  .menu-item.has-children
  .menu-item-container
  li
  a {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: var(--gray-500);
  transition: all 0.3s ease-in-out;
}
#headerNavigation
  nav.header-menu
  ul.header-menu-items
  .menu-item.has-children
  .menu-item-container
  li
  a .ManufacturerPicture{
    display: block;
    height: 200px;
    width: 200px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 auto 10px;
}
#headerNavigation
  nav.header-menu
  ul.header-menu-items
  .menu-item.has-children
  .menu-item-container
  li
  a:hover {
  color: var(--primary);
  transition: all 0.3s ease-in-out;
}

#headerNavigation .header-categories .btn.btn-primary.menu-btn {
  display: none;
}

@media screen and (max-width: 1340px) {
  #headerNavigation .all-category-wrapper .all-categories-container .all-category-menu {
    max-width: 75%;
  }
  #headerNavigation .all-category-wrapper .all-categories-container .all-category-images {
    max-width: 25%;
  }
}
@media screen and (max-width: 1290px) {
  #headerNavigation nav.header-menu ul.header-menu-items .menu-item.has-children .menu-item-container li {
    max-width: 25%;
  }
  #headerNavigation nav.header-menu ul.header-menu-items .menu-item.has-children .menu-item-container li a .ManufacturerPicture {
    height: 160px;
    width: 160px;
  }
}
@media screen and (max-width: 1240px) {
  #headerNavigation .menu-wrapper {
    max-width: calc( 100% - 380px );
  }
  #headerNavigation nav.header-menu ul.header-menu-items .menu-item {
    padding: 10px 15px;
  }
  #headerNavigation nav.header-menu ul.header-menu-items .menu-item > span,
  #headerNavigation nav.header-menu ul.header-menu-items .menu-item > a {
    font-size: 14px;
  }
  #headerNavigation
    nav.header-menu
    ul.header-menu-items
    .menu-item.has-children::after {
    height: 4px;
    width: 4px;
    top: 20px;
    right: 0px;
  }
  header .wrapper .ais-SearchBox .ais-SearchBox-form .ais-SearchBox-input,
  header .wrapper .header-search .search-wrapper .search-field {
    font-size: 14px;
    height: 40px;
    padding: 0px 15px;
  }
  header .wrapper .ais-SearchBox .ais-SearchBox-form .ais-SearchBox-submit,
  header .wrapper .header-search .search-wrapper .search-button {
    font-size: 16px;
    padding: 0px 15px;
    height: 40px;
  }
  header .wrapper .ais-Hits,
  header .wrapper .ais-SearchBox .ais-SearchBox-form,
  header .wrapper .header-search .search-wrapper {
    max-width: 500px;
    margin: 0 auto;
  }
  #topbar {
    padding: 12px 0px 16px;
    margin-bottom: 10px;
  }
  #topbar .wrapper .top-bar-headline a,
  #topbar .wrapper .top-bar-headline,
  #topbar .wrapper .top-bar-location {
    font-size: 12px;
  }
  #topbar .wrapper .top-bar-location svg {
    max-width: 14px;
  }
  #topbar::before {
    height: 14px;
    background-size: 14px;
  }
}
@media screen and (max-width: 1200px) {
  #headerNavigation .all-category-wrapper .all-categories-container .all-category-menu {
    max-width: 100%;
  }
  #headerNavigation .all-category-wrapper .all-categories-container .all-category-images {
    display: none;
  }
  #headerNavigation .all-category-wrapper .all-categories-container .all-category-menu ul li {
    padding-right: 10px;
  }
}

@media screen and (max-width: 1024px) {
  header .wrapper {
    gap: 0px 30px;
  }
  header .wrapper .header-logo img {
    max-width: 130px;
  }
  header .wrapper .header-right .header-info div a {
    font-size: 16px;
  }
}
@media screen and (max-width: 950px) {
  #headerNavigation .menu-wrapper {
    max-width: 100%;
  }
  #headerNavigation nav.header-menu ul.header-menu-items .menu-item.has-children .menu-item-container li a .ManufacturerPicture {
    height: 120px;
    width: 120px;
  }
  #headerNavigation {
    position: relative;
  }
  #headerNavigation .wrapper {
    justify-content: center !important;
  }
  #headerNavigation .header-categories {
    position: absolute;
    top: -80px;
    left: 20px;
  }
  .RTL #headerNavigation .header-categories {
    left: auto;
    right: 20px;
  }
  #headerNavigation .header-offer {
    display: none;
  }
  /* #topbar .wrapper .top-bar-location {
        display: none;
    } */
  #topbar .wrapper .top-bar-headline {
    display: none;
  }
  header .wrapper .header-right .header-info {
    display: none !important;
  }
  header .wrapper .ais-SearchBox .ais-SearchBox-form,
  header .wrapper .header-search .search-wrapper {
    /* display: none !important; */
  }
  header .wrapper .ais-SearchBox::before{
    content: "Search Products";
    display: inline-block;
    margin: 0 0 20px 0;
    font-size: 18px;
  }
  .RTL header .wrapper .ais-SearchBox::before{
    content: "البحث عن المنتجات";
  }
  header .wrapper .header-search .ais-SearchBox{
    position: fixed;
    top: -200px;
    left: 0;
    padding: 50px 30px 50px;
    width: 100%;
    background-color: #fff;
    z-index: 99;
    transition: top 0.3s ease-in-out;
    opacity: 0.98;
    border-bottom: 1px solid var(--gray-100);
  }
  header .wrapper .header-search.toggle-search .ais-SearchBox{
    top: 0px;
    transition: top 0.3s ease-in-out;
  }
  header .wrapper .header-search .ais-SearchBox .ais-SearchBox-form{
    display: block !important;
    max-width: 100%;
    width: 100%;
  }
  header .wrapper .ais-SearchBox,
  header .wrapper .header-search {
    flex: inherit;
  }
  header .wrapper .search-toggle {
    display: inline-flex !important;
    background: none;
    outline: none;
    border: 0;
    font-size: 20px;
    padding: 0 15px 0 0;
    height: 35px;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    border-right: 1px solid var(--gray-300);
    color: var(--secondary);
    cursor: pointer;
  }
  .RTL header .wrapper .search-toggle {
    padding: 0 0 0 15px;
    margin-right: 0px;
    margin-left: 20px;
    border-right: 0px;
    border-left: 1px solid var(--gray-300);
  }
  header .wrapper .search-close {
    display: inline-flex !important;
    background: none;
    outline: none;
    border: 0;
    font-size: 24px;
    height: 35px;
    align-items: center;
    justify-content: center;
    color: var(--gray-500);
    cursor: pointer;
    position: fixed;
    z-index: 100;
    top: -180px;
    right: 30px;
    transition: top 0.3s ease-in-out;
  }
  .RTL header .wrapper .search-close {
    right: auto;
    left: 30px;
  }
  header .wrapper .toggle-search .search-close {
    top: 30px;
    transition: top 0.3s ease-in-out;
  }
  header .wrapper .ais-Hits{
    max-width: calc( 100% - 60px );
    width: 100%;
    position: fixed;
    top: -100%;
    left: 30px;
    transition: top 0.3s ease-in-out;
  }
  header .wrapper .toggle-search .ais-Hits{
    top: 130px;
    transition: top 0.3s ease-in-out;
  }
  header {
    position: relative;
    margin-bottom: 20px;
  }
  header .wrapper {
    gap: 0px;
    justify-content: end !important;
  }
  header .wrapper .header-logo {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
  }
  #headerNavigation .all-category-wrapper {
    top: -80px;
  }
}
@media screen and (max-width: 768px) {
  #headerNavigation .all-category-wrapper .all-categories-container .all-category-menu .all-category-filters{
    display: none!important;
  }
  #headerNavigation .all-category-wrapper .all-categories-container .all-category-menu ul li {
    max-width: 50%;
  }
  #headerNavigation nav.header-menu ul.header-menu-items .menu-item.has-children .menu-item-container{
    width: 90vw;
  }
  #headerNavigation nav.header-menu ul.header-menu-items .menu-item.has-children .menu-item-container li a .ManufacturerPicture {
    height: 100px;
    width: 100px;
  }
}
@media screen and (max-width: 600px) {
  #headerNavigation .header-categories .btn.btn-primary.category-btn {
    display: none;
  }
  #headerNavigation .header-categories .btn.btn-primary.menu-btn {
    display: inline-flex;
    border: 0px;
    background: none;
    color: var(--secondary);
    font-size: 0px;
    padding: 0;
    margin: 0;
  }
  #headerNavigation .header-categories .btn.btn-primary i {
    font-size: 20px;
    padding: 0 !important;
    margin: 0 !important;
  }
  
  nav.header-menu,
  #headerMenuWrapperParent{
    display: none;
  }
  header .wrapper .search-toggle {
    position: absolute;
    top: 30px;
    left: 60px;
    border: 0px;
    margin: 0px;
    padding: 0px;
  }
  .RTL header .wrapper .search-toggle {
    left: auto;
    right: 60px;
    border: 0px;
    margin: 0px;
    padding: 0px;
  }
  header .wrapper .ais-SearchBox .ais-SearchBox-form,
  header .wrapper .header-search .search-wrapper {
    display: block !important;
    width: 100%;
    max-width: 100%;
  }
  header .wrapper .header-search .ais-SearchBox {
    padding: 50px 20px 50px;
  }
  header .wrapper .ais-Hits {
    max-width: calc( 100% - 40px );
    left: 20px;
  }
  /* header .wrapper .ais-SearchBox, */
  /* header .wrapper .header-search {
    position: absolute;
    bottom: -70px;
    left: 20px;
    right: 20px;
  } */
  header .wrapper .header-right {
    margin-top: 20px;
  }
  header .wrapper .header-right .header-cart,
  header .wrapper .header-right .header-info {
    margin-right: 15px;
  }
  #headerNavigation .header-categories {
    top: -75px;
  }
  /* #headerNavigation .all-category-wrapper .all-categories-container .all-category-menu h2{
    display: none!important;
  } */
  #headerNavigation .all-category-wrapper {
    top: -50px;
  }
  #headerNavigation .all-category-wrapper .all-categories-container .all-category-menu ul li {
    max-width: 100%;
  }
}

@media screen and (max-width: 500px){
  header .wrapper .ais-Hits .ais-Hits-list .ais-Hits-item .search-product-result .search-product-price span {
    font-size: 14px;
  }
  header .wrapper .header-logo img {
    max-width: 100px;
    margin-top: 10px;
  }
}