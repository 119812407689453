#categories .wrapper .category-grid {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 15% 15% 15% 15% 15% 15%;
}

#categories .wrapper .category-grid>a.category-card.enabled:nth-of-type(5n+1) {
    background-image: url('../images/categories/Group16396.svg');
    cursor: pointer;
}

#categories .wrapper .category-grid>a.category-card.enabled:nth-of-type(5n+2) {
    background-image: url('../images/categories/Group16400.svg');
    cursor: pointer;
}

#categories .wrapper .category-grid>a.category-card.enabled:nth-of-type(5n+3) {
    background-image: url('../images/categories/Group16407.svg');
    cursor: pointer;
}

#categories .wrapper .category-grid>a.category-card.enabled:nth-of-type(5n+4) {
    background-image: url('../images/categories/Group16398.svg');
    cursor: pointer;
}

#categories .wrapper .category-grid>a.category-card.enabled:nth-of-type(5n+5) {
    background-image: url('../images/categories/Group16428.svg');
    cursor: pointer;
}

#categories .wrapper .category-grid>.category-card {
    display: block;
    height: 130px;
    width: 100%;
    margin: 0 0 50px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-position: bottom;
    text-decoration: none;
    border-radius: 15px;
}


#categories .wrapper .category-grid>.category-card .category-card-container {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: -webkit-grab;
    cursor: grab;
}

#categories .wrapper .category-grid>.category-card .category-card-container .category-card-images {
    position: absolute;
    z-index: 1;
    top: -35px;
    left: 50%;
    transform: translateX(-50%);
}

#categories .wrapper .category-grid>.category-card .category-card-container .category-card-heading {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    bottom: 20px;
    width: 100%;
    max-width: calc(100% - 30px);
    white-space: pre-wrap;
}

#categories .wrapper .category-grid>.category-card .category-card-container .category-card-heading h3 {
    margin: 0;
    font-size: 14px;
}


#categories .wrapper .category-grid>.category-card .category-card-container .category-card-images img {
    width: 100%;
}

#categories .wrapper .category-grid>.category-card .category-card-container .category-card-images {
    max-width: 90px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#brands .wrapper .brand-grid{
    display: grid;
    justify-content: space-between;
    grid-template-columns: 14% 14% 14% 14% 14% 14% 14%;
}
#brands .wrapper .brand-grid .brand-card{
    width: 100%;
    text-decoration: none;
    display: block;
    padding: 20px;
    margin-bottom: 20px;
}
#brands .wrapper .brand-grid .brand-card > img{
    width: 100%;
    height: 100px;
    object-fit: contain;
}
#brands .wrapper .brand-grid .brand-card > h3{
    text-align: center;
}

@media (max-width: 1400px) {
    #categories .wrapper .category-grid {
        grid-template-columns: 18% 18% 18% 18% 18%;
    }
    #brands .wrapper .brand-grid{
        grid-template-columns: 16% 16% 16% 16% 16% 16%;
    }
}

@media (max-width: 1140px) {
    #categories .wrapper .category-grid {
        grid-template-columns: 22% 22% 22% 22%;
    }
    #brands .wrapper .brand-grid{
        grid-template-columns: 18% 18% 18% 18% 18%;
    }
}

@media (max-width: 980px) {
    #categories .wrapper .category-grid>.category-card {
        height: 145px;
    }
    #brands .wrapper .brand-grid{
        grid-template-columns: 22% 22% 22% 22%;
    }
}

@media (max-width: 768px) {
    #categories .wrapper .category-grid {
        grid-template-columns: 24% 24% 24% 24%;
    }
    #brands .wrapper .brand-grid{
        grid-template-columns: 24% 24% 24% 24%;
    }
    #categories .wrapper .category-grid>.category-card {
        height: 125px;
    }
    #categories .wrapper .category-grid>.category-card .category-card-container .category-card-heading {
        bottom: 14px;
    }
    #brands .wrapper .brand-grid .brand-card > h3 {
        font-size: 12px;
    }
    #categories .wrapper .category-grid>.category-card .category-card-container .category-card-heading h3 {
        font-size: 12px;
    }
}
@media (max-width: 640px) {
    #categories .wrapper .category-grid {
        grid-template-columns: 32% 32% 32%;
    }
    #brands .wrapper .brand-grid{
        grid-template-columns: 32% 32% 32%;
    }
    #categories .wrapper .category-grid>.category-card {
        height: 125px;
    }
}
@media (max-width: 500px) {
    #categories .wrapper .category-grid {
        grid-template-columns: 48% 48%;
    }
    #brands .wrapper .brand-grid{
        grid-template-columns: 48% 48%;
    }
    #categories .wrapper .category-grid>.category-card .category-card-container .category-card-heading h3 {
        font-size: 14px;
    }
    #brands .wrapper .brand-grid .brand-card > h3 {
        font-size: 14px;
    }
}