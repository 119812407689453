/* Home banner section */

#home_banner_section .home-banner .top-banner {
  display: flex;
  width: 100%;
  max-width: 800px;
  margin: 40px auto 20px;
  gap: 20px;
}
#home_banner_section .home-banner .top-banner .banner-image{
  display: inline-block;
  width: 50%;
}
#home_banner_section .home-banner .top-banner .banner-image img{
  width: 100%;
  border-radius: 15px;
  display: flex;
}
#home_banner_section .home-banner .top-banner .top-loading-banner{
  width: 50%;
}
#home_banner_section .home-banner .top-banner .top-loading-banner span{
  width: 100%;
  border-radius: 15px;
  aspect-ratio: 5/3;
}
#home_banner_section .home-banner .bottom-banner .bottom-loading-banner{
  width: 100%;
}
#home_banner_section .home-banner .bottom-banner .bottom-loading-banner span{
  width: 100%;
  border-radius: 15px;
  aspect-ratio: 8/3;
}
#home_banner_section .home-banner .bottom-banner .banner-image img{
  width: 100%;
  /* max-width: 1000px; */
  margin: 0 auto;
  display: flex;
  border-radius: 15px;
}
#home_banner_section .home-banner .bottom-banner .swiper-pagination .swiper-pagination-bullet{
  background-color: var(--gray-400);
}
#home_banner_section .home-banner .bottom-banner .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active{
  background-color: var(--primary);
}
#featured_product_section .featured-product-card .product-card {
  max-width: 275px;
}
#featured_product_section .featured-product-card.fill-two::after {
  content: "";
  max-width: 50%;
  width: 100%;
}
#featured_product_section .featured-product-card.fill-one::after {
  content: "";
  max-width: 275px;
  width: 100%;
}

@media screen and (max-width: 860px) {
  #home_banner_section .home-banner .top-banner {
    margin: 15px auto 15px;
    gap: 15px;
  }
}
@media screen and (max-width: 500px) {
  #home_banner_section .home-banner .top-banner {
    margin: 10px auto 10px;
    gap: 10px;
  }
}
/* Shop by category section */

#category_section .react-multi-carousel-track li:nth-of-type(5n+1)>div.category-card.enabled {
  background-image: url('../images/categories/Group16396.svg');
  cursor: pointer;
}

#category_section .react-multi-carousel-track li:nth-of-type(5n+2)>div.category-card.enabled {
  background-image: url('../images/categories/Group16400.svg');
  cursor: pointer;
}

#category_section .react-multi-carousel-track li:nth-of-type(5n+3)>div.category-card.enabled {
  background-image: url('../images/categories/Group16407.svg');
  cursor: pointer;
}

#category_section .react-multi-carousel-track li:nth-of-type(5n+4)>div.category-card.enabled {
  background-image: url('../images/categories/Group16398.svg');
  cursor: pointer;
}

#category_section .react-multi-carousel-track li:nth-of-type(5n+5)>div.category-card.enabled {
  background-image: url('../images/categories/Group16428.svg');
  cursor: pointer;
}

#category_section .react-multi-carousel-track li .category-card {
  display: block;
  height: 165px;
  width: 165px;
  margin: 0px 35px 35px 0px;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}


#category_section .category-card .category-card-container {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: -webkit-grab;
  cursor: grab;
}

#category_section .category-card .category-card-container .category-card-images {
  position: absolute;
  z-index: 1;
  bottom: -25px;
  right: -25px;
}

#category_section .category-card .category-card-container .category-card-heading {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  top: 30px;
  width: 100%;
  max-width: calc(100% - 20px);
  white-space: pre-wrap;
}

#category_section .category-card .category-card-container .category-card-heading h3 {
  margin: 0;
}


#category_section .category-card .category-card-container .category-card-images img {
  width: 100%;
}

#category_section .category-card .category-card-container .category-card-images {
  max-width: 75px;
}

#category_section .react-multi-carousel-list .react-multiple-carousel__arrow {
  min-width: 30px;
  min-height: 30px;
}

#category_section .react-multi-carousel-list .react-multiple-carousel__arrow::before {
  font-size: 10px;
  font-weight: 900;
}

#category_section .react-multi-carousel-list .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
  right: 0px;
}

#category_section .react-multi-carousel-list .react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
  left: 0px;
}

#category_section .carousel-navigation .carousel-navigation-button {
  top: 45%;
  left: -15px;
  transform: translateY(-50%);
  background: #404040;
  border: 0px;
  outline: none;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  width: 26px;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
}

#category_section .carousel-navigation .carousel-navigation-right.carousel-navigation-button {
  left: auto;
  right: -15px;

}

#category_section .carousel-navigation .carousel-navigation-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}




/* @media screen and (max-width: 1410px) {
  #category_section .category-card .category-card-container .category-card-images img {
    width: 250px;
    height: 270px;
    border-radius: 10px;
  }
} */

/* Featured product section */

#featured_product_section .featured-product-card {
  max-width: 73%;
  width: 100%;
  gap: 0.5rem;
  justify-content: space-between;
  /* align-items: flex-start; */
}

#featured_product_section .featured-right-banner {
  position: relative;
  max-width: 25%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #e6e6e6;
  border-radius: 10px;
  overflow: hidden;
}

#featured_product_section .featured-right-banner .featured-right-banner-content {
  position: absolute;
  top: 10%;
  left: 0;
  padding: 20px;
}

.RTL #featured_product_section .featured-right-banner .featured-right-banner-content {
  left: auto;
  right: 10%;
}

#featured_product_section .featured-right-banner .featured-right-banner-content h2 {
  font-size: 2rem;
}

#featured_product_section .featured-right-banner img {
  height: 947px;
  background-color: rgba(0, 0, 0, 0.5);
  /* Black background with opacity */
  z-index: 2;
  object-fit: cover;
}

#featured_product_section .featured-right-banner::before {
  /* content: ""; */
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 100%;
  height: 947px;
  background: transparent linear-gradient(180deg, #ffffff00 0%, #000000 110%) 0% 0% no-repeat padding-box;
  opacity: 1;
  transform: matrix(-1, 0, 0, -1, 0, 0);
}

#featured_product_section .featured-right-banner.demo::before {
  display: none;
}

@media screen and (max-width: 1620px) {
  #featured_product_section .featured-product-card .product-card {
    max-width: 250px;
  }
  #featured_product_section .featured-product-card.fill-one::after {
    max-width: 250px;
  }

  #featured_product_section .featured-product-card .product-card .product-header .product-image img {
    width: 180px;
    height: 180px;
  }

  #featured_product_section .featured-right-banner img,
  #featured_product_section .featured-right-banner::before {
    height: 815px;
  }
}

@media screen and (max-width: 1460px) {
  #featured_product_section .featured-product-card .product-card .product-header .product-image img {
    width: 150px;
    height: 150px;
  }

  #featured_product_section .featured-product-card .product-card {
    max-width: 225px;
  }
  #featured_product_section .featured-product-card.fill-one::after {
    max-width: 225px;
  }

  #featured_product_section .featured-product-card .product-card .product-body .product-title {
    font-size: 15px;
  }

  #featured_product_section .featured-product-card .product-card .product-body .product-price {
    margin-top: 5px;
    margin-bottom: 10px;
  }

  #featured_product_section .featured-product-card .product-card .product-body .product-price .product-currency,
  #featured_product_section .featured-product-card .product-card .product-body .product-price .product-amount {
    margin-right: 5px;
    font-size: 15px;
  }

  #featured_product_section .featured-product-card .product-card .product-body .product-info .product-stock {
    font-size: 14px;
    margin-bottom: 3px;
  }

  #featured_product_section .featured-product-card .product-card .product-body .product-info>div i {
    font-size: 14px;
  }

  #featured_product_section .featured-product-card .product-card .product-action.btn {
    font-size: 14px;
    height: 40px;
  }

  #featured_product_section .featured-right-banner img,
  #featured_product_section .featured-right-banner::before {
    height: 710px;
  }

  #featured_product_section .featured-product-card .product-card .product-header .product-brand {
    width: 40px;
    height: 40px;
  }
}

@media screen and (max-width: 1320px) {

  #featured_product_section .featured-right-banner.demo,
  #featured_product_section .featured-right-banner {
    display: none;
  }

  #featured_product_section .featured-product-card {
    max-width: 100%;
  }

  #featured_product_section .featured-product-card .product-card {
    max-width: 220px;
  }
  #featured_product_section .featured-product-card.fill-one::after {
    max-width: 220px;
  }

  #featured_product_section .featured-product-card>div.product-card:nth-of-type(6),
  #featured_product_section .featured-product-card>div.product-card:nth-of-type(7),
  #featured_product_section .featured-product-card>div.product-card:nth-of-type(8) {
    display: none;
  }
}

@media screen and (max-width: 1200px) {

  #featured_product_section .featured-product-card>div.product-card:nth-of-type(6),
  #featured_product_section .featured-product-card>div.product-card:nth-of-type(7),
  #featured_product_section .featured-product-card>div.product-card:nth-of-type(8) {
    display: block;
  }

  #featured_product_section .featured-product-card .product-card {
    max-width: 240px;
  }
  #featured_product_section .featured-product-card.fill-one::after {
    max-width: 240px;
  }
}

@media screen and (max-width: 1100px) {
  #featured_product_section .featured-product-card .product-card {
    max-width: 225px;
  }
  #featured_product_section .featured-product-card.fill-one::after {
    max-width: 225px;
  }
}

@media screen and (max-width: 980px) {
  #featured_product_section .featured-product-card .product-card {
    max-width: 250px;
  }
  #featured_product_section .featured-product-card.fill-one::after {
    max-width: 250px;
  }

  #featured_product_section .featured-product-card .product-card .product-header .product-image img {
    width: 170px;
    height: 170px;
  }

  #featured_product_section .featured-product-card>div.product-card:nth-of-type(7),
  #featured_product_section .featured-product-card>div.product-card:nth-of-type(8) {
    display: none;
  }
}

@media screen and (max-width: 820px) {
  #featured_product_section .featured-product-card .product-card .product-body .product-title {
    font-size: 16px;
  }

  #featured_product_section .featured-product-card .product-card .product-body .product-info .product-stock {
    font-size: 16px;
    margin-bottom: 5px;
  }

  #featured_product_section .featured-product-card .product-card .product-body .product-info>div i {
    font-size: 16px;
  }

  #featured_product_section .featured-product-card .product-card .product-body .product-price .product-currency,
  #featured_product_section .featured-product-card .product-card .product-body .product-price .product-amount {
    font-size: 18px;
  }

  #featured_product_section .featured-product-card .product-card .product-header .product-image img {
    width: 220px;
    height: 220px;
  }

  #featured_product_section .featured-product-card .product-card {
    max-width: 48%;
  }
  #featured_product_section .featured-product-card.fill-one::after {
    max-width: 48%;
  }

  #featured_product_section .featured-product-card .product-card .product-header .product-brand {
    width: 60px;
  }

  #featured_product_section .featured-product-card>div.product-card:nth-of-type(7),
  #featured_product_section .featured-product-card>div.product-card:nth-of-type(8) {
    display: block;
  }
}

@media screen and (max-width: 550px) {

  #home_banner_section .home-banner .left-banner {
    min-height: 320px;
  }

  #featured_product_section .featured-product-card .product-card .product-body .product-title {
    min-height: 48px;
    /* max-height: 48px;
    overflow: hidden; */
  }

  #featured_product_section .featured-product-card .product-card .product-header .product-image img {
    width: 160px;
    height: 160px;
  }

  #featured_product_section .featured-product-card .product-card .product-body .product-price .product-currency,
  #featured_product_section .featured-product-card .product-card .product-body .product-price .product-amount,
  #featured_product_section .featured-product-card .product-card .product-body .product-info>div i,
  #featured_product_section .featured-product-card .product-card .product-body .product-title {
    font-size: 14px;
  }

  #featured_product_section .featured-product-card .product-card .product-body .product-info .product-stock {
    font-size: 12px;
    margin-bottom: 3px;
  }

  #featured_product_section .featured-product-card .product-card .product-header .product-brand {
    width: 40px;
  }
}

@media screen and (max-width: 420px) {
  #featured_product_section .featured-product-card .product-card {
    max-width: 90%;
    margin: 0 auto 25px;
  }
  #featured_product_section .featured-product-card.fill-one::after {
    max-width: 90%;
  }

  #featured_product_section .featured-product-card .product-card .product-header .product-image img {
    width: 200px;
    height: 200px;
  }

  #featured_product_section .featured-product-card .product-card .product-body .product-price {
    margin-top: 0;
  }

  #featured_product_section .featured-product-card .product-card .product-body .product-price .product-currency,
  #featured_product_section .featured-product-card .product-card .product-body .product-price .product-amount {
    font-size: 18px;
  }

  #featured_product_section .featured-product-card .product-card .product-body .product-info>div i,
  #featured_product_section .featured-product-card .product-card .product-body .product-title {
    font-size: 16px;
  }

  #featured_product_section .featured-product-card .product-card .product-body .product-info .product-stock {
    font-size: 16px;
    margin-bottom: 3px;
  }
}

/* Present someone gift section */

#cta_section .present-gift {
  background-color: #fff1f1;
  padding-right: 2rem;
  padding-left: 2rem;
  margin-bottom: 2rem;
  border-radius: 10px;
}

#cta_section .present-gift .present-gift-content h2 {
  color: var(--primary);
}

#cta_section .present-gift .present-gift-content p {
  color: var(--primary);
}

#cta_section .present-gift .present-gift-image img {
  width: 280px;
  height: 260px;
}

@media screen and (max-width: 760px) {
  #cta_section {
    margin-left: -25px;
    margin-right: -25px;
  }

  #cta_section .present-gift {
    flex-direction: column-reverse;
    text-align: center;
  }

  #cta_section .present-gift .present-gift-content {
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 600px) {
  #cta_section {
    margin-left: -15px;
    margin-right: -15px;
  }

}

/* Hot Selling Section */
#featured_product_section .small-product-card {
  gap: 5px;
  justify-content: space-between;
}

#featured_product_section .small-product-card .product-card {
  max-width: 245px;
}

#featured_product_section .small-product-card .product-card .product-header .product-image img {
  width: 200px;
}

/* offer banners section */
#promotions {
  padding-top: 50px;
}

#promotions .offer-banner a {
  display: inline-block;
  max-width: 49%;
  width: 100%;
  max-height: 400px;
  overflow: hidden;
}

#promotions .offer-banner a img {
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
}


@media screen and (max-width: 1550px) {
  #featured_product_section .small-product-card>div:nth-of-type(6) {
    display: none;
  }

  #featured_product_section .small-product-card .product-card {
    max-width: 225px;
  }

  #featured_product_section .small-product-card .product-card .product-header .product-image img {
    width: 150px;
    height: 150px;
  }

  #featured_product_section .small-product-card .product-card .product-body .product-title {
    font-size: 15px;
  }

  #featured_product_section .small-product-card .product-card .product-body .product-price {
    margin-top: 5px;
    margin-bottom: 10px;
  }

  #featured_product_section .small-product-card .product-card .product-body .product-info .product-stock {
    font-size: 14px;
    margin-bottom: 3px;
  }

  #featured_product_section .small-product-card .product-card .product-body .product-info>div i {
    font-size: 14px;
  }

  #featured_product_section .small-product-card .product-card .product-action.btn {
    font-size: 14px;
    height: 40px;
  }

  #featured_product_section .small-product-card .product-card .product-body .product-price .product-currency,
  #featured_product_section .small-product-card .product-card .product-body .product-price .product-amount {
    margin-right: 5px;
    font-size: 15px;
  }
}

@media screen and (max-width: 1460px) {
  #featured_product_section .small-product-card .product-card .product-header .product-brand {
    width: 40px;
    height: 40px;
  }
}

@media screen and (max-width: 1430px) {
  #featured_product_section .small-product-card>div:nth-of-type(5) {
    display: none;
  }
}

@media screen and (max-width: 980px) {
  #featured_product_section .small-product-card .product-card .product-header .product-image img {
    width: 170px;
    height: 170px;
  }

  #featured_product_section .small-product-card .product-card {
    max-width: 250px;
  }

  #featured_product_section .small-product-card>div:nth-of-type(6),
  #featured_product_section .small-product-card>div:nth-of-type(5) {
    display: block;
  }
}

@media screen and (max-width: 820px) {
  #featured_product_section .small-product-card .product-card .product-header .product-image img {
    width: 220px;
    height: 220px;
  }

  #featured_product_section .small-product-card .product-card {
    max-width: 48%;
  }

  #featured_product_section .small-product-card .product-card .product-body .product-title {
    font-size: 16px;
  }

  #featured_product_section .small-product-card .product-card .product-body .product-price .product-currency,
  #featured_product_section .small-product-card .product-card .product-body .product-price .product-amount {
    font-size: 18px;
  }

  /* #featured_product_section .small-product-card .product-card .product-body .product-info > div i {
    font-size: 16px;
  } */
  #featured_product_section .small-product-card .product-card .product-body .product-info .product-stock {
    font-size: 16px;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 550px) {
  #featured_product_section .small-product-card .product-card .product-header .product-image img {
    width: 160px;
    height: 160px;
  }

  #featured_product_section .small-product-card .product-card .product-header .product-brand {
    width: 40px;
  }

  #featured_product_section .small-product-card .product-card .product-body .product-price .product-currency,
  #featured_product_section .small-product-card .product-card .product-body .product-price .product-amount,
  #featured_product_section .small-product-card .product-card .product-body .product-info>div i,
  #featured_product_section .small-product-card .product-card .product-body .product-title {
    font-size: 14px;
  }

  #featured_product_section .small-product-card .product-card .product-body .product-title {
    min-height: 48px;
    max-height: 48px;
    overflow: hidden;
  }

  #featured_product_section .small-product-card .product-card .product-body .product-info .product-stock {
    font-size: 14px;
    margin-bottom: 3px;
  }
}

@media screen and (max-width: 420px) {
  #featured_product_section .small-product-card .product-card {
    max-width: 90%;
    margin: 0 auto 25px;
  }

  #featured_product_section .small-product-card .product-card .product-header .product-image img {
    width: 200px;
    height: 200px;
  }

  #featured_product_section .small-product-card .product-card .product-body .product-price {
    margin-top: 0;
  }

  #featured_product_section .small-product-card .product-card .product-body .product-price .product-currency,
  #featured_product_section .small-product-card .product-card .product-body .product-price .product-amount {
    font-size: 18px;
  }

  #featured_product_section .small-product-card .product-card .product-body .product-info>div i,
  #featured_product_section .small-product-card .product-card .product-body .product-title {
    font-size: 16px;
  }

  #featured_product_section .small-product-card .product-card .product-body .product-info .product-stock {
    font-size: 16px;
    margin-bottom: 3px;
  }
}

@media screen and (max-width: 400px) {
  #home_banner_section .home-banner .left-banner {
    min-height: 280px;
  }
}

@media screen and (max-width: 900px) {
  #promotions .offer-banner a {
    max-width: 100%;
  }

  #promotions .offer-banner a {
    display: none;
  }

  #promotions .offer-banner>a:last-of-type {
    display: block;
  }
}

@media screen and (max-width: 580px) {
  #featured_product_section .category-title {
    justify-content: center !important;
    text-align: center;
  }

  #featured_product_section .category-button {
    display: none;
  }
}

/* cta-3 section */

#cta_section .latest-updated {
  position: relative;
}

#cta_section .latest-updated .latest-upated-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 3rem 1rem;
}

#cta_section .latest-updated .latest-upated-content .email-field input {
  /* width: 538px; */
  height: 61px;
  max-width: 538px;
  border-radius: 10px;
  border: none;
  padding: 0 10rem 0 2rem;
  outline: none;
  width: 100%;
}

#cta_section .latest-updated .latest-upated-content .email-field {
  position: relative;
}

#cta_section .latest-updated .latest-upated-content .email-field button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 6px;
}

@media screen and (max-width: 980px) {
  #cta_section .latest-updated {
    margin-left: -25px;
    margin-right: -25px;
    border-radius: 0 !important;
  }
}

@media screen and (max-width: 700px) {
  #category_section .react-multi-carousel-track li .category-card {
    height: 125px;
    width: 125px;
  }
  #category_section .category-card .category-card-container .category-card-heading h3{
    font-size: 14px;
  }
  #category_section .category-card .category-card-container .category-card-heading {
    left: 15px;
    text-align: left;
    top: 15px;
    max-width: calc(100% - 30px);
    transform: none;
  }
}
@media screen and (max-width: 510px) {
  #category_section .category-card .category-card-container .category-card-images {
    position: absolute;
    z-index: 1;
    bottom: -25px;
    right: -5px;
  }
}
@media screen and (max-width: 425px) {
  #category_section .react-multi-carousel-track li .category-card {
    height: 115px;
    width: 115px;
  }
  #category_section .category-card .category-card-container .category-card-heading {
    left: 10px;
    top: 10px;
    max-width: calc(100% - 20px);
  }
  #category_section .category-card .category-card-container .category-card-heading h3{
    font-size: 13px;
    font-weight: 500!important;
  }
}
@media screen and (max-width: 400px) {
  #category_section .react-multi-carousel-track li .category-card {
    height: 105px;
    width: 105px;
  }
  #category_section .category-card .category-card-container .category-card-images {
    max-width: 55px;
  }
}