
.address-card-wrapper {
  position: relative;
  max-width: 48%;
  width: 100%;
  padding: 35px 20px 10px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #d6d5d5;
  margin: 0 0 20px;
}


.address-card .address-card-tag span {
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 5px 25px 5px 15px;
  color: var(--white);
  background-color: var(--link);
  z-index: 1;
  font-size: 14px;
  border-bottom-right-radius: 25px;
}
.RTL .address-card .address-card-tag span {
  left: auto;
  right: 0px;
  padding: 5px 15px 5px 25px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 25px;
}

.address-card-wrapper .save-cancel .save-btn{
  position: absolute;
  bottom: 10px;
  right: 200px;
}

.address-card-wrapper .save-cancel{
  position: absolute;
  bottom: 10px;
  right: 20px;
}
.address-card-wrapper .delete{
  position: absolute;
  bottom: 10px;
  left: 20px;
}
.RTL .address-card-wrapper .delete{
  left: auto;
  right: 20px;
}

.address-card-wrapper .address-card-edit-btn{
  position: absolute;
  top: 10px;
  right: 20px;
}
.RTL .address-card-wrapper .address-card-edit-btn{
  left: 20px;
  right: auto;
}
.address-card-wrapper .address-card .address-card-title{
    border-bottom: 2px solid var(--secondary);
}
.address-card .add-default-label{
    position: absolute;
    bottom: 13px;
    right: 20px;
}
.RTL .address-card .add-default-label{
    right: auto;
    left: 20px;
}

.address-card .address-form-content .map-container{
  height: 300px;
  border-radius: 10px;
}
.address-card .address-form-content.title-selector label{
  background-color: #f8f8f8;
  border-radius: 20px;
  height: 40px;
  width: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: var(--gray-400);
  transition: background-color 0.3s ease-in-out;
  transition-property: all;
}
.address-card .address-form-content.title-selector input:checked + label,
.address-card .address-form-content.title-selector label:hover{
  background-color: #ffcce5;
  transition: background-color 0.3s ease-in-out;
  color: var(--primary);
  transition-property: all;
}
.address-card .address-form-content input {
  width: 100%;
  margin: 0px;
  padding: 0px 12px;
  height: 46px;
  font-size: 14px;
  color: var(--gray-500);
  border: 1px solid #d6d5d5;
  outline: none;
  border-radius: 5px;
  background: #fff;
  transition: all 0.3s ease-in-out;
}


.address-card .address-form-content input:focus{
    border: 1px solid var(--primary);
    transition: all 0.3s ease-in-out;
}
.address-card .address-form-content {
  max-width: 48%;
  width: 100%;
}

.address-card .address-form-content select {
  width: 100%;
  margin: 0px;
  padding: 0px 15px;
  height: 46px;
  font-size: 16px;
  color: var(--gray-500);
  border: 1px solid #d6d5d5;
  outline: none;
  border-radius: 5px;
  background: #fff;
  transition: all 0.3s ease-in-out;
}

.address-card .address-form-content select:focus{
    border: 1px solid var(--primary);
    transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 1280px) {
  .address-card-wrapper{
    max-width: 100%;
    width: 100%;
  }
}