#cart{
    padding: 80px 0px;
}
#cart .wrapper{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
#cart .wrapper .section-header{
    margin-bottom: 60px;
}
#cart .wrapper .cart-products{
    max-width: calc( 100% - 480px );
    width: 100%;
}
#cart .wrapper .cart-products .cart-group{
    width: 100%;
}
#cart .wrapper .cart-products .cart-group .group-header{
    margin-bottom: 40px;
}
#cart .wrapper .cart-products .cart-group .group-header .group-heading{
    font-size: 18px;
    font-weight: 400;
    color: var(--gray-500);
}

#cart .wrapper .cart-summary{
    max-width: 450px;
    width: 100%;
}
#cart .wrapper .cart-summary .cart-summary-wrapper{
    width: 100%;
    padding: 30px;
    border: 1px solid #D6D5D5;
    border-radius: 5px;
}
#cart .wrapper .cart-summary .cart-summary-wrapper .summary-title{
    font-size: 18px;
    font-weight: 500;
    color: var(--gray-500);
    margin: 0 0 20px;
    position: relative;
    display: inline-block;
}
#cart .wrapper .cart-summary .cart-summary-wrapper .summary-title::after{
    content: "";
    position: absolute;
    width: 75%;
    height: 1px;
    bottom: -5px;
    left: 0;
    background-color: var(--secondary);
}
.RTL #cart .wrapper .cart-summary .cart-summary-wrapper .summary-title::after{
    left: auto;
    right: 0;
}
#cart .wrapper .cart-summary .cart-summary-wrapper .cart-amounts > div{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0px;
}
#cart .wrapper .cart-summary .cart-summary-wrapper .cart-amounts > div > span:first-of-type{
    font-size: 16px;
    font-weight: 400;
    color: var(--gray-500);
}
#cart .wrapper .cart-summary .cart-summary-wrapper .cart-amounts > div > span:last-of-type{
    font-size: 16px;
    font-weight: 600;
    color: var(--gray-500);
}
#cart .wrapper .cart-summary .cart-summary-wrapper .cart-total{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0px;
    margin: 15px 0px;
    border-top: 1px solid var(--gray-100);
    border-bottom: 1px solid var(--gray-100);
}
#cart .wrapper .cart-summary .cart-summary-wrapper .cart-total > span:first-of-type{
    font-size: 20px;
    font-weight: 500;
    color: var(--gray-500);
}
#cart .wrapper .cart-summary .cart-summary-wrapper .cart-total > span:last-of-type{
    font-size: 20px;
    font-weight: 600;
    color: var(--secondary);
}
#cart .wrapper .cart-summary .cart-summary-wrapper .cart-voucher{
    width: 100%;
    padding: 15px 0px;
    margin-bottom: 30px;
}
#cart .wrapper .cart-summary .cart-summary-wrapper .cart-voucher .applied-coupons{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
}
#cart .wrapper .cart-summary .cart-summary-wrapper .cart-voucher .applied-coupons > span{
    background-color: #FFD0E7;
    padding: 8px 15px;
    font-size: 14px;
    color: var(--primary);
    font-weight: 500;
    border: 1px dashed var(--primary);
    display: inline-flex;
    align-items: center;
    border-radius: 5px;
    margin: 0 10px 10px 0px;
}
.RTL #cart .wrapper .cart-summary .cart-summary-wrapper .cart-voucher .applied-coupons > span{
    margin: 0 0 10px 10px;
}
#cart .wrapper .cart-summary .cart-summary-wrapper .cart-voucher .applied-coupons > span i{
    font-size: 18px;
    cursor: pointer;
}
#cart .wrapper .cart-summary .cart-summary-wrapper .cart-voucher label{
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
    display: inline-block;
    color: var(--gray-500);
}
#cart .wrapper .cart-summary .cart-summary-wrapper .cart-voucher > form{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
#cart .wrapper .cart-summary .cart-summary-wrapper .cart-voucher > form input{
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    color: var(--gray-500);
    margin-right: 10px;
    height: 45px;
    padding: 5px 15px;
    border-radius: 5px;
    outline: none;
    border: 1px solid #D6D5D5;
    transition: all 0.3s ease-in-out;
}
.RTL #cart .wrapper .cart-summary .cart-summary-wrapper .cart-voucher > form input{
    margin-right: 0px;
    margin-left: 10px;
}
#cart .wrapper .cart-summary .cart-summary-wrapper .cart-voucher > form input:focus{
    border: 1px solid var(--primary);
    transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 1440px) {
    #cart .wrapper .cart-summary {
        max-width: 400px;
    }
    #cart .wrapper .cart-products {
        max-width: calc( 100% - 425px );
    }
}

@media screen and (max-width: 1260px) {
    #cart .wrapper .cart-summary .cart-summary-wrapper .cart-amounts > div > span:first-of-type,
    #cart .wrapper .cart-summary .cart-summary-wrapper .cart-amounts > div > span:last-of-type {
      font-size: 14px;
      font-weight: 500;
    }
    #cart .wrapper .cart-summary .cart-summary-wrapper .cart-total > span:first-of-type,
    #cart .wrapper .cart-summary .cart-summary-wrapper .cart-total > span:last-of-type {
      font-size: 18px;
    }
    #cart .wrapper .cart-summary {
      max-width: 360px;
    }
    #cart .wrapper .cart-products {
        max-width: calc( 100% - 385px );
    }
  }
  @media screen and (max-width: 1140px) {
    #cart .wrapper .cart-products .cart-group .group-products{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
  }