.no-breadcrumb .breadcrumbs{
    display: none;
}
.breadcrumbs{
    background-color: #f8f8f8;
    padding: 10px 0px;
    margin-top: 50px;
}
.breadcrumbs .wrapper a{
    text-decoration: none;
    color: var(--gray-500);
    font-size: 14px;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
}
.breadcrumbs .wrapper a:hover{
    color: var(--primary);
    transition: all 0.3s ease-in-out;
}
.breadcrumbs .wrapper > *::after{
    content: "";
    display: inline-block;
    height: 4px;
    width: 4px;
    border-top: 1px solid var(--gray-500);
    border-right: 1px solid var(--gray-500);
    transform: rotate(45deg);
    margin: 0px 10px 2px;
    transition: all 0.3s ease-in-out;
}
.breadcrumbs .wrapper a:hover::after{
    border-top: 1px solid var(--primary);
    border-right: 1px solid var(--primary);
    transition: all 0.3s ease-in-out;
}
.breadcrumbs .wrapper span{
    color: var(--gray-400);
    font-size: 14px;
    font-weight: 400;
}
.breadcrumbs .wrapper > span:last-of-type::after{
    display: none;
}

@media screen and (max-width: 950px) {
    .breadcrumbs {
        margin-top: 20px;
    }
}
@media screen and (max-width: 600px) {
    .breadcrumbs {
        margin-top: 20px;
    }
}