.profile-section {
  position: relative;
  max-width: 100%;
  width: 100%;
  padding: 30px 20px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #d6d5d5;
}

.profile-section .edit-btn button {
  position: absolute;
  right: 20px;
  top: 20px;
}
.RTL .profile-section .edit-btn button {
  right: auto;
  left: 20px;
}

.profile-section .save-cancel {
  padding-bottom: 30px;
}

.profile-section .form-content {
  max-width: 32%;
  width: 100%;
}

.icon-position {
  position: relative;
  top: 3px;
}

.profile-section .form-content input:not([type="radio"]) {
  width: 100%;
  margin: 0px;
  padding: 0px 12px;
  height: 46px;
  font-size: 14px;
  color: var(--gray-500);
  border: 1px solid #d6d5d5;
  outline: none;
  border-radius: 5px;
  background: #fff;
  transition: all 0.3s ease-in-out;
}
.profile-section .form-content input:not([type="radio"]):focus {
  border: 1px solid var(--primary);
  transition: all 0.3s ease-in-out;
}
.profile-section .form-content input:read-only {
  cursor: not-allowed;
  background: url(../images/locked.png);
  background-position: calc( 100% - 10px) center;
  background-repeat: no-repeat;
  background-size: 18px;
}
.RTL .profile-section .form-content input:read-only {
  background-position: 10px center;
  text-align: right;
}

.profile-section .form-content select {
  width: 100%;
  margin: 0px;
  padding: 0px 15px;
  height: 46px;
  font-size: 14px;
  color: var(--gray-500);
  border: 1px solid #d6d5d5;
  outline: none;
  border-radius: 5px;
  background: #fff;
  transition: all 0.3s ease-in-out;
}
.profile-section .form-content select:focus {
  border: 1px solid var(--primary);
  transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 1420px) {
  .profile-section .form-content {
    max-width: 48%;
    width: 100%;
  }
}

@media screen and (max-width: 1020px) {
  .profile-section .form-content {
    max-width: 100%;
    width: 100%;
  }
}
