.order-card {
  background: #fff;
  border-radius: 10px 10px 0px 0px;
  width: 100%;
  padding: 20px 30px;
  border-bottom: 1px dotted var(--gray-300);
}

.order-details-container>div.order-card:last-of-type {
  border-bottom: 0px
}

.order-card .order-card-discount {
  background: var(--link);
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
  padding: 5px 10px;
  font-weight: 500;
}

.order-detail-card {
  background: #fff;
  border-radius: 10px;
  border: 1px solid #d6d5d5;
  width: 100%;
  margin-bottom: 30px;
  padding: 10px 20px;
}

.order-detail-card .order-detail-header>div {
  max-width: 25%;
  width: 100%;
}

.order-detail-card .order-detail-products .order-detail-footer>div {
  max-width: 25%;
  width: 100%;
}

.order-card-box {
  background: #fff;
  border-radius: 10px;
  border: 1px solid #d6d5d5;
  width: 100%;
  margin-bottom: 30px;
  padding: 10px 20px;
}

.container {
  background: #fff;
  border-radius: 10px;
  border: 1px solid #d6d5d5;
  width: 100%;
  margin-bottom: 30px;
}

.order-dropdown {
  display: none;
}

.order-dropdown select {
  background: none;
  border: 1px solid var(--gray-200);
  max-width: 120px;
  width: 100%;
  border-radius: 5px;
  padding: 5px 10px;
  outline: none;
  transition: all 0.3s ease-in-out;
}

.order-dropdown select:focus {
  border: 1px solid var(--primary);
  transition: all 0.3s ease-in-out;
}

.order-card .order-card-wrapper {
  position: relative;
  width: 100%;
}

.order-card .order-card-wrapper>div.card-title-brand {
  max-width: 200px;
  width: 100%;
}

.order-card .order-card-wrapper>div.card-price {
  max-width: 100px;
  width: 100%;
}

.order-card .order-card-wrapper>div.total-price {
  max-width: 150px;
  width: 100%;
}

.order-card-box .order-btn-group button {
  color: var(--gray-300);
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  border-radius: 0;
}

.order-card-box .order-btn-group .breaker {
  display: inline-block;
  width: 1px;
  height: 20px;
  background-color: var(--gray-200);
}

.order-card-box .order-btn-group button:last-of-type {
  border: 0;
}

.order-card-box .btn-order {
  font-size: 16px;
  padding: 0px 30px;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: none;
  box-shadow: none;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  height: 45px;
  border: 0;
  cursor: pointer;
  background: none !important;
  padding: 0 !important;
  font-size: 16px;
}

.order-card-box .order-btn-group .btn-order.active {
  color: var(--primary);
}

.order-card-box .order-btn-group .btn-order i {
  background-color: #f8f8f8;
  border-radius: 20px;
  height: 30px;
  width: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  transition: background-color 0.3s ease-in-out;
}

.order-card-box .order-btn-group .btn-order.active i {
  background-color: #ffcce5;
  transition: background-color 0.3s ease-in-out;
}

.order-card-box .order-btn-group>button.btn-order:last-of-type::after {
  display: none;
}

.order-card .remove-card {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  border: 0px;
  background: var(--primary);
  color: #fff;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 5px;
  height: 30px;
  width: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.order-card .card-image a {
  display: block;
  width: 100%;
  text-align: center;
}

.order-card .card-image img {
  width: 140px;
  height: 140px;
  object-fit: contain;
  margin: 0 auto;
}

.order-card .card-price .price-drop {
  padding: 5px;
  border-radius: 5px;
}

.order-card .product-status {
  padding: 2px 10px;
  border-radius: 5px;
  text-transform: capitalize;
}

.order-card .product-status.pending {
  background-color: #d6d5d5;
  color: var(--gray-500);
}

.order-card .product-status.processing {
  background-color: var(--link);
  color: #fff;
}

.order-card .product-status.delivered {
  background-color: var(--alternative);
  color: var(--secondary);
}

.order-card .product-status.cancelled {
  background-color: #ffcce5;
  color: var(--primary);
}

/* .order-card .order-card-wrapper > * {
  max-width: 20%;
} */

.saved-price span {
  border-radius: 5px;
}

.order-detail-products .order-card {
  border-bottom: 1px solid var(--gray-100);
}

.order-detail-products .order-card .order-card-wrapper .card-title-brand {
  max-width: 20%;
  width: 100%;
}

/* ORDER DETAILS */

.order-details-container {
  position: relative;
  max-width: 1140px;
  width: 100%;
  padding: 15px 20px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #d6d5d5;
  margin-bottom: 30px;
}

.order-details-container .occasion-order-tag {
  position: absolute;
  top: -15px;
  left: 50%;
  color: var(--primary);
  background: #FFCCE5;
  font-size: 14px;
  font-weight: 500;
  padding: 4px 18px;
  border-radius: 30px;
  transform: translateX(-50%);
  white-space: nowrap;
}

.order-tracking-view .order-placed span {
  color: var(--gray-300);
  font-size: 14px;
  font-weight: 400;
}

.order-tracking-view .order-placed i {
  color: var(--gray-300);
  font-size: 14px;
  font-weight: 500;
  background-color: #f8f8f8;
  border-radius: 30px;
  padding: 5px;
  width: 30px;
  height: 30px;
  transition: none !important;
  display: block;
  margin: 0 auto;
  font-style: normal;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.order-tracking-view .orders-track.active i {
  color: var(--primary);
  background-color: #ffcce5;
}

.order-tracking-view .orders-track.active span {
  color: var(--primary);
}

.order-card.border-line-light {
  border-bottom: 1px solid var(--gray-100);
}

.order-card.border-line-light:last-of-type {
  border-bottom: 0px;
}

.shipping-total-bill-container .tag p {
  padding: 4px 8px;
  border-radius: 5px;
  text-align: center;
  white-space: nowrap;
}

.shipping-total-bill-container {
  position: relative;
  max-width: 1140px;
  width: 48%;
  padding: 15px 20px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #d6d5d5;
  margin-bottom: 30px;
}

.order-tracking-view .order-placed {
  /* max-width: 25%;
  width: 100%; */
}

.order-tracking-view .status-line {
  max-width: 15%;
  width: 100%;
  display: inline-block;
  height: 1px;
  border: 1px dashed var(--gray-300);
  margin-top: 12px;
}

.order-tracking-view .order-placed.active+.status-line {
  border: 1px dashed var(--primary);
}

.order-tracking-view .order-placed.active+.status-line {
  border: 1px dashed var(--primary);
}

.order-tracking-view>span.status-line:last-of-type {
  display: none !important;
}

.order-tracking-view .order-packed {
  max-width: 25%;
  width: 100%;
}

.order-tracking-view .out-for-delivery {
  max-width: 25%;
  width: 100%;
}

.order-tracking-view .delivered {
  max-width: 25%;
  width: 100%;
}

.wrap-card-popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.wrap-card-popup.active {
  display: flex;
}

.wrap-card-popup::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0;
  z-index: 10;
  transition: all 0.3s ease-in-out;
}

.wrap-card-popup.active::before {
  opacity: 0.4;
  transition: all 0.3s ease-in-out;
}

.wrap-card-popup .popup-wrapper {
  position: relative;
  max-width: 500px;
  width: 100%;
  background-color: #fff;
  padding: 40px 25px;
  border-radius: 15px;
  z-index: 20;
  text-align: center;
}

.wrap-card-popup .popup-title {
  font-size: 18px;
  font-weight: 500;
  color: var(--gray-500);
  margin: 0;
  text-align: center;
}

.wrap-card-popup .popup-subheading {
  font-size: 16px;
  font-weight: 400;
  color: var(--secondary);
  margin: 0;
  text-align: center;
  display: block;
}

.wrap-card-popup .popup-image-container {
  width: 100%;
  text-align: center;
  margin: 10px 0px 15px;
}

.wrap-card-popup .popup-image-container img {
  width: 100%;
  max-width: 125px;
  border: 3px solid var(--primary);
}

.wrap-card-popup .btn {
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
}

.order-popup {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.order-popup .order-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  opacity: 0.4;
  z-index: 998;
}

.order-popup .order-popup-wrapper {
  position: relative;
  z-index: 999;
  background: #fff;
  padding: 20px 30px;
  border-radius: 10px;
  max-width: 450px;
  width: 100%;
}

.order-popup .order-popup-wrapper .popup-close {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 22px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.RTL .order-popup .order-popup-wrapper .popup-close {
  right: auto;
  left: 15px;
}

.order-popup .order-popup-wrapper .popup-heading {
  font-size: 20px;
  font-weight: 500;
  color: var(--gray-500);
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.order-popup .order-popup-wrapper .return-quantity {
  width: 100%;
  margin: 0px 0px 20px;
  padding: 0px 12px;
  height: 46px;
  font-size: 14px;
  color: var(--gray-500);
  border: 1px solid #d6d5d5;
  outline: none;
  border-radius: 5px;
  background: #fff;
  transition: all 0.3s ease-in-out;
}

.order-popup .order-popup-wrapper .return-quantity::-webkit-outer-spin-button,
.order-popup .order-popup-wrapper .return-quantity::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.order-popup .order-popup-wrapper .return-quantity[type=number] {
  -moz-appearance: textfield;
}

.order-popup .order-popup-wrapper .return-quantity:focus {
  border: 1px solid var(--primary);
  transition: all 0.3s ease-in-out;
}

.order-popup .order-popup-wrapper .popup-reasons {
  list-style: none;
  padding: 0px;
  margin: 0px 0px 30px;
  max-height: 260px;
  overflow: auto;
  padding-right: 20px;
}

.order-popup .order-popup-wrapper .popup-reasons .single-reason {
  border-bottom: 1px solid #d6d5d5;
  padding: 20px 0px;
}

.order-popup .order-popup-wrapper .popup-reasons>li.single-reason:last-of-type {
  border-bottom: 0px;
}

.order-popup .order-popup-wrapper .popup-reasons .single-reason label {
  padding: 0px;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.order-popup .order-popup-wrapper .popup-reasons .single-reason label::after {
  content: "\eb7d";
  display: inline-block;
  vertical-align: middle;
  font-size: 20px;
  color: var(--primary);
  font-family: 'remixicon' !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: pointer;
}

.order-popup .order-popup-wrapper .popup-reasons .single-reason input:checked+label::after {
  content: "\ed49";
}

.order-popup .order-popup-wrapper .popup-footer {
  justify-content: end;
}

.RTL .order-popup .order-popup-wrapper .popup-footer {
  justify-content: start;
}

.shipping-total-bill-container .coupon-codes span {
  background-color: #FFD0E7;
  padding: 5px 10px;
  font-size: 12px;
  color: var(--primary);
  font-weight: 500;
  border: 1px dashed var(--primary);
  display: inline-flex;
  align-items: center;
  border-radius: 5px;
  margin: 5px;
}

.order-card .return-tag p {
  border-radius: 5px;
  padding: 0.25rem 0.5rem;
}

.order-card .total-price .order-card-discount {
  position: absolute;
  right: 0;
  white-space: nowrap;
}


.order-products-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.order-products-wrapper .order-details-container {
  padding: 0px;
  width: 100%;
  max-width: 100%;
}

.order-products-wrapper .order-details-container .order-card {
  background: none;
  margin: 0px;
}

.order-products-wrapper .order-details-container .order-card .order-product-footer {
  border-top: 1px dashed var(--gray-200);
  padding-top: 20px;
}

.order-products-wrapper .order-details-container .order-card .order-product-footer .return-button {
  font-size: 14px;
  font-weight: 400;
  padding: 5px 15px;
  height: 35px;
}

/* MOBILE RESPONSIVE */

@media screen and (max-width: 1360px) {
  .order-card .card-price .price-drop {
    font-size: 12px;
  }

  .order-card .order-card-wrapper .total-price span.product-currency span,
  .order-card .order-card-wrapper .total-price span.product-currency,
  .order-card .order-card-wrapper .product-quanity,
  .order-card .order-card-wrapper .product-title,
  .order-card .order-card-wrapper .product-price span {
    font-size: 14px;
  }

  .order-card .order-card-wrapper .card-image img {
    width: 100px;
    height: 100px;
  }

  .order-card .order-card-wrapper .return-button {
    font-size: 14px;
    padding: 5px 15px;
    height: 40px;
  }
}

@media screen and (max-width: 1250px) {
  .order-detail-products {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .order-products-wrapper .order-details-container .order-card .wrap-details {
    position: absolute;
    top: -5px;
    left: -10px;
  }

  .order-products-wrapper .order-details-container .order-card .wrap-details button.link {
    font-size: 0px;
  }

  .order-products-wrapper .order-details-container .order-card .wrap-details button.link i {
    margin: 0 !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: var(--alternative);
    color: var(--secondary);
    height: 35px;
    width: 35px;
    border-radius: 50px;
  }

  .order-products-wrapper .order-details-container .order-card .order-product-footer {
    margin-top: 20px;
    flex-direction: column;
  }

  .order-products-wrapper .order-details-container .order-card .order-product-footer .order-product-reason {
    order: 2;
    width: 100%;
    text-align: center;
  }

  .order-products-wrapper .order-details-container .order-card .order-product-footer .order-product-reason p {
    font-size: 12px;
    text-align: center;
  }

  .order-products-wrapper .order-details-container .order-card .order-product-footer .order-product-reason p strong {
    display: block;
  }

  .order-products-wrapper .order-details-container .order-card .order-product-footer .order-product-status {
    order: 1;
    width: 100%;
    text-align: center;
  }

  .order-products-wrapper .order-details-container .order-card .order-product-footer .order-product-status p {
    font-size: 12px;
    text-align: center;
  }

  .order-products-wrapper .order-details-container .order-card .order-product-footer .return-button {
    width: 100%;
    height: 40px;
  }

  .order-products-wrapper .order-details-container {
    max-width: 48%;
  }

  .order-detail-products .order-card {
    border-bottom: 0px;
    border-right: 1px solid var(--gray-100);
    border-radius: 0;
  }

  .order-card .return-btn {
    margin-top: 6px;
    width: 100%;
  }

  .order-card .return-btn button {
    width: 100%;
  }

  .order-detail-products .order-card:nth-last-of-type(2) {
    border: 0px !important;
  }

  .order-detail-products .order-detail-footer {
    border-top: 1px solid var(--gray-100);
    padding-top: 1rem;
  }

  .order-card .card-image,
  .order-card .card-price,
  .order-card .card-rating,
  .order-card .card-title-brand,
  .order-card .product-action {
    max-width: 100%;
    width: 100%;
  }

  .order-card .product-title {
    min-height: 50px;
    max-height: 50px;
    font-size: 14px;
    margin: 0;
  }

  .order-card .card-rating,
  .order-card .card-image,
  .order-card .card-price,
  .order-card .card-title-brand {
    margin-bottom: 10px;
  }

  .order-card .order-card-wrapper>div.card-title-brand {
    max-width: 100%;
    text-align: center;
  }

  .order-details-container>div.order-card:last-of-type {
    border-bottom: 1px solid #d6d5d5;
  }

  .order-card .price-drop,
  .order-card .hide-vertical {
    display: none;
  }

  .order-detail-products .order-card .order-card-wrapper .card-title-brand {
    max-width: 100%;
    width: 100%;
  }

  .order-card-box .btn-order {
    font-size: 12px !important;
  }

  .order-card-box .order-btn-group .btn-order i {
    background-color: #ffffff;
    height: 20px;
    width: 20px;
    font-size: 16px;
  }

  .order-card-box .order-btn-group .btn-order.active i {
    background-color: #ffffff;
  }

  .order-detail-card .order-detail-header .order-no {
    flex-direction: column;
    margin: 0 !important;
  }

  .order-detail-card .order-detail-header .order-no span {
    margin: 0 !important;
  }

  .order-detail-card .order-detail-header .orderplaced-date {
    flex-direction: column;
    margin: 0 !important;
  }

  .order-detail-card .order-detail-header .orderplaced-date span {
    margin: 0 !important;
  }

  .order-detail-card .order-detail-header .order-status {
    flex-direction: column;
    margin: 0 !important;
  }

  .order-detail-card .order-detail-header .order-status span {
    margin: 0 !important;
  }

  .order-detail-card .order-detail-header .delivery-date {
    flex-direction: column;
    margin: 0 !important;
  }

  .order-detail-card .order-detail-header .delivery-date span {
    margin: 0 !important;
  }

  .order-detail-card .order-detail-products .order-detail-footer>div {
    flex-direction: column;
  }

  .order-detail-card .order-detail-products .order-detail-footer>div .ml-2 {
    margin: 0 !important;
  }

  .shipping-total-bill-container {
    max-width: 100%;
    width: 100%;
  }

  .order-product-container {
    border: 0px;
    padding: 0px;
  }

  .order-product-container .order-card {
    border: 1px solid #d6d5d5;
    border-radius: 10px;
    margin-bottom: 20px;
  }

  .order-card .total-price .order-card-discount {
    position: relative;
  }

  .order-card {
    overflow: hidden;
  }

  .shipment-price-label {
    text-align: center;
  }

  .order-card .order-card-wrapper>div.card-title-brand {
    max-width: none;
  }

  .order-card .order-card-wrapper>div.card-price {
    max-width: none;
  }

  .order-card .order-card-wrapper>div.total-price {
    max-width: none;
    width: auto;
  }

}

@media screen and (max-width: 1100px) {

  .order-detail-card .order-detail-header>div:nth-of-type(1),
  .order-detail-card .order-detail-header>div:nth-of-type(3n),
  .order-details-header>div:nth-of-type(3n) {
    max-width: 60%;
    width: 100%;
    margin-bottom: 5px !important;
  }

  .order-detail-card .order-detail-header>div:nth-of-type(2n),
  .order-details-header>div:nth-of-type(2n) {
    max-width: 40%;
    width: 100%;
    margin-bottom: 5px !important;
  }

  .order-detail-card .order-detail-header {
    padding-bottom: 10px !important;
    flex-wrap: wrap;
  }

  .order-detail-card .order-detail-products .order-detail-footer {
    flex-wrap: wrap;
  }

  .order-detail-card .order-detail-products .order-detail-footer>div:nth-of-type(1),
  .order-detail-card .order-detail-products .order-detail-footer>div:nth-of-type(3n) {
    max-width: 60%;
    width: 100%;
    margin-bottom: 5px !important;
  }

  .order-detail-card .order-detail-products .order-detail-footer>div:nth-of-type(2n) {
    max-width: 40%;
    width: 100%;
    margin-bottom: 5px !important;
  }
}

@media screen and (max-width: 1024px) {
  .order-detail-header {
    justify-content: space-between;
  }

  .order-detail-header>div {
    max-width: 49%;
    width: 100%;
  }

  .order-details-container .order-detail-header>div:nth-of-type(2n) {
    text-align: right;
  }

  .order-tracking-view .order-placed span {
    font-size: 14px;
  }

  .order-tracking-view .order-packed span {
    font-size: 14px;
  }

  .order-tracking-view .out-for-delivery span {
    font-size: 14px;
  }

  .order-tracking-view .delivered span {
    font-size: 14px;
  }

  .order-tracking-view .status-line {
    max-width: 5%;
  }

  .order-dropdown {
    display: block;
  }

  .order-filter-buttons {
    display: none;
  }
}

@media screen and (max-width: 960px) {
  .order-card-box .order-btn-group button {
    max-width: 100%;
    width: auto;
    border-right: 0px;
  }

  .order-card-box .order-btn-group .btn-order i {
    display: none;
  }

  .order-card-box {
    padding: 0;
    border: 0px;
  }

  .order-tracking-view .order-placed span {
    font-size: 12px;
  }
}

@media screen and (max-width: 860px) {
  .order-products-wrapper .order-details-container {
    max-width: 100%;
    text-align: center;
  }

  .order-card .card-rating>div,
  .order-card .product-price {
    justify-content: center;
  }

  .order-detail-products .order-card {
    border-bottom: 1px solid var(--gray-100);
    border-right: 0px;
    border-radius: 0;
  }

  .order-detail-header .order-no span {
    font-size: 12px;
  }

  .order-detail-header .orderplaced-date span {
    font-size: 12px;
  }

  .order-detail-header .order-status span {
    font-size: 12px;
  }

  .order-detail-header .delivery-date span {
    font-size: 12px;
  }

  .order-detail-card .order-detail-products .order-detail-footer .total-amount span {
    font-size: 12px;
  }

  .order-card .order-card-wrapper>div.card-title-brand {
    margin: 0 auto 20px;
    max-width: 100%;
  }

  .order-card .order-card-wrapper>div.card-title-brand>a {
    min-height: auto;
  }

  .order-card .wrap-details {
    margin: auto;
  }

  .shipping-total-bill-container .coupon-codes-container {
    flex-direction: column;
    align-items: normal !important;
  }

  .shipping-total-bill-container .coupon-codes-container .coupon-codes {
    text-align: right !important;
  }
}

@media screen and (max-width: 700px) {
  .order-products-wrapper .order-details-container {
    max-width: 49%;
  }

  .wrap-card-popup {
    padding: 0px 20px;
  }

  .wrap-card-popup .popup-wrapper {
    max-width: 340px;
    padding: 30px 25px;
  }

  .wrap-card-popup .popup-title {
    font-size: 16px;
  }

  .wrap-card-popup .popup-subheading {
    font-size: 14px;
  }

}

@media screen and (max-width: 550px) {

  .order-product-container .order-card .total-price,
  .order-product-container .order-card .card-quantity {
    width: 100%;
    margin-top: 10px;
    text-align: center;
  }

  .order-products-wrapper .order-details-container {
    max-width: 100%;
  }
  .order-card .order-card-wrapper>div.total-price {
    width: -moz-available;
  }
}

@media screen and (max-width: 440px) {
  .order-details-header>div {
    width: 100% !important;
    max-width: 100% !important;
    justify-content: space-between;
  }

  .order-detail-footer .view-details-btn a,
  .order-detail-footer .total-amount span {
    font-size: 14px;
  }

  .order-tracking-view .order-placed span {
    font-size: 12px;
  }

  .order-tracking-view .order-packed span {
    font-size: 12px;
  }

  .order-tracking-view .out-for-delivery span {
    font-size: 12px;
  }

  .order-tracking-view .delivered span {
    font-size: 12px;
  }
}