aside.profile-siebar{
    max-width: 380px;
    width: 100%;
    padding: 50px 20px;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #d6d5d5;
}
aside.profile-siebar .sidebar-header{
    text-align: center;
    margin-bottom: 30px;
}
aside.profile-siebar .sidebar-header label{
    position: relative;
    cursor: pointer;
    margin: 0;
    padding: 0;
    display: inline-flex;
}
aside.profile-siebar .sidebar-header label::before{
    content: "";
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 152px;
    height: 152px;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 120px;
    transform: translate(-50%,-50%);
    background-color: var(--primary);
    z-index:0;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}
aside.profile-siebar .sidebar-header label::after{
    content: attr(data-text);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: var(--white);
    z-index: 1;
    font-size: 16px;
    font-weight: 500;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}
aside.profile-siebar .sidebar-header label:hover::before{
    opacity: 0.8;
    transition: opacity 0.3s ease-in-out;
}
aside.profile-siebar .sidebar-header label:hover::after{
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}
aside.profile-siebar .sidebar-header .profile-avatar{
    width: 150px;
    height: 150px;
    margin: 0 auto;
    border-radius: 120px;
    object-fit: cover;
    border: 3px solid var(--primary);
    padding: 5px;
}
aside.profile-siebar .sidebar-header .profile-title{
    margin: 0px 0px 0px 0px;
    font-weight: 500;
    font-size: 18px;
    color: var(--gray-500);
}
aside.profile-siebar .sidebar-header .profile-email{
    margin: 0px 0px 0px 0px;
    font-weight: 400;
    font-size: 14px;
    color: var(--gray-400);
}
aside.profile-siebar .sidebar-navigation{
    display: inline-flex;
    flex-direction: column;
    width: 100%;
}
aside.profile-siebar .sidebar-navigation a{
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    color: var(--gray-500);
    padding: 15px;
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
    border-radius: 5px;
}
aside.profile-siebar .sidebar-navigation a i{
    font-size: 22px;
    margin-right: 15px;
    font-weight: 100;
    color: var(--gray-400);
}
.RTL aside.profile-siebar .sidebar-navigation a i{
    margin-right: 0px;
    margin-left: 15px;
}
aside.profile-siebar .sidebar-navigation a.active{
    font-weight: 500;
    color: var(--secondary);
    background-color: var(--alternative);
}
aside.profile-siebar .sidebar-navigation a.active i{
    color: var(--secondary);
}
.label-min::before, .label-max::before {
    content: " SAR";
    display: inline-block;
    margin-right: 5px;
  }

@media screen and (max-width: 1360px){
    aside.profile-siebar{
        max-width: 320px;
    }
}
@media screen and (max-width: 1100px){
    aside.profile-siebar {
        max-width: 280px;
    }
    aside.profile-siebar .sidebar-header .profile-avatar {
        width: 100px;
        height: 100px;
    }
    aside.profile-siebar .sidebar-header label::before{
        width: 102px;
        height: 102px;
    }
    aside.profile-siebar .sidebar-header label::after{
        width: 102px;
        height: 102px;
    }
    aside.profile-siebar .sidebar-navigation a {
        font-size: 14px;
        padding: 10px 15px;
    }
    aside.profile-siebar .sidebar-navigation a i {
        font-size: 18px;
    }
    aside.profile-siebar .sidebar-header .profile-email {
        font-size: 12px;
        color: var(--gray-500);
    }
}

@media screen and (max-width: 700px) {
    aside.profile-siebar {
        max-width: 100%;
        margin-bottom: 40px;
    }
    aside.profile-siebar .sidebar-navigation a {
        font-size: 16px;
        max-width: 500px;
    }
    aside.profile-siebar .sidebar-navigation a i {
        font-size: 22px;
    }
}