/* Modal overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

/* Modal content */
.modal {
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    margin: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    width: 100%;
    max-width: 620px;
}
.modal.reason-to-return-modal, .modal.return-request-success-modal{
    max-width: 450px;
}

.modal .modal-content {
    max-width: 620px;
    max-height: 590px;
    overflow-y: auto;
}
.modal.return-request-success-modal .modal-content{
    max-width: 450px;
    max-height: 540px;
    overflow-y: auto;
}
.modal.reason-to-return-modal .modal-content .popup-reasons{
    max-width: 450px;
    max-height: 230px;
    overflow-y: auto;
}

/* Modal title */
.modal h2 {
    margin-top: 0;
}

.modal-header {
    position: relative;
}

/* Modal close button */
.modal .close-icon {
    color: #262626;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    font-size: 22px;
    cursor: pointer;
    text-decoration: none;
    position: absolute;
    top: -20px;
    right: -27px;
}
body.RTL .modal .close-icon {
    right: auto;
    left: -27px;
}

.modal-card .product-description {
    gap: 20px;
}

.modal-card .product-description .product-image img {
    width: 140px;
    height: 140px;
}

.modal-card .product-description .card-meta {
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: flex-start;
}


.reason-to-return-modal .popup-reasons {
    list-style: none;
    padding: 0px;
    margin: 0px 0px 30px;
    /* max-height: 260px;
    overflow: auto; */
    padding-right: 20px;
}

.reason-to-return-modal .popup-reasons .single-reason {
    border-bottom: 1px solid #d6d5d5;
    padding: 20px 0px;
}

.reason-to-return-modal .popup-reasons>li.single-reason:last-of-type {
    border-bottom: 0px;
}

.reason-to-return-modal .popup-reasons .single-reason label {
    padding: 0px;
    display: flex;
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.reason-to-return-modal .popup-reasons .single-reason label::after {
    content: "\eb7d";
    display: inline-block;
    vertical-align: middle;
    font-size: 20px;
    color: var(--primary);
    font-family: 'remixicon' !important;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: pointer;
}

.reason-to-return-modal .popup-reasons .single-reason input:checked+label::after {
    content: "\ed49";
}

.reason-to-return-modal .product-image img {
    width: 140px;
    height: 140px;
}
.modal.reason-to-return-modal .upload-proof-image img{
    width: 200px;
    height: 200px;
}
.modal.return-request-success-modal .return-request-image img{
    width: 150px;
    height: 150px;
}


.account-body .product-quantity-container .product-quantity-btn {
    background-color: var(--white);
    border: 0px;
    outline: none;
    color: var(--primary);
    box-shadow: 0px 1px 8px #ddd;
    height: 32px;
    width: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    border-radius: 5px;
    cursor: pointer;
}

.account-body .product-quantity-container .product-quantity-field {
    background-color: var(--white);
    border: 0px;
    outline: none;
    color: var(--gray-500);
    box-shadow: none;
    height: 42px;
    width: 50px;
    font-size: 18px;
    border-radius: 5px;
    text-align: center;
    margin: 0px 5px;
    cursor: default;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
}

@media screen and (max-width: 1250px) {
    .btn.order-detail-btn {
        font-size: 16px;
        padding: 0px 30px;
    }
}
@media screen and (max-width: 495px) {
    .modal-card .product-description {
        justify-content: center !important;
        gap: 0px !important;
    }
    .modal-card .product-description .product-quantity-container {
        justify-content: center !important;
    }
    .modal-card .product-description .product-title{
        text-align: center;
    }

    .modal .modal-content {
        max-height: 560px;
    }
}

@media screen and (max-width: 450px) {
    .modal-card .product-description .card-meta {
        align-items: center;
    }
}