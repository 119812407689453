footer{
    background-color: #f8f8f8;
}
footer .footer-features{
    padding-top: 48px;
    padding-bottom: 48px;
    margin-bottom: 48px;
    border-bottom: 2px dashed var(--gray-200);
}
footer .footer-features .featuer-container{
    flex: 1;
}
footer .footer-features > div.featuer-container:nth-last-of-type(2){
    border-left: 2px dashed var(--gray-200);
    border-right: 2px dashed var(--gray-200);
}
footer .footer-features .featuer-container h4{
    margin: 0 0 0 25px;
    font-size: 18px;
    font-weight: 400;
    color: var(--gray-500);
}
.RTL footer .footer-features .featuer-container h4{
    margin: 0 25px 0 0;
}
footer .footer-general{
    gap: 0px 25px;
    margin-bottom: 30px;
}
footer .footer-general .footer-column{
    flex: 1;
}
footer .footer-general > div.footer-column:first-of-type{
    flex: 2;
}
footer .footer-general .footer-column .footer-logo{
    max-width: 150px;
    width: 100%;
    margin-bottom: 25px;
}
footer .footer-general .footer-column .footer-logo img{
    width: 100%;
}
footer .footer-general .footer-column .footer-paragraph{
    font-size: 16px;
    margin: 0 0 30px;
    /* max-width: 360px; */
}
footer .footer-general .footer-column .download-btn a img{
    max-width: 130px;
}
footer .footer-general .footer-column .download-btn > a:first-of-type{
    margin-right: 15px;
}
.RTL footer .footer-general .footer-column .download-btn > a:first-of-type{
    margin-right: 0px;
    margin-left: 15px;
}
footer .footer-general .footer-column .footer-title{
    color: var(--gray-500);
    font-size: 18px;
    font-weight: 500;
    margin: 30px 0 30px;
}
footer .footer-general .footer-column .footer-row .footer-links{
    max-width: 33%;
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
}
footer .footer-general .footer-column .footer-links .footer-link,
footer .footer-general .footer-column .footer-row .footer-links .footer-link{
    margin-bottom: 15px;
}
footer .footer-general .footer-column .footer-links .footer-link a,
footer .footer-general .footer-column .footer-row .footer-links .footer-link a{
    display: inline-block;
    text-decoration: none;
    font-size: 16px;
    color: var(--gray-500);
    font-weight: 400;
    transition: all 0.3s ease-in-out;
}
footer .footer-general .footer-column .footer-links .footer-link a:hover,
footer .footer-general .footer-column .footer-row .footer-links .footer-link a:hover{
    color: var(--primary);
    transition: all 0.3s ease-in-out;
}
footer .footer-general .footer-column .footer-links{
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
}
footer .footer-general .footer-column .footer-information .contact-info{
    margin: 0 0 15px;
}
footer .footer-general .footer-column .footer-information > div.contact-info:last-of-type{
    margin: 0 0 0;
}
footer .footer-general .footer-column .footer-information .contact-info svg{
    margin: 5px 15px 0 0;
    max-width: 15px;
    stroke: var(--primary);
    stroke-width: 2px;
}
.RTL footer .footer-general .footer-column .footer-information .contact-info svg{
    margin: 5px 0 0 15px;
}
footer .footer-general .footer-column .footer-information .contact-info p{
    font-size: 12px;
    color: var(--gray-400);
}
footer .footer-general .footer-column .footer-information .contact-info a{
    font-size: 14px;
    color: var(--gray-500);
    text-decoration: none;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
}
footer .footer-general .footer-column .footer-information .contact-info a:hover{
    color: var(--primary);
    transition: all 0.3s ease-in-out;
}


@media screen and (max-width: 1200px){
    footer .footer-features .featuer-container {
        flex-direction: column;
    }
    footer .footer-features .featuer-container svg{
        margin-bottom: 10px;
        height: 40px;
    }
    footer .footer-features .featuer-container h4 {
        font-size: 14px;
        font-weight: 500;
        margin: 0;
    }
    footer .footer-general {
        gap: 0;
        flex-wrap: wrap;
    }
    footer .footer-general > div.footer-column:first-of-type {
        flex: inherit;
        max-width: 78%;
        width: 100%;
        text-align: center;
        margin: 0 auto 20px;
    }
    footer .footer-general .footer-column {
        flex: inherit;
        max-width: 24%;
        width: 100%;
    }
    footer .footer-general .footer-column .footer-paragraph {
        margin: 0 auto 30px;
        max-width: 600px;
    }
    footer .footer-general .footer-column .footer-title {
        font-size: 16px;
        margin: 30px 0 20px;
    }
    footer .footer-general .footer-column .footer-links .footer-link a, 
    footer .footer-general .footer-column .footer-row .footer-links .footer-link a {
        font-size: 14px;
    }
}
@media screen and (max-width: 860px){
    footer .footer-general .footer-column {
        max-width: 48%;
        text-align: center;
    }
    footer .footer-general .footer-column .footer-information .contact-info svg {
        display: none;
    }
    footer .footer-general .footer-column .footer-information .contact-info {
        justify-content: center;
    }
    footer .footer-features {
        flex-direction: column;
        display: none!important;
    }
    footer .footer-features .featuer-container h4 {
        font-size: 16px;
    }
    footer .footer-features > div.featuer-container:nth-last-of-type(2) {
        border-left: 0px;
        border-right: 0px;
        padding: 20px 0px;
        margin: 20px 0px;
    }
    footer .footer-general {
        padding-top: 50px;
    }
}
@media screen and (max-width: 600px){
    footer .footer-general .footer-column {
        max-width: 100%;
    }
    footer .footer-general .footer-column .footer-information .contact-info svg {
        display: inline-block;
        margin: 0px 0px 10px;
    }
    footer .footer-general .footer-column .footer-information .contact-info {
        flex-direction: column;
        align-items: center;
    }
    footer .footer-general .footer-column .footer-information .contact-info a,
    footer .footer-general .footer-column .footer-links .footer-link a, 
    footer .footer-general .footer-column .footer-row .footer-links .footer-link a {
        font-size: 16px;
    }
    footer .footer-general .footer-column .footer-title {
        font-size: 18px;
    }
    footer .footer-general > div.footer-column:first-of-type {
        max-width: 100%;
    }
}
@media screen and (max-width: 450px){
    footer .footer-features {
        flex-direction: column;
        display: flex !important;
    }
}