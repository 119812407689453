#product-review .review-cards-header {
  position: relative;
  width: 100%;
  padding: 15px 20px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #d6d5d5;
  margin-bottom: 20px;
}
#product-review .review-cards .review-btn-group {
  padding-left: 1rem;
}
#product-review .review-btn-group .btn-review {
  padding: 0px 30px;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: none;
  box-shadow: none;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  height: 45px;
  border: 0;
  cursor: pointer;
  background: none !important;
  padding: 0 !important;
  color: var(--gray-300);
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}

#product-review .review-btn-group .btn-review i {
  background-color: #f8f8f8;
  border-radius: 20px;
  height: 30px;
  width: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  transition: background-color 0.3s ease-in-out;
}

#product-review .review-btn-group .btn-review.active {
  color: var(--primary);
  transition: all 0.3s ease-in-out;
}

#product-review .review-btn-group .btn-review.active  i {
  background-color: #ffcce5;
  transition: background-color 0.3s ease-in-out;
}
#product-review .review-btn-group .btn-review::after {
  content: "";
  border-left: 1px solid var(--gray-200);
  height: 20px;
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 0 !important;
}
#product-review .review-btn-group > button.btn-review:last-of-type::after {
  display: none;
}

@media screen and (max-width: 1250px) {
  #product-review .review-cards-header {
    padding: 10px 20px;
  }
  #product-review .review-btn-group .btn-review {
    font-size: 14px;
  }
  #product-review .review-btn-group .btn-review i,
  #product-review .review-btn-group .btn-review.active i{
    background-color: #fff;
    height: auto;
    width: auto;
  }
}
@media screen and (max-width: 700px) {
  #product-review .review-btn-group {
    justify-content: center !important;
  }
}
@media screen and (max-width: 500px) {
  #product-review .review-btn-group {
    justify-content: center !important;
  }

  #product-review .review-btn-group .btn-review {
    font-size: 14px;
  }
}
