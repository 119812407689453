#login{
    background-color: var(--primary);
    background-image: url(../images/login-bg.png);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    min-width: 100%;
    padding: 40px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
#login .login-form-wrapper{
    background-color: var(--white);
    max-width: 1024px;
    width: 100%;
    border-radius: 10px;
    display: flex;
    overflow: hidden;
    margin: 0 auto;
    box-shadow: 0px 0px 15px 0px #d44289;
}
#login .login-form-wrapper form,
#login .login-form-wrapper .login-background{
    max-width: 50%;
    width: 100%;
}

#login .login-form-wrapper form{
    padding: 50px 60px 30px;
    position: relative;
}
#login .login-form-wrapper .go-back{
    position: absolute;
    top: 30px;
    left: 30px;
    text-decoration: none;
    font-size: 18px;
    color: var(--secondary);
    background-color: var(--alternative);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    width: 34px;
    border-radius: 50px;
}
#login .login-form-wrapper form .form-logo{
    max-width: 160px;
    width: 100%;
    margin: 0 auto 30px;
    display: block;
}
#login .login-form-wrapper form .form-title{
    font-weight: 600;
    color: var(--gray-500);
    font-size: 28px;
    text-align: center;
    margin: 0;
}
#login .login-form-wrapper form .form-description{
    font-weight: 400;
    color: var(--gray-500);
    font-size: 14px;
    text-align: center;
    margin: 0 0 40px;
}
#login .login-form-wrapper form .form-group{
    margin: 0 0 15px;
}
#login .login-form-wrapper form .form-group.has-icon{
    position: relative;
}
#login .login-form-wrapper form .form-group label{
    font-weight: 400;
    color: var(--gray-500);
    font-size: 16px;
    margin: 0 0 10px;
    display: inline-block;
}
#login .login-form-wrapper form .form-group select,
#login .login-form-wrapper form .form-group input:not(.input-checkbox){
    width: 100%;
    margin: 0px;
    padding: 0px 15px;
    height: 48px;
    font-size: 16px;
    color: var(--gray-500);
    border: 1px solid #D6D5D5;
    outline: none;
    border-radius: 5px;
    background: #fff;
    transition: all 0.3s ease-in-out;
}
#login .login-form-wrapper form .form-group.has-icon input:not(.input-checkbox){
    padding: 0px 35px 0px 15px;
}
.RTL #login .login-form-wrapper form .form-group.has-icon input:not(.input-checkbox){
    padding: 0px 15px 0px 35px;
}
#login .login-form-wrapper form .form-group.has-icon button{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 48px;
    right: 10px;
    background: none;
    border: 0px;
    outline: none;
    font-size: 18px;
    color: var(--gray-400);
    cursor: pointer;
}
.RTL #login .login-form-wrapper form .form-group.has-icon button{
    right: auto;
    left: 10px;
}
#login .login-form-wrapper form .form-group select:focus,
#login .login-form-wrapper form .form-group input:focus{
    border: 1px solid var(--primary);
    transition: all 0.3s ease-in-out;
}

#login .login-form-wrapper form .form-group input.input-checkbox + label{
    font-size: 14px;
}
#login .login-form-wrapper form .form-group .line-left {
    display: inline-block;
    height: 2px;
    width: 100px;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    background: var(--gray-100);
    background-image: linear-gradient(to right, #fff, var(--gray-100));
    margin-right: 10px;
}
#login .login-form-wrapper form .form-group .line-right {
    display: inline-block;
    height: 2px;
    width: 100px;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    background: var(--gray-100);
    background-image: linear-gradient(to right, var(--gray-100) ,#fff);
    margin-left: 10px;
}
#login .login-form-wrapper form .social-login-wrapper{
    margin: 0px 0px 40px;
}
#login .login-form-wrapper form .social-login-wrapper .social-login {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px 25px;
    background: #fff;
    border: 1px solid var(--gray-100);
    border-radius: 5px;
    margin: 0px 15px;
}
#login .login-form-wrapper form .social-login-wrapper .social-login img {
    max-width: 24px;
    width: 100%;
}
#login .login-form-wrapper .login-background img{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    object-fit: cover;
}
#login .login-form-wrapper .otp-fields{
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    margin: 10px 0;
}

@media screen and (max-width: 960px) {
    #login .login-form-wrapper form {
        padding: 50px 40px 30px;
    }
    #login .login-form-wrapper form{
        max-width: 55%;
    }
    #login .login-form-wrapper .login-background {
        max-width: 45%;
    }
}
@media screen and (max-width: 800px) {
    #login .login-form-wrapper form {
        padding: 50px 30px 30px;
    }
    #login .login-form-wrapper form{
        max-width: 60%;
    }
    #login .login-form-wrapper .login-background {
        max-width: 40%;
    }
}
@media screen and (max-width: 768px) {
    #login .login-form-wrapper form {
        padding: 50px 40px 30px;
        max-width: 100%;
    }
    #login .login-form-wrapper .login-background {
        display: none;
    }
}
@media screen and (max-width: 500px) {
    #login .login-form-wrapper form {
        padding: 50px 30px 30px;
    }
    #login .login-form-wrapper form .form-title {
        font-size: 24px;
    }
    #login .login-form-wrapper form .form-description {
        margin: 0 0 20px;
    }
    #login .login-form-wrapper form .form-group .line-right ,
    #login .login-form-wrapper form .form-group .line-left {
        width: 50px;
    }
    #login .login-form-wrapper form .social-login-wrapper .social-login {
        padding: 10px 15px;
        margin: 0px 10px;
    }
    #login .login-form-wrapper form > div:last-of-type{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    #login .login-form-wrapper form .social-login-wrapper {
        margin: 0px 0px 20px;
    }
    #login .login-form-wrapper form > div:last-of-type a{
        margin: 0px!important;
    }
}
@media screen and (max-width: 400px) {
    #login .login-form-wrapper form .form-additional{
        flex-direction: column;
    }
    #login .login-form-wrapper form .form-additional .form-group{
        margin: 0;
    }
    #login,
    #login .wrapper {
        padding: 0px;
        background: #fff;
    }
    #login .login-form-wrapper {
        box-shadow: none
    }
    #login .login-form-wrapper form {
        padding: 30px;
    }
    #login .login-form-wrapper .go-back {
        top: 0px;
        left: 20px;
    }
}