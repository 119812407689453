.wishlist-card{
    position: relative;
    padding: 20px 40px;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #d6d5d5;
    margin-bottom: 30px;
    width: 100%;
}
.wishlist-card .remove-card{
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    border: 0px;
    background: var(--primary);
    color: #fff;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 5px;
    height: 30px;
    width: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}
.wishlist-card .card-image a{
    display: block;
    width: 100%;
    text-align: center;
}
.wishlist-card .card-image img{
    width: 140px;
    height: 140px;
    object-fit: contain;
    margin: 0 auto;
}
.wishlist-card .card-price .price-drop{
    padding: 5px;
    border-radius: 5px;
}
.wishlist-card .product-title{
    min-height: 50px;
    max-height: 50px;
    overflow: hidden;
}
.wishlist-card .product-action{
    font-size: 14px;
    padding: 5px 15px;
    height: 40px;
}
.wishlist-card > .card-title-brand {
    width: 100%;
}
.wishlist-card > .card-title-brand,
.wishlist-card > .card-price,
.wishlist-card > .card-rating,
.wishlist-card > .product-action,
.wishlist-card > .card-image{
    max-width: 20%;
}
.wishlist-card > .card-title-brand,
.wishlist-card > .card-price,
.wishlist-card > .card-rating{
    margin-top: 40px;
}
.wishlist-card > .product-action{
    margin-top: 55px;
}
.wishlist-card > .card-rating .card-rating-value{
    display: block;
    margin-top: -4px;
}
.wishlist-card .product-card-popup{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    z-index: 10;
}
.wishlist-card .product-card-popup.active{
    display: flex;
}
.wishlist-card .product-card-popup::before{
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0;
    z-index: 10;
    transition:  all 0.3s ease-in-out;
}
.wishlist-card .product-card-popup.active::before{
    opacity: 0.4;
    transition:  all 0.3s ease-in-out;
}
.wishlist-card .product-card-popup .popup-wrapper{
    position: relative;
    max-width: 500px;
    width: 100%;
    background-color: #fff;
    padding: 40px 25px;
    border-radius: 15px;
    z-index: 20;
    text-align: center;
}
.wishlist-card .product-card-popup .popup-title{
    font-size: 20px;
    font-weight: 500;
    color: var(--gray-500);
    margin: 0;
    text-align: center;
}
.wishlist-card .product-card-popup .popup-subheading{
    font-size: 16px;
    font-weight: 400;
    color: var(--secondary);
    margin: 0;
    text-align: center;
    display: block;
}
.wishlist-card .product-card-popup .popup-image-container{
    width: 100%;
    text-align: center;
    margin: 30px 0px;
}
.wishlist-card .product-card-popup .popup-image-container img{
    width: 100%;
    max-width: 300px;
}
.wishlist-card .product-card-popup .btn{
    width: 100%;
    max-width: 360px;
    margin: 0 auto;
}
.wishlist-card .product-card-popup a.btn{
    margin: 0 auto 15px;
}


@media screen and (max-width: 1360px) {
    .wishlist-card .product-title {
        min-height: 60px;
        max-height: 60px;
        font-size: 14px;
    }
    .wishlist-card .card-price .price-drop {
        font-size: 12px;
    }
}
@media screen and (max-width: 1250px) {
    .wishlist-card{
        max-width: 32%;
        width: 100%;
        padding: 20px;
    }
    .wishlist-card .card-image,
    .wishlist-card .card-price,
    .wishlist-card .card-rating,
    .wishlist-card .card-title-brand,
    .wishlist-card .product-action {
        max-width: 100%;
        width: 100%;
    }
    .wishlist-card .product-title {
        min-height: 50px;
        max-height: 50px;
        font-size: 14px;
        margin: 0;
    }
    
    .wishlist-card .card-title-brand,
    .wishlist-card .card-rating,
    .wishlist-card .card-image,
    .wishlist-card .card-price{
        margin-bottom: 10px;
    }
    .wishlist-card > .product-action,
    .wishlist-card > .card-title-brand, 
    .wishlist-card > .card-price, 
    .wishlist-card > .card-rating {
        margin-top: 0px;
    }
    .wishlist-card .price-drop,
    .wishlist-card .hide-vertical {
        display: none;
    }
}

@media screen and (max-width: 1024px) {
    .wishlist-card{
        max-width: 48%;
    }
}
@media screen and (max-width: 860px) {
    .wishlist-card{
        max-width: 48%;
        text-align: center;
    }
    .wishlist-card .card-rating > div,
    .wishlist-card .product-price{
        justify-content: center;
    }
}

@media screen and (max-width: 600px) {
    #productSingle .product-card-popup {
        padding: 0px 20px;
    }
    #productSingle .product-card-popup .popup-wrapper {
        max-width: 340px;
        padding: 30px 25px;
    }
    #productSingle .product-card-popup .popup-title {
        font-size: 16px;
    }
    #productSingle .product-card-popup .popup-subheading {
        font-size: 14px;
    }
    #productSingle .product-card-popup .popup-image-container img {
        max-width: 200px;
    }
}
@media screen and (max-width: 500px) {
    .wishlist-card{
        max-width: 100%;
    }
}