.tabs {
  position: relative;
  max-width: 1140px;
  width: 100%;
  padding: 15px 20px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #d6d5d5;
  margin-bottom: 2rem;
}

.tabs-btn {
  padding-left: 1rem;
}
.btn-occasion {
  padding: 0px 30px;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: none;
  box-shadow: none;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  height: 45px;
  border: 0;
  cursor: pointer;
  background: none !important;
  padding: 0 !important;
  color: var(--gray-300);
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}
.btn-occasion:first-of-type::after {
  content: "";
  border-left: 1px solid var(--gray-200);
  height: 20px;
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 0 !important;
}
.btn-occasion.active {
  color: var(--primary);
}

.btn-occasion.active i {
  background-color: #ffcce5;
  transition: background-color 0.3s ease-in-out;
}
.btn-occasion i {
  background-color: #f8f8f8;
  font-style: normal;
  border-radius: 20px;
  height: 30px;
  width: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  transition: background-color 0.3s ease-in-out;
}

.occasion-card {
  position: relative;
  max-width: 49%;
  width: 100%;
  padding: 35px 20px 10px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #d6d5d5;
  margin: 0 0 20px;
}
.occasion-card-container {
  position: relative;
  max-width: 100%;
  width: 100%;
  padding: 35px 20px 10px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #d6d5d5;
  margin: 0 0 20px;
}

.p-equal {
  padding: 20px 20px 20px !important;
}

.occasion-card .occasion-tag span {
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 5px 25px 5px 15px;
  color: var(--white);
  background-color: var(--link);
  z-index: 1;
  font-size: 14px;
  border-bottom-right-radius: 25px;
  border-top-left-radius: 10px;
}
.RTL .occasion-card .occasion-tag span {
  left: auto;
  right: 0px;
  padding: 5px 15px 5px 25px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 25px;
  border-top-right-radius: 10px;
}

.occasion-card .remove-card {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  border: 0px;
  background: var(--primary);
  color: #fff;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 5px;
  height: 30px;
  width: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}
.RTL .occasion-card .remove-card {
  right: auto;
  left: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 5px;
}

.occasion-card .days-remaining {
  position: absolute;
  top: 8px;
  right: 45px;
}
.RTL .occasion-card .days-remaining {
  right: auto;
  left: 45px;
}
.occasion-card.single-occasion-invited-card .days-remaining {
  right: 15px;
}
.RTL .occasion-card.single-occasion-invited-card .days-remaining {
  right: auto;
  left: 15px;
}
.occasion-card.single-occasion .days-remaining {
  position: absolute;
  top: 15px;
  right: 15px;
}
.RTL .occasion-card.single-occasion .days-remaining {
  right: auto;
  left: 15px;
}

.occasion-card .occasion-img img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 64px;
}

.gap-1 {
  gap: 1rem;
}

.occasion-list-content input {
  width: 100%;
  margin: 0px;
  padding: 0px 12px;
  height: 46px;
  font-size: 14px;
  color: var(--gray-500);
  border: 1px solid #d6d5d5;
  outline: none;
  border-radius: 5px;
  background: #fff;
  transition: all 0.3s ease-in-out;
}
.occasion-list-content select {
  width: 100%;
  margin: 0px;
  padding: 0px 15px;
  height: 46px;
  font-size: 16px;
  color: var(--gray-500);
  border: 1px solid #d6d5d5;
  outline: none;
  border-radius: 5px;
  background: #fff;
  transition: all 0.3s ease-in-out;
}

.occasion-list-content select:focus {
  border: 1px solid var(--primary);
  transition: all 0.3s ease-in-out;
}

.occasion-list-content {
  max-width: 32%;
  width: 100%;
}

.occasion-list-content .occasionImageSelector {
  width: 125px;
  display: inline-block;
}
.occasion-list-content .occasionImageSelector i {
  display: flex;
  width: 125px;
  height: 125px;
  background-color: var(--gray-100);
  color: var(--gray-400);
  align-items: center;
  justify-content: center;
  border-radius: 125px;
  font-size: 30px;
  margin-top: 10px;
  cursor: pointer;
}
.occasion-list-content .occasionImageSelector img {
  display: flex;
  width: 125px;
  height: 125px;
  background-color: var(--gray-100);
  border-radius: 125px;
  margin-top: 10px;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.3s ease-in-out;
}
.occasion-list-content .occasionImageSelector img:hover {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.btn-gift {
  color: var(--gray-300);
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: none;
  box-shadow: none;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  border: 0;
  cursor: pointer;
  background: none !important;
  padding: 0 !important;
  font-size: 16px;
}

.gift-btn-group .btn-gift.active {
  color: var(--primary);
}

.gift-btn-group .btn-gift:nth-of-type(1)::after {
  content: "";
  border-left: 2px solid var(--gray-100);
  height: 60px;
  margin-left: 1rem;
  padding: 0 !important;
}

.gift-btn-group .btn-gift span {
  background-color: #f8f8f8;
  border-radius: 20px;
  height: 33px;
  width: 33px;
  padding: 5px;
  font-size: 16px;
  transition: none !important;
}

.gift-btn-group .btn-gift span.active {
  background-color: #ffcce5;
  border-radius: 20px;
  height: 33px;
  width: 33px;
  padding: 5px;
  transition: none !important;
}

.occasion-product-list .product-image {
  max-width: 19%;
  width: 100%;
  display: block;
  text-align: center;
}

.occasion-product-list .product-image img {
  width: 120px;
  height: 120px;
  margin: 0 auto;
  object-fit: cover;
}

.occasion-product-list .product-title {
  max-width: 19%;
  width: 100%;
}
.occasion-card-container .occasion-product-list .product-rating{
  max-width: 19%;
  width: 100%;
}
.occasion-card-container .occasion-product-list .product-purchased-container {
  max-width: 19%;
  width: 100%;
}

.occasion-product-list .product-title p {
  text-decoration: none;
  color: var(--gray-500);
  font-size: 16px;
  padding: 0px 5px 0px 0px;
  font-weight: 500;
  margin: 0px 0px 5px;
  max-height: 50px;
  overflow: hidden;
  display: inline-block;
  min-height: 50px;
}
.RTL .occasion-product-list .product-title p {
  max-height: 60px;
  min-height: 60px;
}

.occasion-product-list .product-price-container {
  max-width: 19%;
  width: 100%;
}
.occasion-product-list .btn-occasion {
  max-width: 19%;
  width: 100%;
  display: block;
  text-align: center;
}
.occasion-product-list .remove-occasion {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  border: 0px;
  background: var(--primary);
  color: #fff;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 5px;
  height: 30px;
  width: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}
.RTL .occasion-product-list .remove-occasion {
  right: auto;
  left: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 5px;
}

#occasionInvitation {
  padding: 50px 0px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
#occasionInvitation .invitation-wrapper {
  padding: 30px;
  max-width: 340px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px -5px #000;
}
#occasionInvitation .invitation-wrapper .invitation-avatar img {
  height: 140px;
  width: 140px;
  object-fit: cover;
  border-radius: 140px;
}
.occasion-card.single-occasion {
  margin-top: 40px;
}
.occasion-card.single-occasion .occasion-edit {
  position: absolute;
  top: -35px;
  left: 5px;
  color: var(--secondary) !important;
  font-size: 16px;
}
.occasion-card.single-occasion .occasion-share {
  position: absolute;
  top: -35px;
  right: 5px;
  color: var(--secondary) !important;
  font-size: 16px;
}
.RTL .occasion-card.single-occasion .occasion-edit {
  right: 5px;
  left: auto;
}
.RTL .occasion-card.single-occasion .occasion-share {
  right: auto;
  left: 5px;
}

#occasionSharePopup .popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
  transform: scale(0);
  z-index: 99;
  transition: opacity 0.3s ease-in-out;
}
#occasionSharePopup.active .popup-overlay {
  transform: scale(1);
  opacity: 0.4;
  transition: opacity 0.3s ease-in-out;
}
#occasionSharePopup .popup-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  width: 100%;
  max-width: 550px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #d6d5d5;
  z-index: 100;
  padding: 20px;
  transition: transform 0.3s ease-in-out;
}
#occasionSharePopup.active .popup-wrapper {
  transform: translate(-50%, -50%) scale(1);
  transition: transform 0.3s ease-in-out;
}
#occasionSharePopup .popup-wrapper .popup-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#occasionSharePopup .popup-wrapper .popup-header .btn-close {
  color: var(--gray-500);
  transition: color 0.3s ease-in-out;
}
#occasionSharePopup .popup-wrapper .popup-header .btn-close:hover {
  color: var(--primary);
  transition: color 0.3s ease-in-out;
}
#occasionSharePopup .popup-wrapper .popup-body .share-link {
  border: 2px dashed #d6d5d5;
  white-space: nowrap;
  overflow: auto;
  margin-bottom: 20px;
  scrollbar-width: none;
  cursor: pointer;
}
#occasionSharePopup .popup-wrapper .popup-body .share-link p {
  margin: 0px;
  padding: 10px;
}
.single-occasion-card  .product-card-popup,
.occasion-card-container .product-card-popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
.single-occasion-card .product-card-popup.active,
.occasion-card-container .product-card-popup.active {
  display: flex;
}
.single-occasion-card .product-card-popup::before,
.occasion-card-container .product-card-popup::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0;
  z-index: 10;
  transition: all 0.3s ease-in-out;
}
.single-occasion-card .product-card-popup.active::before ,
.occasion-card-container .product-card-popup.active::before {
  opacity: 0.4;
  transition: all 0.3s ease-in-out;
}
.single-occasion-card .product-card-popup .popup-wrapper,
.occasion-card-container .product-card-popup .popup-wrapper {
  position: relative;
  max-width: 500px;
  width: 100%;
  background-color: #fff;
  padding: 40px 25px;
  border-radius: 15px;
  z-index: 20;
  text-align: center;
}
.single-occasion-card.product-card-popup .popup-title ,
.occasion-card-container .product-card-popup .popup-title {
  font-size: 20px;
  font-weight: 500;
  color: var(--gray-500);
  margin: 0;
  text-align: center;
}
.single-occasion-card .product-card-popup .popup-subheading,
.occasion-card-container .product-card-popup .popup-subheading {
  font-size: 16px;
  font-weight: 400;
  color: var(--secondary);
  margin: 0;
  text-align: center;
  display: block;
}
.single-occasion-card .product-card-popup .popup-image-container,
.occasion-card-container .product-card-popup .popup-image-container {
  width: 100%;
  text-align: center;
  margin: 30px 0px;
}
.single-occasion-card .occasion-card-container .product-card-popup .popup-image-container img,
.occasion-card-container .product-card-popup .popup-image-container img {
  width: 100%;
  max-width: 300px;
}
.single-occasion-card .product-card-popup .btn,
.occasion-card-container .product-card-popup .btn {
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
}
.single-occasion-card .product-card-popup a.btn,
.occasion-card-container .product-card-popup a.btn {
  margin: 0 auto 15px;
}

@media (max-width: 1410px) {
  .occasion-list-content{
    max-width: 48%;
  }
}

@media (max-width: 1260px) {
  .occasion-card.single-occasion-card .occasion-img img {
    width: 80px;
    height: 80px;
    border-radius: 80px;
  }
  .occasion-card.single-occasion-card .occasion-body {
    flex-direction: column;
    text-align: center;
  }
  .occasion-card.single-occasion-card {
    padding: 35px 20px 20px;
  }
}
@media (max-width: 1250px) {
  .btn-occasion {
    font-size: 14px;
  }
  .occasion-tabs{
    padding: 10px 20px;
  }
  .btn-occasion i,
  .btn-occasion.active i {
    background-color: #fff;
    height: auto;
    width: auto;
  }
}
@media (max-width: 1200px) {
  .occasion-list-content.occasion-form-field {
    max-width: 49%;
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .occasion-card.single-occasion-card .dates {
    flex-direction: column;
    gap: 0;
  }
  .occasion-card-container {
    max-width: 48%;
    width: 100%;
  }
  .occasion-card-container .occasion-product-list .product-image {
    max-width: 100%;
    width: 100%;
  }
  .occasion-card-container .occasion-product-list .product-title {
    max-width: 100%;
    width: 100%;
  }
  .occasion-card-container .occasion-product-list .product-rating {
    max-width: 100%;
    width: 100%;
  }
  .occasion-card-container .occasion-product-list .product-price-container {
    max-width: 100%;
    width: 100%;
  }
  .occasion-card-container .occasion-product-list .btn-occasion {
    max-width: 100%;
    width: 100%;
  }
}
@media (max-width: 960px) {
  .occasion-card.single-occasion-card {
    width: 100%;
    max-width: 100%;
    padding: 45px 20px 20px;
  }
  .occasion-card.single-occasion-card .dates {
    flex-direction: row;
    gap: 10px;
  }
  .occasion-card.single-occasion-card .occasion-body {
    flex-direction: row;
    text-align: left;
  }
  .occasion-list-content.occasion-form-field {
    max-width: 100%;
    width: 100%;
  }
  .occasion-list-content.w-100.occasion-form-field {
    text-align: center;
  }
}
@media (max-width: 840px) {
  .occasion-card.single-occasion-card .dates {
    flex-direction: column;
    gap: 0;
  }
  .btn-occasion {
    font-size: 14px;
  }
  .btn-occasion i {
    background-color: #fff;
    height: auto;
    width: auto;
  }
  .btn-occasion.active i {
    background-color: #fff;
  }
  .btn-occasion:first-of-type::after {
    height: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .occasion-card-container {
    max-width: 100%;
    width: 100%;
  }
  .occasion-card-container .occasion-product-list .product-title {
    text-align: center;
  }
  .occasion-card-container .occasion-product-list .product-rating {
    text-align: center;
  }
  .occasion-card-container
    .occasion-product-list
    .product-rating
    .single-product-rating {
    justify-content: center;
  }
  .occasion-card-container .occasion-product-list .product-price-container {
    text-align: center;
  }
  .occasion-card-container
    .occasion-product-list
    .product-price-container
    .product-price {
    justify-content: center;
  }
  .occasion-card-container .occasion-product-list .btn-occasion {
    text-align: center;
  }
  .occasion-card-container .occasion-product-list .btn-occasion {
    text-align: center;
  }
  .occasion-card-container .occasion-product-list .product-purchased-container {
    margin: auto;
  }
}
@media (max-width: 700px) {
  .occasion-card.single-occasion-card .dates {
    flex-direction: row;
    gap: 10px;
  }
  .occasion-card-container {
    max-width: 48%;
    width: 100%;
  }
  .occasion-list-content.occasion-form-field {
    max-width: 48%;
    width: 100%;
  }
  .occasion-list-content.w-100.occasion-form-field {
    text-align: left;
  }
}

@media screen and (max-width: 600px) {

  .single-occasion-card  .product-card-popup ,
  .occasion-card-container .product-card-popup {
    padding: 0px 20px;
  }
  .single-occasion-card .product-card-popup  .popup-wrapper ,
  .occasion-card-container .product-card-popup .popup-wrapper {
    max-width: 340px;
    padding: 30px 25px;
  }
  .single-occasion-card  .product-card-popup .popup-title ,
  .occasion-card-container .product-card-popup .popup-title {
    font-size: 16px;
  }
  .single-occasion-card  .product-card-popup .popup-subheading,
  .occasion-card-container .product-card-popup .popup-subheading {
    font-size: 14px;
  }
  .single-occasion-card  .product-card-popup .popup-image-container img ,
  .occasion-card-container .product-card-popup .popup-image-container img {
    max-width: 200px;
  }
}
@media (max-width: 550px) {
  .occasion-list-content.occasion-form-field {
    max-width: 100%;
    width: 100%;
  }
  .occasion-list-content.w-100.occasion-form-field {
    text-align: center;
  }
}
@media (max-width: 500px) {
  .occasion-card-container {
    max-width: 100%;
    width: 100%;
  }
}
@media (max-width: 450px) {
  .occasion-card.single-occasion-card .dates {
    flex-direction: column;
    gap: 0px;
  }
}
@media (max-width: 400px) {
  .occasion-card.single-occasion-card .occasion-body {
    flex-direction: column;
    text-align: center;
  }
  .occasion-card.single-occasion-card .dates {
    flex-direction: row;
    gap: 10px;
  }
  .btn-occasion i {
    display: none;
  }
}
