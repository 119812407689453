/* Order Track Styles */
.track-status-card {
    background-color: #F9D9DB80;
    border-radius: 20px;
    padding: 40px 40px;
    gap: 30px;
}

.track-status-card img {
    width: 100px;
    height: 100px;
    border-radius: 150px;
}

#track-shipment-section .shipment-info {
    border-radius: 10px;
    -webkit-box-shadow: 10px 1px 16px 0px #0000000F;
    -moz-box-shadow: 10px 1px 16px 0px #0000000F;
    box-shadow: 10px 1px 16px 0px #0000000F;
    padding: 25px;
}
#track-shipment-section .shipment-info .shipment-status-container{
    width: 100%;
    max-width: 50%;
}

#track-shipment-section .shipment-info .courier-logo-container img {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    border-radius: 12px;
    object-fit: cover;
    border: 2px solid var(--secondary);
    -webkit-box-shadow: 0px 3px 6px 0px #00000029;
    -moz-box-shadow: 0px 3px 6px 0px #00000029;
    box-shadow: 0px 3px 6px 0px #00000029;
}

#track-shipment-section .track-timeline-container .track-timeline {
    background-color: #f6f6f6;
    border-radius: 10px;
    padding: 30px;
}

#track-shipment-section .track-timeline-container .track-timeline-card {
    gap: 20px;
    position: relative;
}

/* #track-shipment-section .track-timeline-container .track-timeline-card::after {
    content: "";
    display: inline-block;
    background-color: #D1D1D1;
    width: 2px;
    height: 80px;
    position: absolute;
    top: 63px;
    left: 24px;
}
#track-shipment-section .track-timeline-container .track-timeline-card:last-of-type:after{
    display: none !important;
} */

#track-shipment-section .track-timeline-container .track-status-current {
    background-color: var(--primary);
    padding: 13px 14px;
    border-radius: 120px;
}
#track-shipment-section .track-timeline-container .track-status-current.ar{
    padding: 5px 14px;
}

#track-shipment-section .track-timeline-container .track-status-past {
    background-color: #d1d1e1;
    padding: 25px 25px;
    border-radius: 120px;
}

@media screen and (max-width: 1100px){
    #track-shipment-section .shipment-info .shipment-status-container{
        width: unset;
        max-width: unset;
    }
}
@media screen and (max-width: 420px) {
    .track-status-card {
        gap: 10px;
    }
    .track-status-card img {
        width: 80px;
        height: 80px;
    }
    #track-shipment-section .track-timeline-container .track-timeline-card {
        gap: 10px;
    }
    /* #track-shipment-section .track-timeline-container .track-status-current {
        height: 50px;
    }
    #track-shipment-section .track-timeline-container .track-status-past {
        height: 50px;
    } */
}