.track-status-card {
    background-color: #F9D9DB80;
    border-radius: 20px;
    padding: 40px 40px;
    gap: 30px;
}

.track-status-card img {
    width: 100px;
    height: 100px;
    border-radius: 150px;
}

#track-return-section .shipment-info {
    border-radius: 10px;
    -webkit-box-shadow: 10px 1px 16px 0px #0000000F;
    -moz-box-shadow: 10px 1px 16px 0px #0000000F;
    box-shadow: 10px 1px 16px 0px #0000000F;
    padding: 25px;
}

#track-return-section .shipment-info .courier-logo-container img {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    border-radius: 12px;
    object-fit: cover;
    border: 2px solid var(--secondary);
    -webkit-box-shadow: 0px 3px 6px 0px #00000029;
    -moz-box-shadow: 0px 3px 6px 0px #00000029;
    box-shadow: 0px 3px 6px 0px #00000029;
}

#track-return-section .track-return-current-status .track-timeline {
    background-color: #f6f6f6;
    border-radius: 10px;
    padding: 30px;
}

#track-return-section .track-return-current-status .track-timeline-card {
    gap: 20px;
    position: relative;
}

#track-return-section .track-return-current-status .track-timeline-card::after {
    content: "";
    display: inline-block;
    background-color: #D1D1D1;
    width: 2px;
    height: 80px;
    position: absolute;
    top: 63px;
    left: 24px;
}
#track-return-section .track-return-current-status .track-timeline-card:last-of-type:after{
    display: none !important;
}

#track-return-section .track-return-current-status .track-status-current {
    background-color: var(--primary);
    padding: 13px 14px;
    border-radius: 120px;
}

.track-refund-info{
    -webkit-box-shadow: 0px 3px 6px 0px #00000029;
    -moz-box-shadow: 0px 3px 6px 0px #00000029;
    box-shadow: 0px 3px 6px 0px #00000029;
    background-color: #fff;
    border-radius: 10px;
    padding: 30px;
}

.return-items-container{
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 20px;
}
.return-items-container div.returns-heading {
    background-color: #11ACE1;
    padding: 7px 25px;
    border-radius: 10px 0px 10px 0px;
    display: inline-block;
    color: #fff;
}
.return-items-container .return-item-image img{
    width: 140px;
    height: 140px;
}

@media screen and (max-width: 420px) {
    .track-status-card {
        gap: 10px;
    }
    .return-items-container .return-item-image img {
        width: 80px;
        height: 80px;
    }
    #track-return-section .track-return-current-status .track-timeline-card {
        gap: 10px;
    }
}