#account{
    padding: 50px 0px;
    align-items: start;
}
#account .wrapper{
    align-items: start;
}
#account .account-body{
    max-width: calc( 100% - 410px );
    width: 100%;
}

@media screen and (max-width: 1360px) {
    #account .account-body {
        max-width: calc( 100% - 350px );
    }
    #account .account-body .profile-body-header h1{
        font-size: 20px;
    }
    #account .account-body .profile-body-header p{
        font-size: 16px;
    }
}
@media screen and (max-width: 1100px) {
    #account .account-body {
        max-width: calc( 100% - 310px );
    }
    #account .account-body .profile-body-header h1{
        font-size: 18px;
    }
    #account .account-body .profile-body-header p{
        font-size: 14px;
    }
}
@media screen and (max-width: 1024px) {
    #account .account-body .account-body-header h1{
        font-size: 18px;
    }
    #account .account-body .account-body-header p{
        font-size: 14px;
    }
}

@media screen and (max-width: 700px) {
    #account .wrapper {
        flex-direction: column;
    }
    #account .account-body {
        max-width: 100%;
    }
}