.single-card-product-container {
  margin: 0 0 25px;
}
.single-card-product-container .expected-delivery-date{
  background-color: var(--secondary);
  color: var(--alternative);
  padding: 4px 8px;
  border-radius: 5px;
  display: inline-block;
  margin-top: 5px;
}

.cart-product {
  position: relative;
  padding: 30px 30px 15px;
  border: 1px solid #d6d5d5;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  z-index: 1;
}

.cart-product.out-of-stock {
  border: 1px solid #eeeeee;
}

.cart-product.out-of-stock .product-image-wrapper,
.cart-product.out-of-stock .product-title-container,
.cart-product.out-of-stock .product-price-container,
.cart-product.out-of-stock .product-quantity-container,
.cart-product.out-of-stock .product-total-container {
  opacity: 0.5;
}

.cart-product>button {
  position: absolute;
  top: 0;
  right: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0;
  padding: 0 10px;
  font-size: 22px;
  z-index: 1;
}

.RTL .cart-product>button {
  right: auto;
  left: 0;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
}

.cart-product>.product-delivery {
  position: absolute;
  top: -13px;
  left: 15px;
  background: var(--alternative);
  color: var(--secondary);
  padding: 5px 10px;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  border-radius: 5px;
  z-index: 1;
}

.RTL .cart-product>.product-delivery {
  left: auto;
  right: 15px;
  top: -18px;
}

.cart-product>.product-delivery i {
  font-size: 16px;
  margin-right: 5px;
}

.RTL .cart-product>.product-delivery i {
  margin-right: 0px;
  margin-left: 5px;
}

.cart-product .product-total-container,
.cart-product .product-quantity-container,
.cart-product .product-price-container,
.cart-product .product-title-container {
  max-width: 19%;
  width: 100%;
  padding-top: 20px;
}

.cart-product .product-image-wrapper {
  max-width: 120px;
  width: 100%;
}

.cart-product .product-image-wrapper a {
  display: block;
  text-align: center;
}

.cart-product .product-image-wrapper a img {
  width: 120px;
  height: 120px;
  margin: 0 auto;
  object-fit: contain;
}

.cart-product .product-title-container .product-title {
  text-decoration: none;
  color: var(--gray-500);
  font-size: 16px;
  padding: 0px 5px 0px 0px;
  font-weight: 500;
  margin: 0px 0px 5px;
  max-height: 50px;
  overflow: hidden;
  display: inline-block;
}

.RTL .cart-product .product-title-container .product-title {
  max-height: 60px;
}

.cart-product .product-price-container .single-product-price {
  font-size: 16px;
  font-weight: 500;
}

.cart-product .product-price-container .single-product-discount {
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  background-color: var(--link);
  border-radius: 5px;
  padding: 5px 10px;
}

.cart-product .product-price-container .single-product-old-price {
  font-size: 14px;
  font-weight: 400;
}

.cart-product .product-quantity-container .product-quantity-btn {
  background-color: var(--white);
  border: 0px;
  outline: none;
  color: var(--primary);
  box-shadow: 0px 1px 4px #ddd;
  height: 28px;
  width: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

.cart-product .product-quantity-container .product-quantity-field {
  background-color: var(--white);
  border: 0px;
  outline: none;
  color: var(--gray-500);
  box-shadow: none;
  height: 28px;
  width: 34px;
  font-size: 14px;
  border-radius: 5px;
  text-align: center;
  margin: 0px 5px;
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.cart-product .product-total-container .product-price .single-product-price {
  font-size: 16px;
  font-weight: 500;
}

.cart-product .product-total-container button {
  font-size: 14px;
  font-weight: 400;
  padding: 0px;
  height: 35px;
  max-width: 110px;
}

.RTL .cart-product .product-total-container button {
  max-width: 130px;
}

.cart-product .product-total-container button i {
  font-size: 16px;
  font-weight: 400;
  margin-right: 10px;
}

.RTL .cart-product .product-total-container button i {
  margin-right: 0px;
  margin-left: 10px;
}

.cart-product-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: none;
  z-index: 99;
}

.cart-product-modal.active {
  display: flex;
}

.cart-product-modal::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.3;
  z-index: 1;
}

.cart-product-modal .modal-content {
  position: relative;
  background: #fff;
  z-index: 2;
  margin: 25px;
  max-height: 90vh;
  max-width: 1000px;
  width: 100%;
  border-radius: 5px;
  overflow: auto;
}

.cart-product-modal .modal-content .modal-header {
  padding: 25px;
  background-color: #f8f8f8;
}

.cart-product-modal .modal-content .modal-header h2 {
  margin: 0px;
  font-size: 18px;
  font-weight: 500;
  color: var(--gray-500);
}

.cart-product-modal .modal-content .modal-header button {
  border: 1px solid var(--primary);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  background: none;
  outline: none;
  border-radius: 5px;
  color: var(--primary);
  font-size: 18px;
  cursor: pointer;
}

.cart-product-modal .modal-content .modal-body {
  padding: 25px 25px 0px;
}

.cart-product-modal .modal-content .modal-section-header {
  margin: 0 0 20px;
}

.cart-product-modal .modal-content .modal-section-header h2 {
  margin: 0px;
  font-size: 18px;
  font-weight: 500;
  color: var(--gray-500);
}

.cart-product-modal .modal-content .wrap-options {
  display: flex;
  flex-wrap: wrap;
  /* align-items: center; */
  width: 100%;
  gap: 0px 2.5%;
  margin: 0 0 20px;
}

.cart-product-modal .modal-content .wrap-options .wrap-option {
  max-width: 112px;
  width: 100%;
  margin-bottom: 10px;
}

.cart-product-modal .modal-content .wrap-options .wrap-option input {
  display: none;
}

.cart-product-modal .modal-content .wrap-options .wrap-option label {
  display: inline-block;
  position: relative;
}

.cart-product-modal .modal-content .wrap-options .wrap-option input:checked+label::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 112px;
  height: 112px;
  background-color: #000;
  opacity: 0.6;
}

.cart-product-modal .modal-content .wrap-options .wrap-option input:checked+label::after {
  content: "\eb7b";
  font-family: "remixicon" !important;
  font-size: 24px;
  font-style: normal;
  position: absolute;
  top: 36px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  color: #fff;
  background: var(--primary);
  height: 40px;
  width: 40px;
  border-radius: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.cart-product-modal .modal-content .wrap-options .wrap-option label img {
  width: 112px;
  height: 112px;
  object-fit: cover;
  margin-bottom: 5px;
  cursor: pointer;
}

.cart-product-modal .modal-content .wrap-options .wrap-option label span {
  width: 100%;
  display: block;
  text-align: center;
  font-size: 14px;
  color: var(--gray-500);
  font-weight: 400;
}

.cart-product-modal .modal-content .card-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  gap: 0px 15px;
}

.cart-product-modal .modal-content .card-options .form-group {
  max-width: 48%;
  width: 100%;
  margin-bottom: 20px;
}

.cart-product-modal .modal-content .card-options .form-group label {
  font-size: 14px;
  color: var(--gray-500);
  font-weight: 500;
  display: inline-block;
  margin-bottom: 5px;
}

.cart-product-modal .modal-content .card-options .form-group textarea {
  resize: none;
  width: 100%;
  border: 1px solid #d6d5d5;
  font-size: 14px;
  color: var(--gray-500);
  border-radius: 5px;
  padding: 10px 20px;
  outline: none;
  transition: all 0.3s ease-in-out;
}

.cart-product-modal .modal-content .card-options .form-group input {
  width: 100%;
  border: 1px solid #d6d5d5;
  font-size: 14px;
  color: var(--gray-500);
  border-radius: 5px;
  height: 45px;
  outline: none;
  padding: 10px 20px;
  transition: all 0.3s ease-in-out;
}

.cart-product-modal .modal-content .card-options .form-group textarea:focus,
.cart-product-modal .modal-content .card-options .form-group input:focus {
  border: 1px solid var(--primary);
  transition: all 0.3s ease-in-out;
}

.cart-product-modal .modal-content .modal-footer {
  padding: 0px 25px 25px;
  text-align: right;
}

@media screen and (max-width: 1560px) {
  .cart-product .product-price-container .product-price {
    flex-direction: column;
    align-items: normal !important;
  }

  .cart-product .product-price-container .product-price {
    flex-direction: column;
    align-items: normal !important;
  }

  .cart-product .product-price-container .single-product-old-price {
    margin: 0px !important;
  }

  .cart-product .product-title-container .product-title {
    font-size: 14px;
    max-height: 40px;
  }
}

@media screen and (max-width: 1380px) {
  .cart-product .product-image-wrapper a img {
    width: 100px;
    height: 100px;
  }

  .cart-product .product-image-wrapper {
    max-width: 100px;
  }
}

@media screen and (max-width: 1260px) {

  .cart-product .product-total-container .product-price .single-product-price,
  .cart-product .product-price-container .single-product-price {
    font-size: 14px;
  }

  .cart-product .product-price-container .single-product-old-price {
    font-size: 12px;
  }

  .cart-product>button {
    padding: 0 8px;
    font-size: 18px;
    height: 30px;
  }
}

@media screen and (max-width: 1140px) {
  .single-card-product-container  {
    max-width: 48%;
    width: 100%;
  }
  .single-card-product-container .expected-delivery-date {
    width: 100%;
    display: block;
    text-align: center;
  }
  .cart-product {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .cart-product .product-image-wrapper {
    max-width: 140px;
  }

  .cart-product .product-image-wrapper a img {
    width: 140px;
    height: 140px;
  }

  .cart-product .product-total-container,
  .cart-product .product-quantity-container,
  .cart-product .product-price-container,
  .cart-product .product-title-container {
    max-width: 100%;
    text-align: center;
    padding: 0px;
    margin-top: 5px;
  }

  .cart-product .product-total-container {
    align-items: center;
    margin-top: 15px;
  }

  .cart-product .product-total-container button {
    margin-top: 10px;
    max-width: 150px;
    width: 100%;
  }

  .cart-product .product-title-container>div,
  .cart-product .product-quantity-container>span,
  .cart-product .product-price-container>span {
    display: none !important;
  }
}

@media screen and (max-width: 1024px) {
  .cart-product>.product-delivery span {
    display: none;
  }

  .cart-product-modal .modal-content .wrap-options .wrap-option {
    max-width: 100px;
  }

  .cart-product-modal .modal-content .wrap-options .wrap-option label {
    font-size: 12px;
  }

  .cart-product-modal .modal-content .wrap-options .wrap-option label img,
  .cart-product-modal .modal-content .wrap-options .wrap-option input:checked+label::before {
    width: 100px;
    height: 100px;
  }
}

@media screen and (max-width: 980px) {
  .cart-product {
    max-width: 100%;
  }

  .cart-product>.product-delivery span {
    display: inline-block;
  }

  .cart-product-modal .modal-content .wrap-options::after {
    content: "";
    max-width: 60%;
    width: 100%;
    display: inline-block;
  }
}

@media screen and (max-width: 924px) {
  .cart-product-modal .modal-content .wrap-options {
    gap: 0px 2%;
  }
}

@media screen and (max-width: 900px) {
  .cart-product-modal .modal-content .wrap-options {
    gap: 0px 3%;
  }

  .cart-product-modal .modal-content .wrap-options .wrap-option {
    max-width: 120px;
  }

  .cart-product-modal .modal-content .wrap-options .wrap-option label {
    font-size: 12px;
  }

  .cart-product-modal .modal-content .wrap-options .wrap-option label img,
  .cart-product-modal .modal-content .wrap-options .wrap-option input:checked+label::before {
    width: 120px;
    height: 120px;
  }
}

@media screen and (max-width: 768px) {

  #cart .wrapper .cart-summary,
  #cart .wrapper .cart-products {
    max-width: 100%;
  }

  .single-card-product-container{
    max-width: 48%;
  }
  .cart-product {
    padding: 30px 20px 15px;
  }

  .cart-product>.product-delivery span {
    display: none;
  }

  .cart-product-modal .modal-content .wrap-options {
    gap: 0px 3%;
  }

  .cart-product-modal .modal-content .wrap-options .wrap-option {
    max-width: 90px;
  }

  .cart-product-modal .modal-content .wrap-options .wrap-option label {
    font-size: 12px;
  }

  .cart-product-modal .modal-content .wrap-options .wrap-option label img,
  .cart-product-modal .modal-content .wrap-options .wrap-option input:checked+label::before {
    width: 90px;
    height: 90px;
  }

  .cart-product-modal .modal-content .wrap-options .wrap-option input:checked+label::after {
    top: 25px;
  }

  .cart-product-modal .modal-content .card-options .form-group {
    max-width: 100%;
    width: 100%;
  }

  .cart-product-modal .modal-content .card-options>div.form-group:first-of-type,
  .cart-product-modal .modal-content .card-options>div.form-group:nth-of-type(2) {
    max-width: 48%;
  }
}

@media screen and (max-width: 600px) {
  .cart-product-modal .modal-content .wrap-options {
    flex-wrap: nowrap;
    gap: 0px 15px;
    overflow: auto;
    margin-bottom: 0;
    padding-bottom: 10px;
    scrollbar-width: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .single-card-product-container{
    max-width: 100%;
  }


  .cart-product-modal .modal-content .wrap-options::-webkit-scrollbar {
    width: 0px;
    display: none;
  }

  .cart-product-modal .modal-content .wrap-options::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 0px;
    border: 0px;
  }

  .cart-product-modal .modal-content .wrap-options .wrap-option {
    max-width: 112px;
  }

  .cart-product-modal .modal-content .wrap-options::after {
    display: none;
  }
}

@media screen and (max-width: 550px) {
  .cart-product-modal .modal-content .modal-header {
    padding: 15px 25px;
  }
  .cart-product-modal .modal-content .modal-section-header h2,
  .cart-product-modal .modal-content .modal-header h2 {
    font-size: 16px;
  }
}

@media screen and (max-width: 500px) {

  .cart-product-modal .modal-content .card-options>div.form-group:first-of-type,
  .cart-product-modal .modal-content .card-options>div.form-group:nth-of-type(2) {
    max-width: 100%;
  }
}