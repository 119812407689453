.contact-container .contact-box {
  position: relative;
  max-width: 48%;
  width: 100%;
  padding: 30px 20px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #d6d5d5;
}

.contact-container .contact-box .form-input-feild input {
  width: 100%;
  margin: 0px;
  padding: 0px 12px;
  height: 36px;
  font-size: 12px;
  color: var(--gray-500);
  border: 1px solid #d6d5d5;
  outline: none;
  border-radius: 5px;
  background: #fff;
  transition: all 0.3s ease-in-out;
}
.contact-container .contact-box .form-input-feild textarea {
  width: 100%;
  margin: 0px;
  padding: 0px 12px;
  font-size: 12px;
  color: var(--gray-500);
  border: 1px solid #d6d5d5;
  outline: none;
  border-radius: 5px;
  background: #fff;
  transition: all 0.3s ease-in-out;
  resize: none;
}

.contact-container .google-map {
    max-width: 48%;
    width: 100%;
}
.google-map .google-map-container iframe{
    width: 100%;
    border: 0;
}

.contact-info .phone-container{
    max-width: 33%;
    width: 100%;
    justify-content: center;
}
.contact-info .email-container{
    max-width: 33%;
    width: 100%;
    justify-content: center;
}
.contact-info .location-container{
    max-width: 33%;
    width: 100%;
    justify-content: center;
}
.contact-info .phone-container .phone-img svg{
    stroke: var(--primary);

}
.contact-info .email-container .email-img svg{
    stroke: var(--primary);

}
.contact-info .location-container .location-img svg{
    stroke: var(--primary);

}

@media screen and (max-width: 820px){
    .contact-container .contact-box {
        max-width: 100%;
        margin-bottom: 2rem;
      }
      .contact-container .google-map {
        max-width: 100%;
    }
    .contact-info .phone-container{
        flex-direction: column;
        text-align: center;
    }
    .contact-info .phone-container .phone-img{
        margin-right: 0px !important;
    }
    .contact-info .phone-container .phone-img svg{
        width: 30px;
    }
    .contact-info .email-container{
        flex-direction: column;
        text-align: center;
    }
    .contact-info .email-container .email-img{
        margin-right: 0px !important;
    }
    .contact-info .email-container .email-img svg{
        width: 39px;
    }
    .contact-info .location-container{
        flex-direction: column;
        text-align: center;
    }
    .contact-info .location-container .location-img{
        margin-right: 0px !important;
    }
    .contact-info .location-container .location-img svg{
        width: 30px;
    }
}

@media screen and (max-width: 480px){
    .contact-info .phone-container{
        max-width: 100%;
        margin-bottom: 2rem;
    }
    .contact-info .email-container{
        max-width: 100%;
        margin-bottom: 2rem;
    }
    .contact-info .location-container{
        max-width: 100%;
    }
    
}