.payment-method-card-wrapper {
    gap: 70px 20px;
}
.payment-method-card{
    max-width: 500px;
    width: 100%;
    position: relative;
}
.payment-method-card .payment-method-card-ui{
    background: rgb(243,123,49);
    background: -moz-linear-gradient(310deg, rgba(243,123,49,1) 30%, rgba(237,55,143,1) 100%);
    background: -webkit-linear-gradient(310deg, rgba(243,123,49,1) 30%, rgba(237,55,143,1) 100%);
    background: linear-gradient(310deg, rgba(243,123,49,1) 30%, rgba(237,55,143,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f37b31",endColorstr="#ed378f",GradientType=1);
    border-radius: 15px;
    padding: 20px;
    filter: grayscale(1);
    background-size: 200%;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: background-size 0.3s ease-in-out;
}
.payment-method-card .payment-method-card-ui:hover{
    background-size: 100%;
    transition: background-size 0.3s ease-in-out;
}
.payment-method-card .payment-method-card-ui:not(.active)::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
    transition: opacity 0.3s ease-in-out;
}
.payment-method-card .payment-method-card-ui:not(.active)::before{
    content: "Make Default";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: #fff;
    display: inline-block;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    opacity: 0;
    z-index: 2;
    transition: opacity 0.3s ease-in-out;
}
.RTL .payment-method-card .payment-method-card-ui:not(.active)::before{
    content: "جعل الافتراضي";
}
.payment-method-card .payment-method-card-ui:not(.active):hover::before{
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}
.payment-method-card .payment-method-card-ui:not(.active):hover::after{
    opacity: 0.8;
    transition: opacity 0.3s ease-in-out;
}
.payment-method-card .payment-method-card-ui.active::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
    transition: opacity 0.3s ease-in-out;
}
.payment-method-card .payment-method-card-ui.active::before{
    content: "Default Card";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: #fff;
    display: inline-block;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    opacity: 0;
    z-index: 2;
    transition: opacity 0.3s ease-in-out;
}
.RTL .payment-method-card .payment-method-card-ui.active::before{
    content: "البطاقة الافتراضية";
}
.payment-method-card .payment-method-card-ui.active:hover::before{
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}
.payment-method-card .payment-method-card-ui.active:hover::after{
    opacity: 0.4;
    transition: opacity 0.3s ease-in-out;
}
.payment-method-card .payment-method-card-ui.active{
    filter: grayscale(0);
}
.payment-method-card .payment-method-card-ui .payment-method-card-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
}
.payment-method-card .payment-method-card-ui .payment-method-card-header h2{
    font-size: 24px;
    font-weight: 600;
    color: var(--white);
    margin: 0;
}
.payment-method-card .payment-method-card-ui .payment-method-card-header img{
    max-width: 58px;
    width: 100%;
    margin: 0;
}
.payment-method-card .payment-method-card-ui .payment-method-card-number{
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
}
.payment-method-card .payment-method-card-ui .payment-method-card-number span{
    background: #fff;
    display: inline-block;
    width: 80px;
    border-radius: 50px;
    padding: 2px;
    color: #fff;
    font-size: 18px;
    text-align: center;
    font-weight: 500;
    opacity: 0.4;
}
.payment-method-card .payment-method-card-ui .payment-method-card-number > span:last-of-type{
    background: none;
    opacity: 1;
    text-align: right;
}
.payment-method-card .payment-method-card-ui .payment-method-card-footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.payment-method-card .payment-method-card-ui .payment-method-card-footer .payment-method-card-expiry{
    text-align: right;
}
.payment-method-card .payment-method-card-ui .payment-method-card-footer .payment-method-card-holder span,
.payment-method-card .payment-method-card-ui .payment-method-card-footer .payment-method-card-expiry span{
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 400;
    color: #fff;
}
.payment-method-card .payment-method-card-ui .payment-method-card-footer .payment-method-card-holder h2,
.payment-method-card .payment-method-card-ui .payment-method-card-footer .payment-method-card-expiry h2{
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    color: #fff;
    margin: 0 0 0;
}
.payment-method-card .delete-btn{
    position: absolute;
    bottom: -40px;
    right: 0px;
    font-size: 14px;
    font-weight: 500;
    color: var(--secondary);
    background-color: var(--alternative);
    border-radius: 30px;
    z-index: 3;
    display: inline-flex;
    height: 30px;
    cursor: pointer;
    padding: 0px 10px;
    align-items: center;
    justify-content: center;
}

#newPaymentOption .checkout-form-content {
    max-width: 48%;
    width: 100%;
}
#newPaymentOption .checkout-form-content iframe {
    height: 50px !important;
}
#newPaymentOption .checkout-form-content .card-icon {
    position: absolute;
    top: 36px;
    right: 10px;
    max-width: 45px;
}
.RTL #newPaymentOption .checkout-form-content .card-icon {
    right: auto;
    left: 10px;
}
#newPaymentOption .checkout-form-content .card-icon img {
    display: block;
    width: 100%;
}

@media screen and (max-width:768px) {
    .payment-method-card .payment-method-card-ui .payment-method-card-header img {
        max-width: 48px;
    }
    .payment-method-card .payment-method-card-ui .payment-method-card-number span {
        width: 60px;
        font-size: 16px;
    }
    .payment-method-card .payment-method-card-ui .payment-method-card-header h2 {
        font-size: 20px;
    }
    .payment-method-card .payment-method-card-ui .payment-method-card-footer .payment-method-card-holder h2, 
    .payment-method-card .payment-method-card-ui .payment-method-card-footer .payment-method-card-expiry h2 {
        font-size: 14px;
    }
    .payment-method-card .payment-method-card-ui .payment-method-card-footer .payment-method-card-holder span, 
    .payment-method-card .payment-method-card-ui .payment-method-card-footer .payment-method-card-expiry span {
        font-size: 12px;
        letter-spacing: 2px;
      }
}
@media screen and (max-width:700px) {
    .payment-method-card .payment-method-card-ui .payment-method-card-header img {
        max-width: 58px;
    }
    .payment-method-card .payment-method-card-ui .payment-method-card-number span {
        width: 80px;
        font-size: 18px;
    }
    .payment-method-card .payment-method-card-ui .payment-method-card-header h2 {
        font-size: 24px;
    }
    .payment-method-card .payment-method-card-ui .payment-method-card-footer .payment-method-card-holder h2, 
    .payment-method-card .payment-method-card-ui .payment-method-card-footer .payment-method-card-expiry h2 {
        font-size: 16px;
    }
    .payment-method-card .payment-method-card-ui .payment-method-card-footer .payment-method-card-holder span, 
    .payment-method-card .payment-method-card-ui .payment-method-card-footer .payment-method-card-expiry span {
        font-size: 14px;
        letter-spacing: 2px;
      }
}
@media screen and (max-width:460px) {
    .payment-method-card .payment-method-card-ui .payment-method-card-header img {
        max-width: 48px;
    }
    .payment-method-card .payment-method-card-ui .payment-method-card-number{
        margin:  0 0 30px;
    }
    .payment-method-card .payment-method-card-ui .payment-method-card-header{
        margin:  0 0 30px;
    }
    .payment-method-card .payment-method-card-ui .payment-method-card-number span {
        width: 50px;
        font-size: 16px;
        padding: 0px;
    }
    .payment-method-card .payment-method-card-ui .payment-method-card-header h2 {
        font-size: 20px;
    }
    .payment-method-card .payment-method-card-ui .payment-method-card-footer .payment-method-card-holder h2, 
    .payment-method-card .payment-method-card-ui .payment-method-card-footer .payment-method-card-expiry h2 {
        font-size: 14px;
    }
    .payment-method-card .payment-method-card-ui .payment-method-card-footer .payment-method-card-holder span, 
    .payment-method-card .payment-method-card-ui .payment-method-card-footer .payment-method-card-expiry span {
        font-size: 12px;
        letter-spacing: 2px;
      }
}


