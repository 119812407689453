.page-text p,
.page-text ol li,
.page-text ul li{
    color: var(--gray-400);
    font-weight: 400;
    font-size: 14px;
}

.page-text h1,
.page-text h2,
.page-text h3,
.page-text h4,
.page-text h5,
.page-text h6{
    color: var(--gray-500);
    font-weight: 500;
}
.page-text h1{
    font-size: 32px;
}
.page-text h2{
    font-size: 22px;
}
.page-text h3{
    font-size: 18px;
}
.page-text h4,
.page-text h5,
.page-text h6{
    font-size: 14px;
}
.page-text a{
    display: inline-block;
    text-decoration: none;
    color: var(--link) !important;
    background: none;
    border: 0px;
    outline: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
}
