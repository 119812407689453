#product-returns .returns-cards-header {
    position: relative;
    width: 100%;
    padding: 15px 20px;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #d6d5d5;
    margin-bottom: 20px;
}

#product-returns .returns-cards .returns-btn-group {
    padding-left: 1rem;
}

#product-returns .returns-btn-group .btn-returns {
    padding: 0px 30px;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    outline: none;
    box-shadow: none;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    height: 45px;
    border: 0;
    cursor: pointer;
    background: none !important;
    padding: 0 !important;
    color: var(--gray-300);
    font-size: 16px;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
}

#product-returns .returns-btn-group .btn-returns i {
    background-color: #f8f8f8;
    border-radius: 20px;
    height: 30px;
    width: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    transition: background-color 0.3s ease-in-out;
}

#product-returns .returns-btn-group .btn-returns.active {
    color: var(--primary);
    transition: all 0.3s ease-in-out;
}

#product-returns .returns-btn-group .btn-returns.active i {
    background-color: #ffcce5;
    transition: background-color 0.3s ease-in-out;
}

#product-returns .returns-btn-group .btn-returns::after {
    content: "";
    border-left: 1px solid var(--gray-200);
    height: 20px;
    margin-left: 1rem;
    margin-right: 1rem;
    padding: 0 !important;
}

#product-returns .returns-btn-group>button.btn-returns:last-of-type::after {
    display: none;
}

/* returns box styles */
#product-returns .order-details-container {
    padding: 0px 0px;
}

#product-returns div.returns-heading {
    background-color: #11ACE1;
    padding: 7px 25px;
    border-radius: 10px 0px 10px 0px;
    display: inline-block;
    color: #fff;
}

#product-returns div.track-returns {
    padding: 7px 25px;
}

#product-returns div.track-returns a {
    text-decoration: none;
}

#product-returns div.returns-heading i {
    color: var(--primary);
}

#product-returns .order-card-wrapper{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
#product-returns .order-card-wrapper > div{
    width: 100%;
}

#product-returns .order-card-wrapper > div > div{
    width: 100%;
    max-width: 20%;
}

#product-returns .returns-info .courier-logo-container img {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    border-radius: 120px;
    object-fit: cover;
    border: 2px solid var(--secondary);
}

#product-returns .returns-info {
    border-bottom: 1px dashed #cdcdcd;
    padding-bottom: 15px;
}

#product-returns .returns-info p {
    margin: 5px 0px;
}

.dropdown-btn-container{
    position: relative;
}
.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 160px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    z-index: 1;
}

.dropdown-menu li {
    padding: 5px 15px;
    cursor: pointer;
}

.dropdown-menu li:hover {
    background-color: #f2f2f2;
}

#product-returns .status-indicator.status-processing{
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    background-color: #FFE600;
}



@media screen and (max-width: 1250px) {
    #product-returns .order-details-container{
        border: 1px solid #cdcdcd;
    }
    #product-returns .order-details-container > div.order-card{
        border: none !important;
    }
    #product-returns .returns-info {
        border-bottom: none;
        border: 1px solid #cdcdcd;
        padding-bottom: 0px;
        padding: 20px;
        border-radius: 10px;
    }
    #product-returns .returns-cards-header {
        padding: 10px 20px;
    }

    #product-returns .returns-btn-group .btn-returns {
        font-size: 14px;
    }

    #product-returns .returns-btn-group .btn-returns i,
    #product-returns .returns-btn-group .btn-returns.active i {
        background-color: #fff;
        height: auto;
        width: auto;
    }
    #product-returns .order-card-wrapper > div > div{
        width: unset;
       max-width: unset;
    }
}

@media screen and (max-width: 700px) {
    #product-returns .returns-btn-group {
        justify-content: center !important;
    }
}

@media screen and (max-width: 500px) {
    #product-returns .returns-btn-group {
        justify-content: center !important;
    }

    #product-returns .returns-btn-group .btn-returns {
        font-size: 14px;
    }
    #product-returns .order-card-wrapper > div{
        width: 100%;
    }
}